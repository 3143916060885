import { IFieldType } from "../../widgets/editor/types"


export const egOrders = () => {
  const fields = [
    {
      attribute: 'status',
      type: IFieldType.FIELD_SELECT,
      title: 'Status',
      options: [
        {
          alias: "open",
          value: "open",
        },
        {
          alias: "in progress",
          value: "in progress",
        },
        {
          alias: "success",
          value: "success",
        },
        {
          alias: "cancel",
          value: "cancel",
        },
      ],
      placeholder: 'Status',
      styles: {
        wrapper: 'w-50',
        input: 'w-100'
      }
    },
  ]
  return fields
}