/* eslint-disable react-hooks/rules-of-hooks */
import React, { FC, useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css'
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { checkUser, loginUser, selectToken, selectUser, updateToken, updateUser, viewModalLogin, viewModalRegister } from '../../app/redux/slices/UserSlice';
import { startRegisterUser } from './../../app/redux/slices/UserSlice';
import cx from 'classnames';
import { useLang } from './../../app/lang/useLang';
import { selectLang } from './../../app/redux/slices/CoreSlice';
import UIButton from './../../widgets/ui/user-interface/Button/UIButton';

interface ILoginModalProps {

};

const LoginModal: FC<ILoginModalProps> = ({ }) => {
  const user = useAppSelector(selectUser);
  const token = useAppSelector(selectToken);
  const dispatch = useAppDispatch();
  const [formData, setFormData] = useState<{
    email: string;
  }>({
    email: ''
  });
  const lang = useAppSelector(selectLang);
  const [code, setCode] = useState<string>('');
  const [showCode, setShowCode] = useState<boolean>(false);
  const [error, setError] = useState<false | 'login' | 'code'>(false);
  const [loading, setLoading] = useState(false);
  const checkLoginInfo = async () => {
    setLoading(true);
    const res = await dispatch(checkUser({
      email: formData.email,
    }));
    if (res.payload === 422) {
      setError('login');
    } else {
      setError(false);
      setShowCode(true);
    }
    setLoading(false)
  };

  const sendCode = async () => {
    setLoading(true);
    if (user) {
      const res: any = await dispatch(loginUser({
        code,
        user_id: user.id
      }));
      if (res.payload === 422) {
        setError('code');
      } else {
        localStorage.setItem('token', String(res?.payload?.token || ''));
        localStorage.setItem('user_id', String(res?.payload?.user.id || ''));

        setError(false);
        setShowCode(false);
        dispatch(viewModalLogin(false));
      }
    }
    setLoading(false)
  }
  return (
    <div className="register-modal">
      <div className="mb-2">
        <input
          className="input-main"
          type="email"
          autoComplete="email"
          placeholder="Enter email"
          onChange={(e) => setFormData({
            ...formData,
            email: e.target.value,
          })}
          disabled={Boolean(user?.email && !user?.token)}
        />
      </div>
      {/* <div className="mb-2">
        <PhoneInput
          inputClass={cx(
            'input-main input-main_phone',
            error === 'login' && 'input-main_error'
          )}
          country={'ua'}
					placeholder={useLang("your-phone", lang)}
          value={formData.phone}
          onChange={(e) => setFormData({
            ...formData,
            phone: e,
          })}
          disabled={Boolean(user?.phone && !user?.token)}
        />
      </div> */}
      {showCode && (
        <div className="mb-3">
          <input
            className="input-main"
            placeholder={useLang("code-from-sms", lang)}
            value={code}
            onChange={(e) => setCode(e.target.value)}
          />
        </div>
      )}
      {error && <div className="text-center error mb-2">{error === 'login' ? useLang("uncorrect-login", lang) : useLang("uncorrect-code", lang)}</div>}
      <UIButton
        onClick={showCode ? sendCode : checkLoginInfo}
        loading={loading}
      >{showCode ? useLang("login", lang) : useLang("send-code", lang)}
      </UIButton>
    </div>
  );
};

export default LoginModal;