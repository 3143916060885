import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import coreReducer from '../app/redux/slices/CoreSlice';
import orderSlice from '../app/redux/slices/OrderSlice';
import userSlice from '../app/redux/slices/UserSlice';
import { capitalMionApi } from './redux/api/capitalMion';
import { coreApi } from './redux/api/coreApi';

export const store = configureStore({
  reducer: {
    core: coreReducer,
    order: orderSlice,
    user: userSlice,
    [capitalMionApi.reducerPath]: capitalMionApi.reducer,
    [coreApi.reducerPath]: coreApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([
      capitalMionApi.middleware,
      coreApi.middleware,
    ]),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
