import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ICurrency, ICurrencyPair, IEventCurrency } from '../../../types/models/Currency';
import { RootState } from '../../store';
import { actionFetchCurrency, actionFetchCurrencies, actionFetchCurrencyPairs } from './../actions/core';

export interface IParams {
  fetch: {
    currency_pair_id: number;
  };
}

export interface coreState {
  lang: 'en' | 'ua' | 'ru';
  eventCurrency: IEventCurrency[] | null;
  currencies: ICurrency[] | null;
  activePair: string | null;
  currencyPairs: ICurrencyPair[] | null;
  status: 'loading' | 'success' | 'failed';
  checkKey: string;
}

const initialState: coreState = {
  lang: 'en',
  currencies: null,
  activePair: null,
  currencyPairs: null,
  eventCurrency: null,
  status: 'loading',
  checkKey: 'NoKey',
};

export const fetchCurrency = createAsyncThunk<IEventCurrency[] ,IParams['fetch']>(
  'core/currency',
  async (params) => {
    const response = await actionFetchCurrency(params);
    return response;
  }
);

export const fetchCurrencies = createAsyncThunk<ICurrency[]>(
  'core/currencies',
  async () => {
    const response = await actionFetchCurrencies();
    return response;
  }
);

export const fetchCurrencyPairs = createAsyncThunk<ICurrencyPair[]>(
  'core/currencyPairs',
  async () => {
    const response = await actionFetchCurrencyPairs();
    return response;
  }
);

export const coreSlice = createSlice({
  name: 'core',
  initialState,
  reducers: {
    setLang: (state, action) => {
      state.lang = action.payload;
    },
    setCheckKey: (state, action) => {
      state.checkKey = action.payload;
    },
    setActivePair: (state, action) => {
      const item = state?.currencyPairs?.find(item => action.payload === item.id);
      state.activePair = item?.firstCurrency.symbol + "/" + item?.secondCurrency.symbol;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCurrency.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchCurrency.fulfilled, (state, action) => {
        state.status = 'success';
        state.eventCurrency = action.payload;
      })
      .addCase(fetchCurrency.rejected, (state) => {
        state.status = 'failed';
      });
    builder.addCase(fetchCurrencyPairs.fulfilled, (state, action) => {
      state.status = 'success';
      state.currencyPairs = action.payload;
    })
  },
});

export const selectLang = (state: RootState) => state.core.lang;
export const selectCheckKey = (state: RootState) => state.core.checkKey;
export const coreLoading = (state: RootState) => state.core.status;
export const selectCurrency = (state: RootState) => state.core.eventCurrency;
export const selectCurrencyPairs = (state: RootState) => state.core.currencyPairs;
export const selectActivePair = (state: RootState) => state.core.activePair;
export const lastValueCurrency = (state: RootState) => {
  const currency = state.core.eventCurrency || [];

  const percent = () => {
    if (currency.length > 1) {
      const per = currency[currency.length - 1].value * 100 / currency[currency.length - 2].value;
      return {
        value: (per - 100).toFixed(3),
        plus: per > 100,
      }
    }
    return {
      value: 0,
      plus: true,
    };
  }
  return {
    value: currency.length ? currency[currency.length - 1].value : 0,
    percent: percent(),
  }
}

export const {
  setLang,
  setCheckKey,
  setActivePair,
} = coreSlice.actions

export default coreSlice.reducer;
