import React, { FC, useMemo } from 'react';
import Modal from '../../../widgets/modal/Modal';
import Editor from './../../../widgets/editor/Editor';
import { NET } from './../../../common/network';
import { IEditorConfig } from '../types';
import { IFieldType } from '../../editor/types';
import { IConfigGridView } from '../GridView';

interface IField {
  attribute: string;
  type: IFieldType;
  title: string;
  placeholder: string;
}

interface IGridModalProps extends IConfigGridView {
  fields: IField[];
  showModal: boolean;
  setShowModal: any;
  dataItem: any;
  setDataItem: any;
  afterSuccess: (data:any) => void;
  modalConfig: IEditorConfig;
  urlId?: boolean;
};

const GridModal: FC<IGridModalProps> = ({
  fields,
  showModal,
  setShowModal,
  dataItem,
  setDataItem,
  afterSuccess,
  modalConfig,
  title,
  baseUrl,
  path,
  urlId,
}) => {
  return (
    <div>
      <Modal
        showModal={showModal}
        setShowModal={setShowModal}
        title={title}
      >
        <Editor
          fields={fields}
          dataItem={dataItem}
          setDataItem={setDataItem}
          net={NET}
          url={baseUrl}
          route={path}
          urlId={urlId}
          modalConfig={modalConfig}
          afterSuccess={afterSuccess}
        />
      </Modal>
    </div>
  )
};

export default GridModal;
