import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { IOrder, TOrderStatus } from '../../../types/models/Order';
import { RootState } from '../../store';
import { actionCreateOrder, actionFetchOrders, actionUpdateOrder } from '../actions/order';

export interface IParams {
  ordersUser: {
    user_id: number;
    token: string | null;
  };
  create: {
    token: string | null;
    value: number;
    fixed_price: number;
    user_id: number;
    type: 'buy' | 'sell';
    currency_pair_id: number;
    status: string;
    orderId?: number;
  };
  update: {
    token: string | null;
    value?: number;
    fixed_price?: number;
    user_id: number;
    type?: 'buy' | 'sell';
    currency_pair_id?: number;
    status?: TOrderStatus;
    orderId: number;
    link?: string;
  };
}

export interface coreState {
  ordersUser: IOrder[] | null;
  status: 'loading' | 'success' | 'failed';
}

const initialState: coreState = {
  ordersUser: null,
  status: 'loading',
};

export const fetchOrdersUser = createAsyncThunk<IOrder[], IParams['ordersUser']>(
  'core/orders',
  async (params) => {
    const response = await actionFetchOrders(params);
    return response;
  }
);

export const createOrder = createAsyncThunk<IOrder[], IParams['create']>(
  'order/create',
  async (params) => {
    const response = await actionCreateOrder(params);
    return response;
  }
);

export const updateOrder = createAsyncThunk<IOrder[], IParams['update']>(
  'order/update',
  async (params) => {
    const response = await actionUpdateOrder(params);
    return response;
  }
);

export const orderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    clearOrders: (state) => {
      state.ordersUser = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchOrdersUser.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchOrdersUser.fulfilled, (state, action) => {
        state.status = 'success';
        state.ordersUser = action.payload;
      })
      .addCase(fetchOrdersUser.rejected, (state) => {
        state.status = 'failed';
      });
    builder
      .addCase(createOrder.fulfilled, (state, action) => {
        state.status = 'success';
        state.ordersUser = action.payload;
      })
      .addCase(createOrder.rejected, (state) => {
        state.status = 'failed';
      });
    builder
      .addCase(updateOrder.fulfilled, (state, action) => {
        state.status = 'success';
        console.log(action.payload)
        state.ordersUser = action.payload;
      })
      .addCase(updateOrder.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

export const {
  clearOrders
} = orderSlice.actions;

export const selectOrdersUsers = (state: RootState) => state.order.ordersUser;
// export const showWinModal = (state: RootState) => state.core.winModal;

export default orderSlice.reducer;
