import { IFieldType } from "../../widgets/editor/types"
import { NET } from "../network"

export const egCapitalLogs = () => {
  const fields = [
    {
      attribute: 'capital',
      type: IFieldType.FIELD_INPUT,
      title: 'Capital',
      placeholder: 'Enter capital',
      styles: {
        wrapper: 'w-50 pr-3',
        input: 'w-100'
      }
    },
    {
      attribute: 'date',
      type: 'date',
      title: 'Date',
      placeholder: 'Enter Date',
      format: 'YYYY-MM-DD',
      styles: {
        wrapper: 'w-50',
        input: 'w-100'
      }
    },
    {
      attribute: 'holders_count',
      type: 'input',
      title: 'Holders',
      placeholder: 'Enter count'
    },
  ]
  return fields
}