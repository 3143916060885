import { FC } from "react";
import { ISvg } from "../svgSprite";

export const TelegramSvg: FC<ISvg> = (config) => (
  <svg {...config} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M0.514251 10.5107C-0.0840737 10.3417 -0.199159 9.65423 0.376153 9.41757L15.3573 3.17416C16.5194 2.68956 17.3479 3.28685 17.2213 4.09827C16.623 7.98632 16.0362 11.8744 15.4378 15.7624C15.2768 16.8218 14.0341 17.3514 13.1366 16.7429C11.2496 15.4694 9.06336 13.9818 7.85521 13.1591C7.37195 12.8323 7.31443 12.1561 7.72866 11.7617C8.86778 10.6685 11.0079 8.61743 12.8489 6.84808C13.056 6.6565 12.7914 6.34095 12.5613 6.49872C10.26 8.04267 7.53306 9.86837 5.97971 10.9052C5.18578 11.4349 4.19623 11.5814 3.28724 11.3222L0.514251 10.5107Z"/>
	</svg>
);


