import React, {
  FC,
} from 'react';
import { IField } from '../../types';
import cx from 'classnames';

const TextArea: FC<IField> = ({
  title,
  placeholder,
  classes,
  dataItem,
  setDataItem,
  attribute,
  errors,
  styles,
}) => {
  // const [val, setVal] = useState()
  // useEffect(() => {
  //     if (dataItem[attribute]) {
  //         setVal(dataItem[attribute])
  //     }
  // }, [])
  return (
    <div className={cx(
      classes.editor__item,
      styles?.wrapper,
    )}>
      <div className={classes.editor__item__title}>
        {title}
      </div>
      <div className={cx(
        classes.editor__item__textArea,
        errors.length > 0 && classes.editor__input__error,
        styles?.input
      )} >
        <textarea placeholder={placeholder} value={dataItem[attribute]} onChange={(e) => {
          setDataItem({
            ...dataItem,
            [attribute]: e.target.value
          })
        }} />
      </div>
      <div className={classes.editor__error}>
        {errors && errors.length > 0 && errors.map((err: any, idx: number) => (
          <div key={`err${attribute}${idx}`}>{err}</div>
        ))}
      </div>
    </div>
  )
}
export default TextArea;
