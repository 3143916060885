import React, { FC } from 'react'
import cx from 'classnames';
import { IGridItem } from '../types'

const BooleanText: FC<IGridItem> = ({
  value,
  bg,
  name,
  width,
  styles,
  customStyles
}) => {
  return (
    <div style={{
      width: width,
      minWidth: width
    }} className={cx(
      styles.gridRowItem, 
      customStyles?.gridRowItem,
      bg && styles.gridRowItem_bg
  )}>
      {name ? <div className={styles.gridBoolean}>
        <svg width="11" height="10" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1 4.82897L4.52199 9L9.5 1" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </div> : <div>-</div>}
    </div>
  )
}
export default BooleanText;
