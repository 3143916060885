import { FC } from "react";
import { ISvg } from "../svgSprite";

export const HomeActiveSvg: FC<ISvg> = (config) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M13.6641 2.90755C12.6564 2.23575 11.3436 2.23575 10.3359 2.90755L3.3359 7.57422C2.5013 8.13062 2 9.06731 2 10.0704V19C2 20.6569 3.34315 22 5 22H9C9.55228 22 10 21.5523 10 21V16C10 15.4477 10.4477 15 11 15H13C13.5523 15 14 15.4477 14 16V21C14 21.5523 14.4477 22 15 22H19C20.6569 22 22 20.6569 22 19V10.0704C22 9.06731 21.4987 8.13061 20.6641 7.57422L13.6641 2.90755Z" fill="#00E6E6" />
  </svg>
);



