import React, { FC, ReactNode } from 'react';
import ModalHeader from './components/ModalHeader';
import './modal.scss'
import ModalBackground from './components/ModalBackground';

interface IModalProps {
  showModal: boolean;
  setShowModal: any;
  title: string;
  children: ReactNode;
};

const Modal: FC<IModalProps> = ({
  showModal,
  setShowModal,
  children,
  title
}) => {
  const closeModal = () => {
    setShowModal(false)
  }
  return (
    <div className="wrapper">
      {showModal && <div className="modal">
        <ModalHeader
          title={title}
          closeModal={closeModal}
        />
        {children}
      </div>}
      {showModal && <ModalBackground
        setShowModal={setShowModal}
      />}
    </div>
  )
};

export default Modal;