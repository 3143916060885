import React from 'react'
import cx from 'classnames';

const Text = ({
  value,
  bg,
  name,
  width,
  styles,
  customStyles,
  transformerValue,
}) => {
  return (
    <div style={{
        width: width,
        'min-width': width
    }} className={cx(
        styles.gridRowItem,
        customStyles?.gridRowItem,
        bg && styles.gridRowItem_bg
    )}>
        <div style={{
            textOverflow: 'ellipsis',
            overflow: 'hidden',
        }}>
          {name ? transformerValue ? transformerValue(name) : name : '-'}
        </div>
    </div>
  )
}
export default Text