import './App.css';
import './styles/global.scss'
import { Routes, Route } from 'react-router-dom';
import HomePage from './pages/home/HomePage';
import CapitalPage from './pages/capital/CapitalPage';
import AdminRouter from './pages/admin/Main/AdminRouter';
import ProfilePage from './pages/profile/ProfilePage';
import RegistrationPage from './pages/auth/registration/RegistrationPage';
import LoginPage from './pages/auth/login/LoginPage';

function App() {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/capital" element={<CapitalPage />} />
      <Route path="/profile" element={<ProfilePage />} />
      <Route path="/auth/registration" element={<RegistrationPage />} />
      <Route path="/auth/login" element={<LoginPage />} />
      <Route path="/admin/*" element={<AdminRouter />} />
    </Routes>
  );
}

export default App;
