import React, { FC } from 'react'
import cx from 'classnames';

interface IGridItem {
  value: any;
  bg: any;
  name: any;
  width: any;
  styles: any;
  customStyles: any;
}

const Status:FC<IGridItem> = ({
  value,
  bg,
  name,
  width,
  styles,
  customStyles
}) => {
  const statusData:any[] = [
    {
      key: 'open',
      background: '#7894f7',
      color: 'white',
      title: 'Открыт'
    },
    {
      key: 'pending',
      background: '#ffd600',
      color: 'white',
      title: 'В обработке'
    },
    {
      key: 'in progress',
      background: '#ffd600',
      color: 'white',
      title: 'В обработке'
    },
    {
      key: 'send',
      background: '#71d1ba',
      color: 'white',
      title: 'Отправлен'
    },
    {
      key: 'return',
      background: '#e16363',
      color: 'white',
      title: 'Возврат'
    },
    {
      key: 'cancel',
      background: '#b7b7b7',
      color: 'white',
      title: 'Отменен'
    },
    {
      key: 'success',
      background: '#62bb8a',
      color: 'white',
      title: 'Успешно'
    },
  ];
  return (
    <div style={{
      width: width,
      minWidth: width
    }} className={cx(
      styles.gridRowItem,
      customStyles?.gridRowItem,
      bg && styles.gridRowItem_bg,
    )}>
      <div className={cx(
        styles.status,
      )} style={{
        backgroundColor: statusData.find(status => status.key === name).background,
        color: statusData.find(status => status.key === name).color,
      }}>
        {statusData.find(status => status.key === name).title}
      </div>
    </div>
  )
}
export default Status;