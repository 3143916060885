import React, { FC } from 'react';
import { Route, Routes } from 'react-router-dom';
import AdminCurrencyPage from '../Currency/AdminCurrencyPage';
import AdminMainPage from './AdminMainPage';
import AdminCapitalLogsPage from '../CapitalLogs/AdminCapitalLogsPage';
import AdminCapitalPage from '../Capital/AdminCapitalPage';
import AdminEventCurrencyPage from './../EventCurrency/AdminEventCurrencyPage';
import AdminOrdersPage from './../Orders/AdminOrdersPage';
import AdminUsersPage from './../Users/AdminUsersPage';

interface IAdminRouterProps {

};

const AdminRouter:FC<IAdminRouterProps> = ({}) => {
  return (
    <div>
      <Routes>
        <Route path="/" element={<AdminMainPage />} />
        <Route path="currency" element={<AdminCurrencyPage />} />
        <Route path="capital-mion" element={<AdminCapitalPage />} />
        <Route path="capital-logs" element={<AdminCapitalLogsPage />} />
        <Route path="event-currencies" element={<AdminEventCurrencyPage />} />
        <Route path="orders" element={<AdminOrdersPage />} />
        <Route path="users" element={<AdminUsersPage />} />
      </Routes>
    </div>
  )
};

export default AdminRouter;
