import React, { FC } from 'react';
import { IGridData } from '../types';
import { getData } from './../../../common/utils';

interface IGridPagginationProps {
    data: any,
    transformData: (head: any[], data: any) => IGridData,
    setGridData: any,
    thead: any
};

const GridPaggination:FC<IGridPagginationProps> = ({
    data,
    setGridData,
    transformData,
    thead
}) => {
    const pagginationFunc = async (item:any) => {
        console.log(item)
        const data = await getData(item.url)
        setGridData(transformData(thead, data))
    }
    return (
        <div className="paggination">
            {data?.links.map((item:any, idx:number) => (
                <div 
                    onClick={() => pagginationFunc(item)}
                    className={data.current_page === +item.label ? "paggination__item paggination__item_active" : "paggination__item"}
                    >
                    <div>{
                        idx === 0 ? 
                        <div>prev</div> : idx === (data.links.length - 1) ? 
                        <div>next</div> :
                        <div>{item.label}</div>
                    }</div>
                </div>
            ))}
        </div>
    )
};

export default GridPaggination;