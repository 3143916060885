import React, { FC, useState } from 'react';
import { NET } from '../../../../../app/network';
import { ggCurrency } from '../../../../../common/gridgenerator/ggCurrency';
import GridView from '../../../../../widgets/GridView/GridView';
import { egEventCurrencies } from './../../../../../common/editorgenerator/egEventCurrencies';

interface ITableCurrencyProps {

};

const TableCurrency:FC<ITableCurrencyProps> = ({}) => {
  return (
    <div>
      <GridView
        config={{
          title: "Currency",
          baseUrl: NET.BACK_URL,
          path: 'currencies',
          width: 'flexible',
          pagination: false,
          filters: {
            on: false,
          }
        }}
        generator={{
          grid: ggCurrency,
          editor: egEventCurrencies,
        }}
        model={{

        }}
      />
    </div>
  )
};

export default TableCurrency;