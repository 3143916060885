import React, { FC } from "react";
import GridFilter from "./GridFilter";
import { ExportCSV } from "./excel/ExportCSV";

interface IGridControls {
  filter: any;
  setFilter: any;
  routeFilter?: string;
  styles: any;
  url: string;
  gridData: any;
  staticData?: boolean;
  options?: {
    id: string | number;
    title: string;
  }[];
}

const GridControls:FC <IGridControls> = ({
  filter,
  setFilter,
  routeFilter,
  styles,
  url,
  gridData,
  staticData,
  options,
}) => {
  return (
    <div className={styles.gridControls}>
      {Object.keys(filter).slice(1).map((keyItem, idx) => (
        <GridFilter
          keyItem={keyItem}
          filter={filter}
          setFilter={setFilter}
          classes={styles}
          routeFilter={routeFilter}
          url={url}
          key={`filter${idx}`}
          staticData={staticData}
          options={options}
        />
      ))}
      {/* <ExportCSV
        csvData={gridData}
        fileName="excel-data"
        styles={styles}
        filterParams={filterParams}
        activeFilter={activeFilter}
      /> */}
    </div>
  );
};
export default GridControls;
