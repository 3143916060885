import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { IUser } from '../../../types/models/User';
import { RootState } from '../../store';
import { actionStartRegisterUser, actionUpdateUser, actionCheckToken, actionCheckUser, actionLoginUser } from './../actions/user';

export interface IParams {
  register: {
    phone?: string;
    name: string;
    email: string;
    code?: string;
    user_id?: number;
    refferal?: string | null;
  };
  update: {
    id: string;
    phone?: string;
    name?: string;
    metamask_id?: string;
  };
  token: {
    user_id: number;
    token: string;
  };
  check: {
    email: string;
  };
  login: {
    user_id: number;
    code: string;
  };
}

export interface userState {
  modalConfirmPay: number | false;
  modalRegister: boolean;
  modalLogin: boolean;
  user: IUser | null;
  token: string | null;
  status: 'loading' | 'success' | 'failed';
}

const initialState: userState = {
  modalRegister: false,
  modalConfirmPay: false,
  modalLogin: false,
  user: null,
  token: null,
  status: 'loading',
};

export const startRegisterUser = createAsyncThunk<IUser, IParams['register']>(
  'user/startRegister',
  async (params) => {
    const response = await actionStartRegisterUser(params);
    return response;
  }
);

export const updateUser = createAsyncThunk<IUser, IParams['update']>(
  'user/update',
  async (params) => {
    const response = await actionUpdateUser(params);
    return response;
  }
);

export const checkToken = createAsyncThunk<IUser | 403, IParams['token']>(
  'user/token',
  async (params) => {
    const response = await actionCheckToken(params);
    return response;
  }
);

export const checkUser = createAsyncThunk<IUser | 422, IParams['check']>(
  'user/check',
  async (params) => {
    const response = await actionCheckUser(params);
    return response;
  }
);

export const loginUser = createAsyncThunk<{
  token: string,
  user: IUser,
} | 422, IParams['login']>(
  'user/login',
  async (params) => {
    const response = await actionLoginUser(params);
    return response;
  }
);

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    viewModalRegister: (state, action) => {
      state.modalRegister = action.payload;
    },
    viewModalLogin: (state, action) => {
      state.modalLogin = action.payload;
    },
    viewModalConfirmPay: (state, action) => {
      state.modalConfirmPay = action.payload;
    },
    updateToken: (state, action) => {
      state.token = action.payload;
    },
    exitUser: (state) => {
      state.token = null;
      state.user = null;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(startRegisterUser.fulfilled, (state, action) => {
        state.status = 'success';
        if (action.payload.token) {
          state.token = action.payload.token
        } else {
          state.user = action.payload;
        }
      })
    builder
      .addCase(updateUser.fulfilled, (state, action) => {
        state.status = 'success';
        state.user = action.payload;
      })
    builder
      .addCase(checkToken.fulfilled, (state, action) => {
        state.status = 'success';
        state.user = action.payload === 403 ? null : action.payload;
      })
      .addCase(checkToken.rejected, (state, action) => {
        state.user = null;
        state.token = null;
        localStorage.removeItem('user_id');
        localStorage.removeItem('token');
      })
    builder
      .addCase(checkUser.fulfilled, (state, action) => {
        state.status = 'success';
        state.user = action.payload === 422 ? null : action.payload;
      })
      .addCase(checkUser.rejected, (state, action) => {
        state.user = null;
        state.token = null;
      })
    builder
      .addCase(loginUser.fulfilled, (state, action) => {
        state.status = 'success';
        if (action.payload !== 422){
          state.user = action.payload.user;
          state.token = action.payload.token;
        }
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.user = null;
        state.token = null;
      })
  },
});

export const {
  viewModalRegister,
  viewModalLogin,
  viewModalConfirmPay,
  updateToken,
  exitUser,
} = userSlice.actions;

export const showModalRegister = (state: RootState) => state.user.modalRegister;
export const showModalLogin = (state: RootState) => state.user.modalLogin;
export const showModalModalConfirmPay = (state: RootState) => state.user.modalConfirmPay;
export const selectUser = (state: RootState) => state.user.user;
export const selectToken = (state: RootState) => state.user.token;
// export const showWinModal = (state: RootState) => state.core.winModal;

export default userSlice.reducer;
