import React, { FC } from 'react'
import cx from 'classnames';

interface IGridItem {
  value: any;
  bg?: string;
  name: string;
  width: number;
  styles: any;
  customStyles: any;
}

const Link: FC<IGridItem> = ({
  value,
  bg,
  name,
  width,
  styles,
  customStyles
}) => {
  return (
    <div style={{
      width: width,
      minWidth: width,
    }} className={cx(
      styles.gridRowItem,
      customStyles?.gridRowItem,
      bg && styles.gridRowItem_bg
    )}>
      <div className={styles.gridItemLink}>
        <a target="_blank" href={name}>{name ? name : '-'}</a>
      </div>
    </div>
  )
}
export default Link
