/* eslint-disable react-hooks/rules-of-hooks */
import React, { FC, useRef, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import styles from './LineChart.module.scss';
import { fetchCurrency, selectCurrency } from '../../app/redux/slices/CoreSlice';
import { useGetCapitalMionLogsQuery } from '../../app/redux/api/capitalMion';
import Loading from '../ui/Loading/Loading';
import { selectLang } from './../../app/redux/slices/CoreSlice';
import { useLang } from './../../app/lang/useLang';

interface ILineChartProps {

};

const LineChart: FC<ILineChartProps> = ({ }) => {
  const { data, isLoading, isSuccess } = useGetCapitalMionLogsQuery('capital-logs');
  const dispatch = useAppDispatch();
  const canvasRef = useRef<HTMLCanvasElement>(null);
	const lang = useAppSelector(selectLang);
  const drawGraph = (
    ctx: CanvasRenderingContext2D | null,
    width = 0,
    height = 0,
    data: { startValue: number, endValue: number, date: string }[],
    multiple: number,
  ) => {
    if (ctx) {
      const yRecord = (value: number) => {
        return height - value - 20;
      }
      data.forEach((item, idx) => {
        if (item.startValue - item.endValue < 0) {
          ctx.fillStyle = '#2EBD85';
        } else {
          ctx.fillStyle = '#F6465D';
        }
        ctx.fillRect(
          idx * 15,
          yRecord(item.startValue * multiple),
          12,
          (item.startValue - item.endValue) * multiple
        );
        ctx.font = `11px Montserrat`;
        ctx.save();
        ctx.rotate(-Math.PI / 2);
        ctx.textAlign = "center";
        const progressCapitalValue = (((item.endValue / (data[idx - 1]?.endValue || 1)) * 100) - 100);
        if (idx) {
          ctx.fillText(
            (progressCapitalValue > 0 ? "+" : '') + String(progressCapitalValue.toFixed(2)),
            -15, ((idx + 1) * 15) - 5);
        }
        ctx.restore();
        if (!(idx % 3)) {
          ctx.fillText(item.date.substring(5, 10), (idx * 15) - 2, yRecord(-20));
        }
      });
    }
  }

  const drawY = (
    ctx: CanvasRenderingContext2D | null,
    width: number,
    height: number,
    start: number,
    now: number,
    end: number,
    step: number,
    MULTIPLE_INDEX: number,
  ) => {
    const yRecord = (value: number) => {
      return height - value - 20;
    }
    if (ctx) {
      ctx.font = `12px Montserrat`;
      ctx.fillText(String(start), width - 35, height - 18)
      ctx.fillText(String(end / MULTIPLE_INDEX), width - 35, 10)
      ctx.fillStyle = '#343A44';
      ctx.fillRect(0, yRecord(now * MULTIPLE_INDEX), 790, 1);
      ctx.fillStyle = '#2EBD85'
      ctx.fillText(String(now), width - 35, yRecord(now * MULTIPLE_INDEX) - 3);
    }
  }

  useEffect(() => {
    const HIGH_Y = 250;
    if (canvasRef.current && data) {
      const transformData = data.data.map((item, idx) => ({
        startValue: 0,
        endValue: item.capital,
        date: item.date,
      })).reverse();

      let maxValueY = 0;
      transformData.forEach((item) => {
        if (item.endValue > maxValueY) {
          maxValueY = item.endValue;
        }
      });

      const MULTIPLE_INDEX = HIGH_Y / maxValueY * 0.7;

      const canvas = canvasRef.current;
      const ctx = canvas.getContext('2d');

      const widthWindow = window.innerWidth;

      let widthCanvas = 600;
      let heightCanvas = 250;

      if (widthWindow > 1100 && widthWindow < 1300) {
        widthCanvas = 500;
      } else if (widthWindow > 576 && widthWindow <= 1100) {
        widthCanvas = 400;
      } else if (widthWindow < 576) {
        widthCanvas = widthWindow - 49
      }
      canvas.width = widthCanvas;
      canvas.height = heightCanvas;

      const width = canvasRef.current.width
      const height = canvasRef.current.height
      drawGraph(
        ctx,
        width,
        height,
        transformData,
        MULTIPLE_INDEX,
      );
      drawY(
        ctx,
        width,
        height,
        transformData[0].startValue,
        transformData[transformData.length - 1].endValue,
        HIGH_Y,
        1,
        MULTIPLE_INDEX,
      );
      if (ctx) {
        canvas.addEventListener('mousemove', (event) => {
          ctx.clearRect(0, 0, canvas.width, canvas.height);
          drawGraph(
            ctx,
            width,
            height,
            transformData,
            MULTIPLE_INDEX,
          );
          drawY(
            ctx,
            width,
            height,
            transformData[0].startValue,
            transformData[transformData.length - 1].endValue,
            HIGH_Y,
            1,
            MULTIPLE_INDEX,
          );
          if (height - event.offsetY > 20) {
            //Value price on move
            const idDate = Math.floor(event.offsetX / 15);
            ctx.font = `12px Montserrat`;
            ctx.fillStyle = '#1F2630';
            ctx.fillRect((widthCanvas - 35), event.offsetY - 10, 50, 20);
            ctx.fillStyle = '#fff';
            ctx.fillText(`${((height - event.offsetY - 20) / MULTIPLE_INDEX).toFixed(3)}`, (widthCanvas - 35), event.offsetY + 5);
            if (idDate < transformData.length) {
              ctx.fillStyle = '#1F2630';
              ctx.fillRect(event.offsetX - 20, 230, 40, 20);
              ctx.fillStyle = '#fff';
              ctx.fillText(transformData[idDate].date.substring(5, 10), event.offsetX - 10, 250);
            }
            ctx.fillStyle = '#343A44';
            ctx.fillRect(event.offsetX, 0, 1, 230);
            ctx.fillRect(0, event.offsetY, (widthCanvas - 35), 1);
          } else {
            // ctx.clearRect(0, 0, canvas.width, canvas.height);
          }
          ctx.fillStyle = '#343A44';
        });

      }
    }
  }, [isSuccess]);

  if (!isSuccess) return <Loading />
  return (
    <div>
      <div className="capital__progress-title">{useLang("capital-progress", lang)}</div>
      <div className="chart">
        <canvas ref={canvasRef} id="canvas">
					{useLang("browser-does-not", lang)}
        </canvas>
        <div className="chart-line-capital"></div>
        <div className="chart-line-capital-y"></div>
      </div>
    </div>
  )
};

export default LineChart;