import { FC } from "react";
import { ISvg } from "../svgSprite";

export const CapitalActiveSvg: FC<ISvg> = (config) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M1 20V2H3V20C3 20.5523 3.44772 21 4 21H22V23H4C2.34315 23 1 21.6569 1 20Z" fill="#00E6E6" />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M15 2C14.4477 2 14 2.44772 14 3V19C14 19.5523 14.4477 20 15 20H20C20.5523 20 21 19.5523 21 19V3C21 2.44772 20.5523 2 20 2H15Z" fill="#00E6E6" />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M9 7C9 6.44772 9.44772 6 10 6H15C15.5523 6 16 6.44772 16 7V19C16 19.5523 15.5523 20 15 20H10C9.44772 20 9 19.5523 9 19V7ZM11 8V18H14V8H11Z" fill="#00E6E6" />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M4 12C4 11.4477 4.44772 11 5 11H10C10.5523 11 11 11.4477 11 12V19C11 19.5523 10.5523 20 10 20H5C4.44772 20 4 19.5523 4 19V12ZM6 13V18H9V13H6Z" fill="#00E6E6" />
  </svg>
);


