import React, { FC } from 'react';
import { NET } from '../../../app/network';
import GridView from '../../../widgets/GridView/GridView';
import { ggCapitalLogs } from '../../../common/gridgenerator/ggCapitalLogs';
import { egCapitalLogs } from '../../../common/editorgenerator/egCapitalLogs';
import AdminLayout from '../../../layouts/AdminLayout';

interface IAdminCapitalLogsPageProps {

};

const AdminCapitalLogsPage:FC<IAdminCapitalLogsPageProps> = ({}) => {
  return (
    <AdminLayout>
      <GridView
        config={{
          title: "Capital Logs",
          baseUrl: NET.BACK_URL,
          path: 'capital-mion-logs',
          width: 'flexible',
          pagination: false,
          filters: {
            on: false,
          }
        }}
        generator={{
          grid: ggCapitalLogs,
          editor: egCapitalLogs,
        }}
        model={{
          id: 1,
          capital: '',
          date: '2023-01-01',
          holders_count: 1,
        }}
      />
    </AdminLayout>
  )
};

export default AdminCapitalLogsPage;