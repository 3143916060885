import React from "react";
import cx from "classnames";
import GridSwitcher from "./../GridSwitcher";

const GridRow = ({
  elems,
  elem,
  styles,
  numberRow,
  customStyles,
  config,
  gridHandlers,
}) => {
  return (
    <div className={[styles.gridRow, customStyles?.gridWrapper].join(" ")}>
      {config.counter && (
        <div
          style={{
            width: 30,
            "min-width": 30,
          }}
          className={cx(
            styles.gridRowItem,
            customStyles?.gridRowItem,
            styles.gridRowItem_bg
          )}
        >
          {numberRow + 1}
        </div>
      )}
      {elems
        .filter((el) => !el.hide)
        .map((cell, idx) => (
          <GridSwitcher
            styles={styles}
            key={`switchGrid${idx}`}
            type={cell.type || "text"}
            name={elem[cell.alias] || ""}
            value={elem[cell.value] || ""}
            width={cell.width || 150}
            gridHandlers={gridHandlers[cell.alias]}
            idRow={elem.id}
            net={config.net}
            elem={elem}
            cell={cell}
            bg={idx % 2}
          />
        ))}
    </div>
  );
};
export default GridRow;
