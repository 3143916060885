import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { ISetting } from '../../../types/models/Setting';
import { NET } from '../../network';


interface ISettingDefense extends ISetting{
  checkKey: string;

}

export const coreApi = createApi({
  reducerPath: 'coreApi',
  baseQuery: fetchBaseQuery({ baseUrl: NET.BACK_URL }),
  tagTypes: ['Setting'],
  endpoints: (builder) => ({
    getSetting: builder.query<ISetting, string>({
      query: () => '/setting',
      providesTags: ['Setting'],
    }),
    updateSetting: builder.mutation<null, ISettingDefense>({
      query: (body) => ({
        url: '/setting',
        method: "POST",
        body: body,
      }),
      invalidatesTags: ['Setting']
    }),
    checkAdminToken: builder.mutation<null, {
      token: string;
    }>({
      query: (body) => ({
        url: '/check-admin',
        method: "POST",
        body: body,
      }),
    }),
    loginAdmin: builder.mutation<{
      token: string;
    }, {
      password: string;
    }>({
      query: (body) => ({
        url: '/login-admin',
        method: "POST",
        body: body,
      }),
    }),
  }),
})

export const {
  useGetSettingQuery,
  useCheckAdminTokenMutation,
  useUpdateSettingMutation,
  useLoginAdminMutation,
} = coreApi;
