export enum IFieldType {
  FIELD_INPUT = "input",
  FIELD_TEXTAREA = "text-area",
  FIELD_PHONE = "phone",
  FIELD_SELECT_SEARCH = "select-search",
  FIELD_SELECT = "select",
}

export interface IField {
  title: string;
  placeholder: string;
  classes: any;
  dataItem: any;
  setDataItem: any;
  attribute: string;
  errors: any;
  value?: any;
  changeFunc?: any;
  styles?: {
    wrapper?: string;
    input?: string;
  }
}