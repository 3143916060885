export const NET = {
  // BACK_URL: 'http://127.0.0.1:8000/api',
  BACK_URL: 'https://api.mion.company/api',
}

export const GLOBAL_VAR = {
  pay_metamask: "0x362c546F471e12fa7F75CA19C8A78fbc2330c60a",
  binance_id: '436168637'
}

export const PAY_METHODS = [
  {
    id: 1,
    buy: [
      {
        title: 'Metamask',
        value: '0x362c546F471e12fa7F75CA19C8A78fbc2330c60a',
      },
      {
        title: 'TrustWallet',
        value: '0x210FF0b640282d1805e281C70cC3f457305B399e',
      },
      {
        title: 'Binance (motich)',
        value: '436168637',
      },
    ],
    sell: [
      {
        title: 'Metamask',
        value: '0x362c546F471e12fa7F75CA19C8A78fbc2330c60a',
      },
      {
        title: 'TrustWallet',
        value: '0x210FF0b640282d1805e281C70cC3f457305B399e',
      },
    ]
  },
  {
    id: 2,
    buy: [
      {
        title: 'Monobank',
        value: '5375411503181773',
      },
    ],
    sell: [
      {
        title: 'Metamask',
        value: '0x362c546F471e12fa7F75CA19C8A78fbc2330c60a',
      },
      {
        title: 'TrustWallet',
        value: '0x210FF0b640282d1805e281C70cC3f457305B399e',
      },
    ]
  },
]