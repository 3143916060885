import { FC } from "react";
import { ISvg } from "../svgSprite";

export const ProfileNewSvg: FC<ISvg> = (config) => (
	<svg {...config} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
		<circle cx="10" cy="10" r="9" />
		<circle cx="10" cy="8" r="3" />
		<path d="M16.0636 17C14.8532 14.9077 12.591 13.5 10 13.5C7.40906 13.5 5.14686 14.9077 3.93652 17" />
	</svg>
);
