import { FC } from "react";
import { ISvg } from "../svgSprite";

export const PanelSvg: FC<ISvg> = (config) => (
  <svg width="100%" viewBox="0 0 430 131" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.73463 7.79406C83.8108 -19.9439 139.318 58.486 225.5 55.9629C303.891 53.6678 349.084 -24.4589 419.657 7.79406C490.229 40.047 430.167 131 430.167 131H-0.275673C-0.275673 131 -64.3416 35.5321 9.73463 7.79406Z" fill="url(#paint0_linear_47_1565)"/>
    <defs>
    <linearGradient id="paint0_linear_47_1565" x1="214" y1="1.01436" x2="214" y2="131" gradientUnits="userSpaceOnUse">
    <stop stop-color="#FFAE00"/>
    <stop offset="1" stop-color="#F28700"/>
    </linearGradient>
    </defs>
  </svg>
);

