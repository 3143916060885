import React, { FC } from 'react';

interface IOverlayerProps {
  onClick: any;
};

const Overlayer:FC<IOverlayerProps> = ({
  onClick
}) => {
  return (
    <div onClick={onClick} className="overlayer"></div>
  )
};

export default Overlayer;