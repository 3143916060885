export const ggOrders = () => {
  return {
    thead: [
      {
        title: '',
        alias: 'id',
        type: 'text',
        width: 0,
        hide: true,
      },
      {
        title: 'Pair',
        alias: 'title',
        type: 'text',
        width: 100
      },
      {
        title: 'Status',
        alias: 'status',
        type: 'status',
        width: 150,
      },
      {
        title: 'Type',
        alias: 'type',
        type: 'text',
        width: 80
      },
      {
        title: 'Value',
        alias: 'value',
        type: 'text',
        width: 100
      },
      {
        title: 'Price',
        alias: 'fixed_price',
        type: 'text',
        width: 100
      },
      {
        title: 'User',
        alias: 'user_id',
        type: 'text',
        width: 50
      },
      {
        title: 'Wallet',
        alias: 'metamask_id',
        type: 'text',
        width: 250
      },
      {
        title: 'Link Order',
        alias: 'link',
        type: 'link',
        width: 150
      },
      {
        title: 'Date',
        alias: 'created_at',
        type: 'text',
        width: 180,
        transformerValue: (e:string):string => `${e.substring(0, 10)} ${e.substring(11, 19)}`
      },
      {
        title: 'Actions',
        alias: 'buttons',
        type: 'buttons',
        width: 150,
        // customButtons: [
        //   {
        //     icon: 'qr',
        //     path: '../filters-values',
        //     type: 'link',
        //   }
        // ]
      },
    ],
  }
}