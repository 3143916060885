/* eslint-disable react-hooks/rules-of-hooks */
import React, { FC, ReactNode, useEffect, useState } from 'react';
import Menu from './components/Menu';
import { useAppDispatch, useAppSelector } from './../app/hooks';
import { checkToken, updateToken } from './../app/redux/slices/UserSlice';
import MobileMenu from './components/MobileMenu/MobileMenu';
import { Navigate, useSearchParams, useLocation } from 'react-router-dom';
import { selectLang, setLang } from '../app/redux/slices/CoreSlice';
import { useLang } from './../app/lang/useLang';

export type TMenuItem = 'capital' | 'home' | 'profile' | 'registration' | 'login';

interface IMainLayoutProps {
  children: ReactNode;
  activeMenuItem: TMenuItem;
};

const MainLayout:FC<IMainLayoutProps> = ({
  children,
  activeMenuItem,
}) => {
  const [ready, setReady] = useState(false);
  const dispatch = useAppDispatch();
  const [params]:any = useSearchParams();
  const route = useLocation();
  const lang = useAppSelector(selectLang);

  useEffect(() => {
    if (params.get('lang')) {
      localStorage.setItem('lang', params.get('lang'));
      dispatch(setLang(params.get('lang')));
    }
  }, [lang, params.get('lang')]);

  useEffect(() => {
    (async () => {
      const token = localStorage.getItem('token');
      const user_id = localStorage.getItem('user_id');

      if (token && user_id) {
        await dispatch(checkToken({
          user_id: Number(user_id),
          token
        }));
        dispatch(updateToken(token));
      }
      setReady(true);
    })()
  }, []);

  if (!params.get('lang')) {
    if (route.search) {
      return <Navigate replace to={`${route.pathname}${route.search}&lang=${localStorage.getItem('lang') || lang}`} />
    } else {
      return <Navigate replace to={`${route.pathname}?lang=${localStorage.getItem('lang') || lang}`} />
    }
  }

  return (
    <div className="bg-grey-black mh-100-vh main-wrapper">
      <div>
        <Menu activeMenuItem={activeMenuItem} />
        <MobileMenu activeMenuItem={activeMenuItem} />
      </div>
      {ready ? (
        children
      ) : <div>{useLang("loading", lang)}</div>}
    </div>
  )
};

export default MainLayout;