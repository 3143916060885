/* eslint-disable react-hooks/rules-of-hooks */
import React, { FC, useState } from 'react';
import cx from 'classnames';
import { IOrder } from '../../../types/models/Order';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { updateOrder } from '../../../app/redux/slices/OrderSlice';
import { selectToken, selectUser } from '../../../app/redux/slices/UserSlice';
import Modal from '../../../widgets/modal/Modal';
import ConfirmPayModal from './ConfirmPayModal';
import { useLang } from './../../../app/lang/useLang';
import { selectLang } from './../../../app/redux/slices/CoreSlice';

interface IOrderItemProps extends IOrder {
	created_at: string;
};

const OrderItem: FC<IOrderItemProps> = ({
	id,
	pair,
	status,
	value,
	type,
	created_at,
	fixed_price,
	currency_pair_id,
}) => {
	const lang = useAppSelector(selectLang);
	const token = useAppSelector(selectToken);
	const [modalConfirmPay, setModaConfirmPay] = useState(false);
	const user = useAppSelector(selectUser);
	const dispatch = useAppDispatch();
	const cancelOrder = () => {
		if (user) {
			dispatch(updateOrder({
				token,
				status: 'cancel',
				orderId: id,
				user_id: user.id,
			}));
		}
	};

	const modalConfirmPayShow = (status: boolean) => {
		setModaConfirmPay(status)
	};

	return (
		<div className="orders-body">
			<div className="orders-body__item orders-body__item_number">#{id}</div>
			<div className="orders-body__item orders-body__item_date">{created_at.substring(0, 10)}</div>
			<div className="orders-body__item orders-body__item_pair">{pair.firstCurrency.symbol + "/" + pair.secondCurrency.symbol}</div>
			<div className="orders-body__item orders-body__item_value">{value}</div>
			<div className="orders-body__item orders-body__item_number">{fixed_price}</div>
			<div className={cx(
				"orders-body__item",
				type === 'buy' && 'green',
				type === 'sell' && 'red'
			)}>{type === "buy" ? `${useLang("buy-lowercase", lang)}` : `${useLang("sell-lowercase", lang)}`}</div>
			<div className={cx(
				"orders-body__item",
				status === 'confirmed' && 'green',
				status === 'rejected' && 'red',
				status === 'cancel' && 'red',
				status === 'open' && 'blue',
				status === 'in progress' && 'yellow',
			)}>
				{status === 'confirmed' ? useLang("confirmed", lang) :
				status === 'rejected' ? useLang("rejected", lang) :
				status === 'cancel' ? useLang("canceled", lang) :
				status === 'open' ? useLang("opened", lang) : useLang("in-progress", lang)}
			</div>
			{status === 'open' && (
				<div className="d-flex">
					<button
						className="button-main button-main_success mr-1"
						onClick={() => modalConfirmPayShow(true)}
					>{useLang("pay-uppercase", lang)}</button>
					<button
						onClick={cancelOrder}
						className="button-main button-main_danger"
					>{useLang("cancel-uppercase", lang)}</button>
				</div>
			)}
			{modalConfirmPay && (
				<Modal
					showModal={modalConfirmPay}
					setShowModal={modalConfirmPayShow}
					title={`Confirm Pay: Order #${id}`}
				>
					<ConfirmPayModal
						type={type}
						value={value}
						fixed_price={fixed_price}
						orderId={id}
						userId={user?.id || 1}
						modalConfirmPayShow={modalConfirmPayShow}
						pair={pair}
						currency_pair_id={currency_pair_id}
					/>
				</Modal>
			)}
		</div>
	)
};

export default OrderItem;