/* eslint-disable react-hooks/rules-of-hooks */
import React, { FC, useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css'
import cx from 'classnames';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { selectToken, selectUser, updateToken, updateUser, viewModalRegister } from '../../app/redux/slices/UserSlice';
import { startRegisterUser } from './../../app/redux/slices/UserSlice';
import { selectLang } from './../../app/redux/slices/CoreSlice';
import { useLang } from './../../app/lang/useLang';
import UIButton from '../../widgets/ui/user-interface/Button/UIButton';

interface IRegisterModalProps {

};

const RegisterModal: FC<IRegisterModalProps> = ({ }) => {
	const lang = useAppSelector(selectLang);
  const user = useAppSelector(selectUser);
  const token = useAppSelector(selectToken);
  const dispatch = useAppDispatch();
  const [formData, setFormData] = useState<{
    name: string;
    phone: string;
    email: string;
  }>({
    name: '',
    phone: '',
    email: '',
  });
  const [code, setCode] = useState<string>('');
  const [metamask, setMetamask] = useState<string>('');
  const [metamaskValidate, setMetamaskValidate] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);

  const sendFunc = async () => {
    setLoading(true);
    const userData: any = await dispatch(startRegisterUser(code ? {
      name: formData.name,
      email: formData.email,
      // phone: formData.phone,
      code,
      user_id: Number(localStorage.getItem('user_id')),
    } : {
      name: formData.name,
      // phone: formData.phone,
      email: formData.email,
    }));
    if (!localStorage.getItem('user_id') && userData?.payload?.id) {
      localStorage.setItem('user_id', String(userData?.payload?.id));
    }
    if (userData?.payload?.token) {
      dispatch(updateToken(String(userData.payload.token)))
      localStorage.setItem('token', String(userData.payload.token));
    }
    setLoading(false);
  };

  const updateFunc = async () => {
    if (user && metamask){
      await dispatch(updateUser({
        id: String(user.id),
        metamask_id: metamask,
      }));
      dispatch(viewModalRegister(false));
    } else {
      setMetamaskValidate(true);
    }
  };
  return (
    <div className="register-modal">
      {Boolean(token) ? (
        <div className="register-modal__block">
          <div className="register-modal__title">{useLang("thank-you", lang)}</div>
          <ul>
            <li>{useLang("for-buy-or-sell", lang)}</li>
            <li>{useLang("if-u-havent-got", lang)}</li>
          </ul>
          <div className="mb-3">
            <div>
            <a className="blue" href="https://trustwallet.com/" target="blank">Trust Wallet (recomended)</a>
            </div>
            <div>
              <a className="blue mr-2" href="https://metamask.io/" target="blank">metamask.io</a>
            </div>
          </div>
          <div className="mb-2">{useLang("if-u-have-account", lang)}</div>
          <div className="mb-2">
            <input
              className={cx(
                "input-main",
                metamaskValidate && "input-main_error",
              )}
              placeholder={useLang("write-metamask-id", lang)}
              value={metamask}
              onChange={(e) => {
                setMetamaskValidate(false)
                setMetamask(e.target.value)
              }}
            />
          </div>
          <div className="mb-2 grey-light">{useLang("also-u-can", lang)}</div>
        </div>
      ) : (
        <div>
          <div className="mb-2">
            <input
              className="input-main"
              type="name"
              autoComplete="given-name"
							placeholder={useLang("your-name", lang)}
              onChange={(e) => setFormData({
                ...formData,
                name: e.target.value,
              })}
              disabled={Boolean(user?.phone && !user?.token)}
            />
          </div>
          <div className="mb-2">
            <input
              className="input-main"
              type="email"
              autoComplete="email"
							placeholder="Enter email"
              onChange={(e) => setFormData({
                ...formData,
                email: e.target.value,
              })}
              disabled={Boolean(user?.email && !user?.token)}
            />
          </div>
          {/* <div className="mb-2">
            <PhoneInput
              inputClass='input-main input-main_phone'
              country={'ua'}
              placeholder={useLang("your-phone", lang)}
              value={formData.phone}
              onChange={(e) => setFormData({
                ...formData,
                phone: e,
              })}
              disabled={Boolean(user?.phone && !user?.token)}
            />
          </div> */}
          {user?.email && !user?.token && (
            <div className="mb-3">
              <input
                className="input-main"
								placeholder={useLang("code-from-sms", lang)}
                value={code}
                onChange={(e) => setCode(e.target.value)}
              />
            </div>
          )}
        </div>
      )}
      {token ? (
        <div className="d-flex gap-2">
          <button
            onClick={() => {
              dispatch(viewModalRegister(false))
            }}
            className="button-main"
          >{useLang("skip", lang)}</button>
          <button
            onClick={updateFunc}
            className="button-main"
          >{useLang("next", lang)}</button>
        </div>
      ) : (
        <div>
          <UIButton
            onClick={sendFunc}
            loading={loading}
          >{useLang("next", lang)}</UIButton>
        </div>
      )}
    </div>
  );
};

export default RegisterModal;