/* eslint-disable react-hooks/rules-of-hooks */

import React, { FC, useState } from 'react';
import cx from 'classnames';
import { useAppSelector } from '../../../app/hooks';
import { selectToken, selectUser, startRegisterUser, updateToken, updateUser, viewModalRegister } from '../../../app/redux/slices/UserSlice';
import Svg from '../../../assets/svg/svgSprite';
import MainLayout from '../../../layouts/MainLayout';
import UIButton from '../../../widgets/ui/user-interface/Button/UIButton';
import { Lang, useLang } from './../../../app/lang/useLang';
import { selectLang } from './../../../app/redux/slices/CoreSlice';
import { useAppDispatch } from './../../../app/hooks';
import { useNavigate } from 'react-router-dom';
import { useSearchParams } from 'react-router-dom';

interface ILoginPageProps {

};

const LoginPage: FC<ILoginPageProps> = ({ }) => {
  const lang = useAppSelector(selectLang);
  const user = useAppSelector(selectUser);
  const token = useAppSelector(selectToken);
  const dispatch = useAppDispatch();

  const [searchParams] = useSearchParams();

  const [formData, setFormData] = useState<{
    name: string;
    phone: string;
    email: string;
    refferal?: string | null;
  }>({
    name: '',
    phone: '',
    email: '',
    refferal: searchParams.get('refferal') ? searchParams.get('refferal') : null
  });
  const [code, setCode] = useState<string>('');
  const [metamask, setMetamask] = useState<string>('');
  const [metamaskValidate, setMetamaskValidate] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const sendFunc = async () => {
    setLoading(true);
    const userData: any = await dispatch(startRegisterUser(code ? {
      name: formData.name,
      email: formData.email,
      // phone: formData.phone,
      code,
      user_id: Number(localStorage.getItem('user_id')),
    } : {
      name: formData.name,
      refferal: formData.refferal,
      email: formData.email,
    }));
    if (!localStorage.getItem('user_id') && userData?.payload?.id) {
      localStorage.setItem('user_id', String(userData?.payload?.id));
    }
    if (userData?.payload?.token) {
      dispatch(updateToken(String(userData.payload.token)))
      localStorage.setItem('token', String(userData.payload.token));
    }
    setLoading(false);
  };

  const updateFunc = async () => {
    if (user && metamask) {
      await dispatch(updateUser({
        id: String(user.id),
        metamask_id: metamask,
      }));
      navigate('/profile')
    } else {
      setMetamaskValidate(true);
    }
  };
  return (
    <MainLayout activeMenuItem='login'>
      <div className="container reg">
        <div className="reg-container">
          {Boolean(token) && (
            <div className="register-modal__block reg-wallet-block">
              <div className="register-modal__title">{useLang("thank-you", lang)}</div>
              <ul>
                <li>{useLang("for-buy-or-sell", lang)}</li>
                <li>{useLang("if-u-havent-got", lang)}</li>
              </ul>
              <div className="mb-3">
                <div>
                  <a className="blue" href="https://trustwallet.com/" target="blank">Trust Wallet (recomended)</a>
                </div>
                <div>
                  <a className="blue mr-2" href="https://metamask.io/" target="blank">metamask.io</a>
                </div>
              </div>
              <div className="mb-2">{useLang("if-u-have-account", lang)}</div>
              <div className="mb-2">
                <input
                  className={cx(
                    "input-main",
                    metamaskValidate && "input-main_error",
                  )}
                  placeholder={useLang("write-metamask-id", lang)}
                  value={metamask}
                  onChange={(e) => {
                    setMetamaskValidate(false)
                    setMetamask(e.target.value)
                  }}
                />
              </div>
              <div className="mb-2 grey-light">{useLang("also-u-can", lang)}</div>
              <div className="d-flex gap-2">
                <button
                  onClick={() => {
                    navigate('/profile')
                  }}
                  className="button-main button-main_secondary"
                >{useLang("skip", lang)}</button>
                <button
                  onClick={updateFunc}
                  className="button-main"
                >{useLang("next", lang)}</button>
              </div>
            </div>
          )}
          {!Boolean(token) && (
            <div className="reg-block">
              <div className="title reg-block-title">MION Login</div>
              <div>
                {/* <div className="mb-2">
                  <input
                    className="input-main"
                    type="name"
                    autoComplete="given-name"
                    placeholder={useLang("your-name", lang)}
                    onChange={(e) => setFormData({
                      ...formData,
                      name: e.target.value,
                    })}
                    disabled={Boolean(user?.phone && !user?.token)}
                  />
                </div>
                <div className="mb-3">
                  <input
                    className="input-main"
                    type="email"
                    autoComplete="email"
                    placeholder="Enter email"
                    onChange={(e) => setFormData({
                      ...formData,
                      email: e.target.value,
                    })}
                    disabled={Boolean(user?.email && !user?.token)}
                  />
                </div> */}
              </div>
              <UIButton
                loading={loading}
                onClick={sendFunc}
              >
                Login
              </UIButton>
            </div>
          )}
          <div className="reg-background">
            <Svg svgName="mion-stream" />
          </div>
        </div>
      </div>
    </MainLayout>
  )
};

export default LoginPage;