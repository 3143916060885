import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import Svg from '../../../assets/svg/svgSprite';
import { Lang, useLang } from '../../../app/lang/useLang';
import { useAppSelector } from '../../../app/hooks';
import { selectLang } from './../../../app/redux/slices/CoreSlice';

interface IMobileMenuProps {
  activeMenuItem: string;
};

const MobileMenu: FC<IMobileMenuProps> = ({
  activeMenuItem,
}) => {
  const lang = useAppSelector(selectLang);
  return (
    <div className="menu-mobile">
      <Link className={
        cx(
          "menu__item",
          activeMenuItem === 'home' && "menu__item_active"
        )
      } to="/">
        <div className="menu-mobile-item">
          <Svg
            svgName={activeMenuItem === "home" ? "home-active" : "home"}
          />
          <div className="menu-mobile-item__text">{useLang("home", lang)}</div>
        </div>
      </Link>
      <Link className={
        cx(
          "menu__item",
          activeMenuItem === 'capital' && "menu__item_active"
        )
      } to="/capital">
        <div className="menu-mobile-item">
          <Svg
            svgName={activeMenuItem === "capital" ? "capital-active" : "capital"}
          />
          <div className="menu-mobile-item__text">
            <Lang
              keyWord="capital"
              lang={lang}
            />
          </div>
        </div>
      </Link>
      <a className={
        cx(
          "menu__item",
          activeMenuItem === 'blog' && "menu__item_active"
        )
      } target="_blank" href="https://t.me/homyak_fm">
        <div className="menu-mobile-item">
          <Svg
            svgName="telegram"
          />
          <div className="menu-mobile-item__text">
            <Lang
              keyWord="blog"
              lang={lang}
            />
          </div>
        </div>
      </a>
      <Link className={
        cx(
          "menu__item",
          activeMenuItem === 'profile' && "menu__item_active"
        )
      } to="/profile">
        <div className="menu-mobile-item">
          <Svg
            svgName={activeMenuItem === "profile" ? "profile-menu-active" : "profile-menu"}
          />
          <div className="menu-mobile-item__text">
            <Lang
              keyWord="profile"
              lang={lang}
            />
          </div>
        </div>
      </Link>
    </div>
  )
};

export default MobileMenu;