import { useParams } from "react-router-dom"

export const getDataGE = async(
  url: string,
  method:string = "GET",
  body?: object,
  token?: string,
) => {
  const res = await fetch(url, {
      method: method || 'GET',
      headers: {
          "access-control-allow-origin" : "*",
          'Content-Type': 'application/json;charset=UTF-8',
          'Authorization': 'Bearer ' + token
      },
      ...(body ? {
          body: JSON.stringify(body)
      } : {})
  }).then((response) => {
      if (response.status === 200) {

      }
      return response
  })
  return await res.json()
}

export function transformGE(
  head: any[],
  data: any
){

  const header = head && head.map(el => el.alias)

  const boder = data.map((elem:any) => {
      let accum:any = {}
      header.forEach((headItem) => {
          Object.keys(elem).forEach((key) => {
              if (key === 'user') {

              } else if (headItem === key) {
                  accum[key] = elem[key]
              }
          })
      })
      return accum
  })
  return {
      header: head,
      boder,
      data
  }
}

export const getGridHandlers = (
  updateHandler?: (id:number) => void,
  deleteHandler?: (id:number) => void,
  ordersHandler?: (id:number) => void,
) => {
  return {
      buttons: {
          update: updateHandler ? (id:number) => updateHandler(id) : false,
          delete: deleteHandler ? (id:number) => deleteHandler(id) : false,
          // orders: (id) => ordersHandler(id),
          // potok: (id) => potokHandler(id),
      }
  }
};

export const fetchGridData = async (
  filter: any,
  url: string,
  setGridData: any,
  thead: any,
  settingsPage: any,
  setSettingsPage: any,
  pagination?: boolean,
  urlId?: string,
  token?: string,
) => {
  let sendUrl = url;
  if (urlId){
    sendUrl = url.substring(0, url.length - 3) + urlId
  }
  let filterParams = ''
  Object.keys(filter).forEach((el, idx) => {
    if (idx === 0) {
      filterParams = `?${el}=${filter[el]}`
    } else {
      filterParams = `${filterParams}&${el}=${filter[el]}`
    }
  })
  try {
    const urlFetch = `${sendUrl}${filterParams}`

    const response = await fetch(urlFetch, {
      method: 'GET',
      // cors: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
    })
    if (response.status === 200) {
      const result = await response.json()
      if (pagination) {
        setGridData(transformGE(thead, result.data.data))
      } else {
        setGridData(transformGE(thead, result.data))
      }

      setSettingsPage({
        ...settingsPage,
        maxPage: result.last_page
      })
    }
  } catch (e) {
    console.log(e)
  }
}