/* eslint-disable react-hooks/rules-of-hooks */
import React, { FC, useState } from 'react';
import { useAppSelector } from '../../app/hooks';
import { exitUser, selectToken, selectUser, updateUser } from '../../app/redux/slices/UserSlice';
import MainLayout from '../../layouts/MainLayout';
import UIButton from '../../widgets/ui/user-interface/Button/UIButton';
import ProfileModal from './../../layouts/components/ProfileModal';
import { Lang, useLang } from './../../app/lang/useLang';
import { selectLang } from './../../app/redux/slices/CoreSlice';
import { useAppDispatch } from './../../app/hooks';
import { clearOrders } from '../../app/redux/slices/OrderSlice';
import { copyTextFunc } from '../../widgets/helpers';

interface IProfilePageProps {

};

const ProfilePage: FC<IProfilePageProps> = ({ }) => {
  const lang = useAppSelector(selectLang);
  const user = useAppSelector(selectUser);
  const token = useAppSelector(selectToken);
  const dispatch = useAppDispatch();

  const [openModal, setOpenModal] = useState(false);
  const [showEditWallet, setShowEditWallet] = useState(false);
  const [formData, setFormData] = useState({
    walletID: '',
  });
  const [loadingEditWallet, setLoadingEditWallet] = useState(false);

  const addWalletId = async () => {
    if (user && formData.walletID) {
      setLoadingEditWallet(true)
      await dispatch(updateUser({
        id: String(user.id),
        metamask_id: formData.walletID,
      }));

    }
    setLoadingEditWallet(false);
    setShowEditWallet(false);
  };

  const exitFunc = () => {
    dispatch(exitUser());
    dispatch(clearOrders());
    localStorage.removeItem('user_id');
    localStorage.removeItem('token');
  };
  return (
    <MainLayout activeMenuItem="profile">
      <div className="container profile">
        <div className="title">
          <Lang
            keyWord="profile"
            lang={lang}
          />
        </div>
        {user && token && (
          <div className="table">
            <div className="table-item">
              <div className="table-title">
                <Lang keyWord="name" lang={lang} />
                :</div>
              <div className="table-value">{user?.name}</div>
            </div>
            <div className="table-item">
              <div className="table-title">{<Lang keyWord="email" lang={lang} />}</div>
              <div className="table-value">{user?.email}</div>
            </div>
            <div className="table-item">
              <div className="table-title">Refferal Link:</div>
              <div className="table-value">
                <div
                  onClick={() => copyTextFunc(`https://exchange.mion.company/auth/registration?refferal=${user?.refferalKey}`)}
                  className="confirm-modal__link blue"
                >{useLang("click-to-copy", lang)} exchange.../?refferal={user?.refferalKey}</div>
              </div>
            </div>
            <div className="table-item wallet-id align-items-center">
								<div className="table-title">{<Lang keyWord="wallet-id" lang={lang} />}</div>
              <div className="table-value d-flex">
                {!showEditWallet && <div className="table-value-wallet d-flex align-items-center ">{user.metamask_id}</div>}
                {showEditWallet && (
                  <div className="table__wallet-box d-flex align-items-center">
                    <input
                      className="input-main"
                      type="email"
                      placeholder={useLang("enter-wallet-id", lang)}
                      value={formData.walletID}
                      onChange={(e) => setFormData({
                        ...formData,
                        walletID: e.target.value,
                      })}
                    />
												<UIButton
													onClick={() => {setShowEditWallet(false)}}
													loading={false}
													flexible
												>{<Lang keyWord="cancel-uppercase" lang={lang} />}
												</UIButton>
												<UIButton
													onClick={addWalletId}
													loading={loadingEditWallet}
													flexible
												>{<Lang keyWord="save" lang={lang} />}</UIButton>
                  </div>
                )}
                {!showEditWallet && <UIButton
                  onClick={() => {
                    setShowEditWallet(true);
                    setFormData({
                      walletID: user.metamask_id,
                    });
                  }}
                  loading={false}
									flexible
              >{user.metamask_id ? <Lang keyWord="edit" lang={lang} /> : <Lang keyWord="add-wallet" lang={lang} />}
								</UIButton>}
              </div>
            </div>
            {Boolean(user?.refferals?.length) && (
              <div>
                <div className="white mb-2">My refferals</div>
                {user?.refferals?.map((refferal, idx) => (
                  <div
                    className="grey-light"
                  >{idx + 1}. {refferal.name}</div>
                ))}
              </div>
            )}
            <div className="mt-3">
              <UIButton
                onClick={exitFunc}
                loading={false}
                flexible
              >
                <Lang
                  keyWord="exit"
                  lang={lang}
                />
              </UIButton>
            </div>
          </div>
        )}
        {!user && (
          <div className="d-flex flex-column align-items-center">
            <div className='white mr-1'>
              <Lang keyWord="need-auth" lang={lang} />
            </div>
            <div>
              <UIButton
                onClick={() => setOpenModal(true)}
                loading={false}
                flexible
              >
                <Lang keyWord="enter" lang={lang} />
              </UIButton>
            </div>
          </div>
        )}
        {openModal && (
          <ProfileModal setOpenModal={setOpenModal} />
        )}
      </div>
    </MainLayout>
  )
};

export default ProfilePage;