import React, { FC, useRef, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import styles from './Chart.module.scss';
import { fetchCurrency, selectCurrency, selectLang } from './../../app/redux/slices/CoreSlice';
import { useLang } from './../../app/lang/useLang';
import { useSearchParams } from 'react-router-dom';

interface IChartProps {

};


const Chart: FC<IChartProps> = ({ }) => {
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState<boolean>(false);
  const coreLoading = useAppSelector(selectCurrency);
  const currency = useAppSelector(selectCurrency);
  const dispatch = useAppDispatch();
  const canvasRef = useRef<HTMLCanvasElement>(null);

  let MAX_VALUE = 0;
  let MIN_VALUE = 9999999999;
  currency?.forEach((item) => {
    if (item.value > MAX_VALUE) {
      MAX_VALUE = item.value
    }
    if (item.value < MIN_VALUE) {
      MIN_VALUE = item.value
    }
  });
  const SCALE_K = MIN_VALUE;
  let MULTIPLE_INDEX = 3000000;
  if (MAX_VALUE > 0.1) {
    MULTIPLE_INDEX = 10000;
  }

  const drawGraph = (
    ctx: CanvasRenderingContext2D | null,
    width = 0,
    height = 0,
    data: { startValue: number, endValue: number, date: string }[],
    multiple: number,
  ) => {
    if (ctx) {
      const yRecord = (value: number) => {
        return height - value - 20;
      }
      data.forEach((item, idx) => {
        if (item.startValue - item.endValue < 0) {
          ctx.fillStyle = '#2EBD85';
        } else if (item.startValue - item.endValue === 0) {
          ctx.fillStyle = '#fcd434';
        } else {
          ctx.fillStyle = '#F6465D';
        }
        ctx.fillRect(
          idx * 15,
          yRecord((item.startValue - SCALE_K) * multiple),
          12,
          ((item.startValue - item.endValue) !== 0 ? item.startValue - item.endValue : -1 / multiple) * multiple
        );
        ctx.fillStyle = '#2EBD85';
        ctx.font = `11px Montserrat`;
        if (!(idx % 3)) {
          ctx.fillText(item.date.substring(5, 10), (idx * 15) - 2, yRecord(-20));
        }
      });
    }
  }

  const drawY = (
    ctx: CanvasRenderingContext2D | null,
    width: number,
    height: number,
    start: number,
    now: number,
    end: number,
    step: number,
    MULTIPLE_INDEX: number,
  ) => {
    const yRecord = (value: number) => {
      return height - value - 20;
    }
    if (ctx) {
      ctx.font = `12px Montserrat`;
      //Start value on Y (bottom)
      ctx.fillText(String(start), width - 60, height - 18)
      //End value on Y (top)
      ctx.fillText(String((end / MULTIPLE_INDEX) + SCALE_K), width - 60, 10)
      ctx.fillStyle = '#343A44';
      ctx.fillRect(0, yRecord(((now - SCALE_K) * MULTIPLE_INDEX)), 790, 1);
      ctx.fillStyle = '#2EBD85'
      ctx.fillText(String(now), width - 60, yRecord((now - SCALE_K) * MULTIPLE_INDEX) - 3);
    }
  }

  useEffect(() => {
    dispatch(fetchCurrency({
      currency_pair_id: Number(searchParams.get('pair')),
    }));
  }, []);

  useEffect(() => {
    if (canvasRef.current && currency) {
      const transformData = currency.map((item, idx) => ({
        startValue: currency[idx - 1]?.value || SCALE_K,
        endValue: item.value,
        date: item.date,
      }))
      const canvas = canvasRef.current;
      const widthWindow = window.innerWidth;

      let widthCanvas = 800;
      let heightCanvas = 250;

      if (widthWindow > 1100 && widthWindow < 1300) {
        widthCanvas = 600;
      } else if (widthWindow > 576 && widthWindow <= 1100) {
        widthCanvas = 500;
      } else if (widthWindow < 576) {
        widthCanvas = widthWindow - 49
      }
      canvas.width = widthCanvas;
      canvas.height = heightCanvas;
      const ctx = canvas.getContext('2d');

      const width = canvasRef.current.width
      const height = canvasRef.current.height
      drawGraph(
        ctx,
        width,
        height,
        transformData,
        MULTIPLE_INDEX,
      );
      drawY(
        ctx,
        width,
        height,
        transformData[0].startValue,
        transformData[transformData.length - 1].endValue,
        250,
        1,
        MULTIPLE_INDEX,
      );
      if (ctx) {
        canvas.addEventListener('mousemove', (event) => {
          ctx.clearRect(0, 0, canvas.width, canvas.height);
          drawGraph(
            ctx,
            width,
            height,
            transformData,
            MULTIPLE_INDEX,
          );
          drawY(
            ctx,
            width,
            height,
            transformData[0].startValue,
            transformData[transformData.length - 1].endValue,
            250,
            1,
            MULTIPLE_INDEX,
          );
          if (height - event.offsetY > 20) {
            //Value price on move
            //Line X-Y value
            ctx.fillStyle = '#343A44';
            ctx.fillRect(event.offsetX, 0, 1, 230);
            ctx.fillRect(0, event.offsetY, (widthCanvas - 65), 1);

            const idDate = Math.floor(event.offsetX / 15);
            ctx.font = `12px Montserrat`;
            ctx.fillStyle = '#1F2630';
            ctx.fillRect((widthCanvas - 65), event.offsetY - 10, 70, 20);
            ctx.fillStyle = '#fff';
            ctx.fillText(`${(((height - event.offsetY - 20) / MULTIPLE_INDEX) + SCALE_K).toFixed(7)}`, (widthCanvas - 60), event.offsetY + 5);
            if (idDate < transformData.length) {
              ctx.fillStyle = '#1F2630';
              ctx.fillRect(event.offsetX - 20, 230, 40, 20);
              ctx.fillStyle = '#fff';
              ctx.fillText(transformData[idDate].date.substring(5, 10), event.offsetX - 10, 250);
            }
          } else {
            // ctx.clearRect(0, 0, canvas.width, canvas.height);
          }
          ctx.fillStyle = '#343A44';
        });

      }
    }
  }, [coreLoading]);
  const lang = useAppSelector(selectLang);
  return (
    <div className="chart">
      <canvas ref={canvasRef} id="canvas">
        {useLang("browser-does-not", lang)}
      </canvas>
      <div className="chart-line"></div>
      <div className="chart-line-y"></div>
    </div>
  )
};

export default Chart;