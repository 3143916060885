import { FC } from "react";
import { ISvg } from "../svgSprite";

export const EnergySvg: FC<ISvg> = (config) => (
  <svg width="71" height="71" viewBox="0 0 71 71" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M57.9998 13.448C53.9736 11.0051 48.796 9.14054 45.0509 7.96632C44.7491 7.87532 44.3737 7.76184 43.9133 7.73787C41.1214 7.62936 38.2337 7.57422 35.3303 7.57422C32.7632 7.57582 30.2348 7.61569 27.8962 7.70504C25.6202 7.71231 23.8778 8.71182 21.8679 9.39152C18.6653 10.5999 15.3273 11.8361 12.4115 13.868C9.33373 16.5358 6.8605 18.9892 5.11485 22.5942C4.39562 24.3236 4.70394 25.1308 5.25661 26.1649C10.3084 36.1898 16.5612 47.5769 25.8122 57.1201C33.5105 65.7301 38.0513 65.4994 45.6853 56.5773C53.9708 48.0439 60.997 35.8811 65.8048 26.071C66.9499 24.2234 65.8568 22.537 65.2529 21.4018C63.4834 18.464 61.0324 15.7766 57.9998 13.448V13.448Z" fill="white" />
    <path d="M57.1415 14.7324C55.9345 13.9257 54.5847 13.3176 53.2619 12.7298C50.4896 11.4979 47.6294 10.3402 44.7168 9.47915C44.4389 9.3969 44.1453 9.29685 43.8533 9.28159C40.8259 9.16385 37.7958 9.11249 34.7662 9.11983C32.4782 9.12551 30.1901 9.16491 27.9037 9.25076C26.5346 9.20544 25.244 9.80444 23.9768 10.2458C20.3707 11.6275 16.6099 12.8755 13.3958 15.0586C11.5659 16.5716 9.87757 18.2644 8.43509 20.1536C7.73107 21.0762 6.99657 22.0921 6.54134 23.1878C5.96991 24.5614 6.28852 24.7638 6.82978 25.8401C12.2734 36.6671 18.4365 47.2959 26.9211 56.0448C29.2479 58.3568 32.1591 61.9125 35.68 61.9342C39.6019 61.7401 43.369 56.6994 45.9422 54.0106C48.3301 51.368 50.5583 48.5924 52.6199 45.6877C57.1795 39.2632 60.9397 32.3184 64.4694 25.288C65.193 24.2695 64.3928 23.0671 63.8914 22.1318C62.1527 19.275 59.7741 16.7768 57.1415 14.7324V14.7324Z" fill="#68182B" />
    <path d="M57.823 17.1566C58.2217 16.6824 58.2822 16.5524 58.8489 17.0896C59.9611 18.1441 61.0066 19.2699 61.943 20.4815C62.5057 21.2097 63.096 22.0024 63.5354 22.8485C64.0503 23.8394 63.6821 23.6172 63.0188 23.2097C60.9935 21.9655 58.8114 20.9447 56.6014 20.1104C56.3897 20.0304 56.1771 19.9533 55.9636 19.8787C55.03 19.5536 54.7672 19.4853 55.5166 18.9875C56.3298 18.4462 57.1955 17.9037 57.823 17.1566V17.1566Z" fill="#CEFFFF" />
    <path d="M14.2211 15.3001C15.288 14.5407 16.5547 14.0337 17.723 13.4746C20.1776 12.387 22.6844 11.3346 25.2514 10.5149C26.9411 9.97535 26.642 9.9789 28.1159 9.92482C31.9594 9.78454 35.8071 9.7707 39.6523 9.84175C40.5459 9.86169 41.4405 9.87992 42.3343 9.90796C43.8266 9.95441 43.9869 9.96666 45.2079 10.3476C47.0148 10.9114 48.7774 11.6231 50.538 12.3121C52.0566 12.9256 53.5665 13.5719 55.0273 14.314C55.7097 14.6953 56.4839 15.0341 57.1005 15.5334C57.6557 15.9833 57.6494 16.3592 57.1115 16.9141C55.5763 18.498 51.2529 20.7733 48.6176 21.8692C47.1849 22.465 47.2527 22.4469 46.0536 22.4673C39.1922 22.5831 32.298 22.6554 25.4463 22.4609C23.5549 22.4076 24.1475 22.5924 22.6278 21.9715C20.1496 20.9585 15.7266 18.7183 14.0027 17.0476C13.4044 16.4679 13.3679 15.9073 14.2211 15.3001V15.3001Z" fill="#CEFFFF" />
    <path d="M35.4698 24.4821C35.4516 23.5208 35.8917 23.6168 36.5658 24.002C40.6313 26.3246 44.5222 28.957 48.1623 31.9028C49.1758 32.7232 48.7118 32.6876 47.5679 32.7081C43.8306 32.7757 40.0922 32.7955 36.3544 32.7947C35.3064 32.7947 35.4386 32.6319 35.4499 31.8122C35.4843 29.369 35.5156 26.9248 35.4698 24.4821V24.4821Z" fill="#8EDBFF" />
    <path d="M37.125 23.8515C36.4715 23.4756 35.9167 23.2483 37.4292 23.2439C40.1935 23.2365 42.9576 23.2114 45.7217 23.1632C46.8053 23.1443 46.8654 23.3721 47.2025 24.1136C48.2362 26.385 49.1426 28.7202 49.6555 31.1689C49.6867 31.3116 49.7166 31.4547 49.7455 31.5977C49.914 32.4324 49.8859 32.7856 48.8937 31.9749C45.1988 28.9552 41.262 26.2304 37.125 23.8515Z" fill="#CEFFFF" />
    <path d="M48.0724 24.3943C47.6617 23.4831 47.4428 23.1038 48.7199 22.5652C50.3175 21.8923 51.8738 21.121 53.3875 20.2746C54.0596 19.8991 54.0434 20.1456 53.9966 20.8444C53.7511 24.5047 52.7179 28.1729 51.1596 31.4725C50.6835 32.481 50.5815 32.2935 50.4368 31.5633C49.9458 29.0881 49.1083 26.6948 48.0724 24.3943V24.3943Z" fill="#8EDBFF" />
    <path d="M51.6149 31.4942C53.0142 28.3781 54.0053 25.0481 54.3423 21.6497C54.5029 20.0286 54.6459 20.5946 55.2436 21.7334C55.682 22.5686 56.0855 23.4241 56.4844 24.2687C57.0685 25.5544 57.6974 26.8353 58.1564 28.1731C58.3866 28.8444 58.5032 28.8854 57.5309 29.415C56.2329 30.1225 54.9062 30.7787 53.5676 31.3925C53.1807 31.57 52.7911 31.7459 52.3978 31.9128C51.4145 32.3291 51.2535 32.2995 51.6149 31.4942V31.4942Z" fill="#CEFFFF" />
    <path d="M14.1642 30.5628C15.0967 31.0453 16.0409 31.5035 16.99 31.9411C17.7277 32.2747 18.4681 32.6222 19.2299 32.9059C20.3682 33.3303 20.1158 33.4794 20.3732 34.5011C20.6367 35.5449 21.0417 36.5601 21.386 37.5746C23.51 43.2454 26.1309 48.7425 29.2413 53.9392C30.2506 55.6062 31.3236 57.2568 32.5178 58.8083C33.1695 59.6551 33.1047 59.7694 32.3391 59.0566C32.1665 58.8959 31.9955 58.7333 31.8266 58.5694C27.6136 54.4056 24.167 49.5339 20.9387 44.5878C18.2237 40.3501 15.622 36.0111 13.5335 31.4259C13.4605 31.2579 13.3876 31.0895 13.3168 30.9203C12.8981 29.9171 13.6709 30.3075 14.1642 30.5628V30.5628Z" fill="#CEFFFF" />
    <path d="M13.1864 27.8533C14.0051 25.6497 15.0231 23.5128 16.1471 21.4464C16.9777 19.9197 16.8324 20.7081 16.8978 21.4545C17.2047 24.9484 18.2535 28.3696 19.6872 31.5625C20.0874 32.4534 19.7785 32.3825 19.0774 32.1075C17.1523 31.3519 15.2945 30.4158 13.4793 29.4201C12.6974 28.9912 12.8293 28.814 13.1864 27.8533V27.8533Z" fill="#8EDBFF" />
    <path d="M19.7275 30.6555C18.6013 28.0143 17.7881 25.2199 17.4139 22.3843C17.3587 21.9655 17.3148 21.5454 17.283 21.1245C17.2114 20.1766 17.3663 20.1595 18.2424 20.6274C18.4691 20.7487 18.6965 20.8681 18.9241 20.9864C20.0988 21.5915 21.2929 22.1979 22.5292 22.6783C23.2881 22.9731 23.2622 23.2558 22.9321 23.9857C22.342 25.29 21.7859 26.6098 21.3332 27.9689C21.0487 28.8233 20.8042 29.6909 20.6001 30.5679C20.3036 31.8422 20.2115 31.7907 19.7275 30.6555V30.6555Z" fill="#CEFFFF" />
    <path d="M21.1104 31.3978C21.6178 28.9858 22.4543 26.6538 23.481 24.4134C23.5019 24.3663 23.5229 24.3195 23.544 24.2727C24.0529 23.1498 24.2807 23.1429 25.1416 23.1622C25.8356 23.1776 26.5304 23.1786 27.2237 23.1943C29.1743 23.2215 31.125 23.2378 33.0761 23.2449C34.1734 23.2488 34.0372 23.4394 33.0655 24.0046C29.7042 25.9603 26.4696 28.1306 23.3928 30.5104C22.887 30.9016 22.3867 31.3 21.8905 31.7034C21.0875 32.3566 20.8848 32.4695 21.1104 31.3978V31.3978Z" fill="#8EDBFF" />
    <path d="M7.36221 23.0751C7.45327 22.9021 7.56261 22.6996 7.69165 22.4642C9.01492 20.2132 10.8146 18.2692 12.7409 16.5225C13.1104 17.2957 13.8279 17.8142 14.4914 18.3187C14.8554 18.5884 15.2293 18.8451 15.61 19.0921C16.1598 19.4489 16.2466 19.549 15.3009 19.8754C13.007 20.6681 10.7861 21.7306 8.7021 22.9451C8.37817 23.1192 8.08718 23.3062 7.84146 23.4713C7.01568 24.0258 6.70067 24.3328 7.36221 23.0751V23.0751Z" fill="#8EDBFF" />
    <path d="M7.75629 24.0038C10.1551 22.4427 12.7785 21.1933 15.4822 20.2553C16.3665 19.9486 16.285 20.2907 15.859 21.0691C15.313 22.0662 14.8266 23.1012 14.3464 24.1196C13.9331 24.9965 13.535 25.8809 13.1629 26.776C13.0299 27.0955 12.8957 27.4175 12.7719 27.743C12.4116 28.6891 11.9564 28.5636 11.3925 28.2268C10.4538 27.6656 9.5301 27.0724 8.66183 26.4086C8.30926 26.1402 7.92503 25.8539 7.59778 25.5203C6.96706 24.8778 7.0925 24.4357 7.75629 24.0038V24.0038Z" fill="#CEFFFF" />
    <path d="M32.6481 60.2436C29.7385 58.4748 27.5337 55.6556 25.2303 53.1992C18.5613 45.8079 13.4654 37.1851 8.89411 28.3783C7.78344 26.2379 8.9147 27.4514 9.65405 27.9404C10.2997 28.3674 10.9591 28.7724 11.6283 29.1649C12.2917 29.5538 12.5447 30.0727 12.8321 30.8055C16.4906 38.985 21.437 46.5894 26.9088 53.6673C28.6709 55.8783 30.5509 58.0931 32.7102 59.9522C33.4309 60.5727 33.4299 60.7184 32.6481 60.2436V60.2436Z" fill="#8EDBFF" />
    <path d="M34.9987 34.7896C34.9844 35.6413 34.9692 36.4933 34.9539 37.3449C34.8554 43.0678 34.7257 48.7915 34.765 54.5156C34.7765 55.9585 34.7964 57.4036 34.8553 58.8466C34.9171 60.358 34.8412 60.5944 33.7723 59.2988C32.5527 57.8203 31.4701 56.2221 30.4537 54.6072C27.2464 49.4535 24.5889 43.9586 22.3851 38.3056C21.9422 37.1025 21.4703 35.8971 21.1301 34.6582C20.8658 33.6958 20.9572 33.375 21.8611 33.3799C25.7602 33.3998 29.8863 33.4946 33.8469 33.4795C34.7163 33.477 35.0199 33.5249 34.9987 34.7896V34.7896Z" fill="#8EDBFF" />
    <path d="M35.0467 31.4536C35.0325 32.5413 34.8746 32.7949 33.931 32.7935C31.1349 32.7902 28.3385 32.7766 25.5424 32.7414C24.6001 32.7295 23.6577 32.7149 22.7157 32.6964C21.8903 32.6804 21.3123 32.6927 22.3496 31.8578C25.9928 28.9263 29.8696 26.2861 33.9336 23.9734C35.2554 23.2215 35.0469 23.8067 35.0617 24.5939C35.1047 26.8801 35.0771 29.1671 35.0467 31.4536V31.4536Z" fill="#CEFFFF" />
    <path d="M35.3136 59.8919C35.2302 58.4031 35.2014 56.9101 35.1829 55.4203C35.1125 48.5791 35.2959 41.7389 35.4055 34.8988C35.4257 33.6359 35.2612 33.4767 36.5744 33.4757C40.5197 33.4725 44.4649 33.4498 48.4094 33.3755C48.5144 33.3735 48.62 33.3725 48.726 33.3719C49.9397 33.3638 50.0696 33.2457 49.6565 34.6406C48.5811 38.2669 46.9728 41.7241 45.3919 45.1527C42.874 50.2754 40.2817 55.5113 36.6654 59.9565C35.1807 61.7812 35.3961 61.368 35.3136 59.8919V59.8919Z" fill="#CEFFFF" />
    <path d="M38.2793 59.0064C39.2266 57.7126 40.0943 56.3569 40.9215 54.9941C44.6007 48.7913 47.7747 42.2467 50.1404 35.429C50.2468 35.0924 50.3577 34.7518 50.4514 34.4072C50.7822 33.1938 50.7814 33.3086 51.6794 32.9467C51.9562 32.8351 52.2271 32.7144 52.488 32.6054C54.1476 31.9114 55.7777 31.1277 57.3671 30.274C58.2657 29.7912 58.3575 29.7738 57.957 30.7642C54.2963 38.8614 49.4073 46.4101 43.9742 53.4267C42.2968 55.5052 40.5474 57.5576 38.5543 59.3415C37.1444 60.6032 37.1544 60.5431 38.2793 59.0064V59.0064Z" fill="#8EDBFF" />
    <path d="M62.1147 28.4097C57.9421 36.469 53.316 44.3473 47.4321 51.285C45.3495 53.6935 43.2247 56.0777 40.9277 58.285C40.3033 58.872 39.6225 59.4947 38.8815 60.0184C37.4672 61.0175 37.5041 60.8476 38.82 59.6585C39.0966 59.4084 39.3682 59.1534 39.6354 58.895C43.9041 54.6834 47.3915 49.7483 50.6592 44.741C53.3833 40.4821 56.0001 36.1234 58.0833 31.5105C58.2019 31.2275 58.3233 30.9462 58.4369 30.662C58.8621 29.5982 58.6714 29.5658 59.6866 28.9644C60.4409 28.5179 61.1857 28.0538 61.9036 27.5519C63.0596 26.7433 62.834 27.0202 62.1147 28.4097V28.4097Z" fill="#CEFFFF" />
    <path d="M63.5827 25.2865C62.6747 26.3505 60.8906 27.4395 59.6088 28.21C58.7312 28.7376 58.7522 28.5953 58.3924 27.6594C57.4911 25.3131 56.418 23.0326 55.1966 20.8364C54.4719 19.5325 55.202 20.0533 55.8745 20.2892C58.4822 21.2032 61.0803 22.3957 63.3365 23.9216C64.1275 24.4564 63.9878 24.8119 63.5827 25.2865V25.2865Z" fill="#8EDBFF" />
    <path d="M13.4793 29.4204C15.2946 30.4161 17.1523 31.3522 19.0774 32.1078C19.2769 32.1861 19.4435 32.2467 19.5722 32.2743C18.8328 31.8895 18.1508 31.3638 17.4773 30.8992C16.5045 30.2279 15.4251 29.5391 14.7967 28.5091C14.1106 27.3849 14.5888 25.8237 14.897 24.6497C15.0711 23.9859 15.2875 23.3339 15.4958 22.6797C14.6302 24.3603 13.8433 26.0851 13.1863 27.8536C12.8293 28.8144 12.6974 28.9916 13.4793 29.4204V29.4204Z" fill="#73B9D5" />
    <path d="M22.0891 28.0001C22.1048 27.9053 22.1261 27.8118 22.1455 27.7178C21.7256 28.9224 21.3731 30.1495 21.1104 31.3984C20.8848 32.4701 21.0875 32.3572 21.8907 31.7041C22.3868 31.3007 22.8872 30.9023 23.393 30.511C26.4697 28.1312 29.7044 25.961 33.0657 24.0052C33.4831 23.7624 33.745 23.5892 33.8225 23.4697C33.0012 23.8224 32.2064 24.2345 31.3993 24.6376C29.8955 25.3886 28.443 26.1966 27.0605 27.1552C25.767 28.0521 24.543 29.0861 23.1824 29.8812C23.0439 29.9599 22.8979 30.0215 22.7443 30.0659C21.9199 30.3316 21.9333 29.4874 21.9481 29.113C21.9626 28.7473 22.0296 28.3584 22.0891 28.0001V28.0001Z" fill="#73B9D5" />
    <path d="M50.4278 28.449C49.7205 28.0208 49.4282 26.8833 49.1073 26.1729C48.8775 25.6646 48.5325 25.0234 48.113 24.4877C49.1298 26.7597 49.9523 29.1216 50.4367 31.5636C50.5814 32.2938 50.6834 32.4813 51.1595 31.4728C52.2942 29.0701 53.1487 26.4715 53.6251 23.8232C53.3692 24.4117 53.1239 25.0053 52.8648 25.5898C52.5468 26.3078 51.5732 29.1426 50.4278 28.449V28.449Z" fill="#73B9D5" />
    <path d="M7.51221 23.6947C7.61036 23.6279 7.71965 23.5527 7.84147 23.4708C8.08719 23.3058 8.37817 23.1188 8.70211 22.9446C10.7862 21.7302 13.007 20.6677 15.3009 19.875C16.2465 19.5485 16.1598 19.4485 15.6101 19.0916C15.2293 18.8447 14.8554 18.5879 14.4914 18.3182C14.4199 18.264 14.3478 18.2093 14.2756 18.1543C14.3184 18.2607 14.3611 18.3671 14.3962 18.4765C14.6871 19.3815 13.8648 19.8072 13.1952 20.0775C12.4097 20.3948 11.65 20.7191 10.8996 21.1198C10.0217 21.5886 9.20709 22.1506 8.45899 22.8054C8.16233 23.065 7.82106 23.3656 7.51221 23.6947V23.6947Z" fill="#73B9D5" />
    <path d="M31.7089 56.4018C30.8197 54.6886 30.0089 52.9283 29.144 51.2017C27.367 47.6534 25.65 44.0443 24.7143 40.1695C24.3925 38.8368 24.1243 37.3855 24.8424 36.1319C25.3691 35.2124 26.3831 34.7073 27.34 34.3583C28.4256 33.9623 29.5838 33.7525 30.7153 33.4719C27.7454 33.45 24.7413 33.3946 21.861 33.3799C20.9573 33.375 20.8659 33.6958 21.1301 34.6582C21.4703 35.8971 21.9422 37.1025 22.3851 38.3056C24.5889 43.9586 27.2463 49.4535 30.4537 54.6072C30.9654 55.4202 31.4941 56.2287 32.0483 57.0201C31.9311 56.8169 31.8176 56.6111 31.7089 56.4018V56.4018Z" fill="#73B9D5" />
    <path d="M36.3544 32.7948C40.0825 32.7956 43.8111 32.7758 47.5389 32.7086C46.5491 32.5052 45.485 32.4723 44.5716 32.4159C42.6928 32.2998 40.7927 32.3682 38.9211 32.1656C38.1264 32.0795 37.3607 31.8486 36.7893 31.2643C36.0759 30.5349 35.952 29.2375 35.8234 28.2788C35.7166 27.4833 35.5969 26.6805 35.5212 25.8818C35.5066 25.7279 35.494 25.573 35.4805 25.418C35.5044 27.5492 35.48 29.6811 35.4499 31.8123C35.4386 32.632 35.3064 32.7948 36.3544 32.7948V32.7948Z" fill="#73B9D5" />
    <path d="M57.957 30.7642C58.3576 29.7738 58.2657 29.7912 57.3671 30.274C56.116 30.946 54.8391 31.5724 53.5429 32.1482C53.7185 32.1131 53.8941 32.0812 54.0693 32.0578C54.5662 31.9918 55.2334 31.9176 55.6453 32.2643C56.1908 32.7236 55.501 34.015 55.283 34.4753C53.7476 37.7159 51.7645 40.7552 49.8859 43.8021C47.8797 47.0564 45.8265 50.2834 43.6103 53.3997C42.7915 54.5511 41.9468 55.7006 41.0735 56.831C42.0817 55.7316 43.0401 54.5843 43.9741 53.4267C49.4073 46.4101 54.2963 38.8614 57.957 30.7642Z" fill="#73B9D5" />
    <path d="M28.2132 55.2704C27.7702 54.7394 27.3359 54.2036 26.9089 53.668C21.4372 46.59 16.4908 38.9857 12.8323 30.8062C12.5449 30.0733 12.2919 29.5545 11.6284 29.1655C11.1105 28.8617 10.6008 28.5468 10.0964 28.2236C11.7494 30.2877 12.8917 32.7698 14.0991 35.1028C14.9293 36.707 15.697 38.3435 16.5865 39.9162C17.4255 41.3992 18.3618 42.8258 19.3393 44.2206C21.2523 46.9499 23.3659 49.5323 25.4366 52.1415C26.3054 53.236 27.2581 54.2534 28.2132 55.2704V55.2704Z" fill="#73B9D5" />
    <path d="M63.3236 25.5653C62.118 26.2664 60.7224 26.6969 59.4798 26.1634C58.3684 25.6862 57.6555 24.2412 56.9922 23.2993C56.5184 22.6266 56.0075 21.9482 55.4568 21.3135C56.5701 23.3625 57.556 25.4821 58.3925 27.6591C58.7522 28.5949 58.7312 28.7373 59.6089 28.2098C60.7788 27.5065 62.365 26.5379 63.3236 25.5653V25.5653Z" fill="#73B9D5" />
    <path d="M11.6305 18.1519C11.1862 18.5336 10.8177 19.0002 10.4586 19.4604C10.3311 19.6241 10.1961 19.7913 10.0639 19.9626C9.04301 21.2869 10.1787 20.6577 10.5408 20.4613C11.1721 20.1191 11.8105 19.7961 12.4691 19.4944C12.7565 19.3626 13.5036 18.976 13.2488 18.5299C12.9288 17.9706 12.1574 17.6989 11.6305 18.1519Z" fill="#CEFFFF" />
    <path d="M15.8414 24.3156C15.5987 25.4193 15.5119 26.5646 15.8518 27.6441C16.0096 28.1455 16.426 28.9054 16.9477 29.4462C17.1151 29.6195 18.5869 31.3074 17.854 28.7524C17.5886 27.8261 17.154 26.3751 16.6859 24.2736C16.2696 22.406 16.0315 23.4521 15.8414 24.3156V24.3156Z" fill="#CEFFFF" />
    <path d="M27.9915 23.9728C27.5356 23.9353 27.0791 23.9065 26.6213 23.8951C24.8088 23.8502 24.3884 24.2127 23.5366 26.3027C23.0468 27.5042 23.0725 28.1597 24.6096 27.3893C25.6057 26.8902 27.0206 25.9988 28.0924 25.2882C29.4502 24.3883 29.5742 24.1029 27.9915 23.9728V23.9728Z" fill="#CEFFFF" />
    <path d="M43.0696 29.6727C42.7587 29.4265 42.4356 29.2064 42.1937 29.0084C41.2878 28.2667 40.0969 27.5241 38.2814 26.3501C37.273 25.6981 36.6378 25.6789 37.111 27.632C37.2931 28.3855 37.4713 29.1422 37.87 29.8132C38.2541 30.4597 38.9123 30.8331 39.616 31.0344C39.8738 31.1081 41.2319 31.3188 42.3811 31.3638C44.0928 31.431 44.7476 31.0019 43.0696 29.6727Z" fill="#CEFFFF" />
    <path d="M50.6347 26.3361C51.6983 26.2352 52.2553 24.4908 52.5095 23.6549C52.5601 23.4881 52.705 23.0847 52.7952 22.6889C53.0674 21.4962 52.73 21.5885 51.8111 22.0394C51.3409 22.2701 50.8665 22.4919 50.4078 22.7452C50.0342 22.9514 49.5612 23.2741 49.5 23.7401C49.4327 24.2518 49.7726 26.4178 50.6347 26.3361V26.3361Z" fill="#CEFFFF" />
    <path d="M61.7074 23.758C60.9516 23.2631 60.1708 22.7985 59.3736 22.3738C57.7791 21.5256 57.0186 21.4683 58.0129 22.9853C58.2601 23.3623 58.5062 23.7399 58.7603 24.1126C59.0678 24.5632 59.468 25.0468 59.998 25.2415C60.5626 25.4489 61.1107 25.398 61.6759 25.2103C61.9375 25.1234 62.4274 24.9625 62.4274 24.6111C62.4274 24.2173 61.9942 23.9459 61.7074 23.758Z" fill="#CEFFFF" />
    <path d="M54.0134 33.5686C53.6726 33.3803 53.0172 33.595 52.6738 33.6677C52.2659 33.7542 51.6434 33.8968 51.4094 34.2868C51.3867 34.3246 51.4455 34.3587 51.4682 34.3211C51.4824 34.2973 51.5048 34.2727 51.5284 34.2479C48.7343 41.2585 46.0864 48.4273 41.8406 54.7238C41.8175 54.7581 41.8716 54.7882 41.8954 54.7557C45.3998 49.9483 48.5975 44.8983 51.581 39.7531C52.4112 38.3213 53.3134 36.8699 53.9408 35.3328C54.127 34.876 54.6209 33.9041 54.0134 33.5686V33.5686Z" fill="#CEFFFF" />
    <path d="M33.1076 43.0793C33.02 41.1692 33.0092 39.2677 33.0105 37.3577C33.0116 35.7127 32.4445 34.9383 30.4888 35.3805C29.4527 35.6149 28.3583 35.9505 28.0182 36.2175C27.0053 37.0128 26.9775 38.2597 27.1186 39.4435C27.4392 42.1349 29.4836 47.6008 30.8954 50.8396C32.8708 55.3702 33.4091 56.1942 33.3495 49.7794C33.3288 47.5443 33.2103 45.3119 33.1076 43.0793V43.0793Z" fill="#CEFFFF" />
    <path d="M32.9177 59.3823C32.853 59.2634 32.7201 59.0714 32.5179 58.8089C31.3236 57.2573 30.2507 55.6068 29.2414 53.9397C26.131 48.743 23.5101 43.246 21.3861 37.5751C21.0417 36.5607 20.6368 35.5454 20.3733 34.5017C20.1158 33.48 20.3682 33.3309 19.2299 32.9064C18.5922 32.6689 17.9697 32.3869 17.3511 32.1055C17.615 32.3443 17.8739 32.589 18.1179 32.8506C19.2207 34.0336 19.8735 35.571 20.4489 37.0595C21.5936 40.0204 22.6584 42.961 24.0599 45.8175C25.4367 48.624 26.977 51.3655 28.6039 54.0345C29.7946 55.9877 31.1567 57.9052 32.9177 59.3823V59.3823Z" fill="#A9CDDD" />
    <path d="M19.7276 30.6556C20.2116 31.7909 20.3037 31.8423 20.6001 30.5681C20.7982 29.7171 21.0359 28.8754 21.3098 28.0455C20.7349 28.4976 20.0696 28.8006 19.5306 28.0712C19.3225 27.7895 19.1898 27.4483 19.0464 27.1313C18.208 25.2794 17.6612 23.3894 17.3091 21.4131C17.3378 21.7374 17.3715 22.0614 17.414 22.3845C17.7882 25.22 18.6013 28.0145 19.7276 30.6556V30.6556Z" fill="#A9CDDD" />
    <path d="M47.017 29.3903C45.863 28.7528 44.7599 28.0156 43.628 27.3392C41.6484 26.1564 39.6185 24.8757 37.4478 24.042C41.4664 26.3761 45.2956 29.0346 48.894 31.9754C49.8862 32.7862 49.9142 32.4329 49.7457 31.5982C49.7169 31.4552 49.6869 31.3122 49.6557 31.1694C49.5568 30.6967 49.4392 30.2295 49.3126 29.7648C48.6524 30.1256 47.8375 29.8437 47.017 29.3903V29.3903Z" fill="#A9CDDD" />
    <path d="M25.4465 22.4608C32.2982 22.6553 39.1924 22.583 46.0538 22.4672C47.253 22.4468 47.1851 22.4649 48.6178 21.8691C51.2532 20.7732 55.5765 18.4979 57.1118 16.914C57.6497 16.3591 57.6559 15.9832 57.1007 15.5333C56.8958 15.3674 56.6707 15.2228 56.4402 15.085C56.6594 15.6659 56.6276 16.2528 56.0197 16.7765C54.8573 17.7781 53.4211 18.5455 52.0953 19.3018C50.7037 20.0954 49.2467 20.9064 47.7178 21.4037C46.3602 21.8453 44.8459 21.8457 43.4319 21.9116C37.5257 22.1868 31.597 21.9456 25.6896 21.8232C22.8045 21.7633 20.1725 20.2367 17.7261 18.8487C16.7532 18.2966 15.6709 17.7432 14.8411 16.982C14.2627 16.4512 14.1841 15.8319 14.3512 15.2131C14.3082 15.2425 14.2636 15.2697 14.2213 15.3C13.3681 15.9073 13.4046 16.4679 14.003 17.0475C15.7269 18.7181 20.1499 20.9584 22.6281 21.9714C24.1477 22.5923 23.5551 22.4075 25.4465 22.4608V22.4608Z" fill="#A9CDDD" />
    <path d="M35.0466 31.453C35.0628 30.2332 35.0772 29.013 35.0816 27.793C34.7017 28.948 34.1249 30.0443 33.3408 30.8612C31.9843 32.2744 29.8937 32.1837 28.0878 32.2282C27.2457 32.2489 23.7945 32.42 22.8483 31.464C22.6823 31.5956 22.5143 31.7245 22.3493 31.8572C21.3121 32.692 21.89 32.6798 22.7155 32.6958C23.6575 32.7143 24.5999 32.7289 25.5421 32.7408C28.3382 32.776 31.1346 32.7896 33.9307 32.793C34.8744 32.7943 35.0323 32.5407 35.0466 31.453V31.453Z" fill="#A9CDDD" />
    <path d="M56.9829 27.6597C56.7981 28.8955 55.5837 29.6826 54.5834 30.2338C53.7544 30.6904 52.7698 31.1545 51.8156 31.0264C51.7481 31.1821 51.6844 31.3398 51.6149 31.4947C51.2535 32.3 51.4145 32.3296 52.3976 31.9133C52.791 31.7464 53.1805 31.5705 53.5674 31.393C54.9061 30.7792 56.2328 30.123 57.5308 29.4155C58.5031 28.8859 58.3865 28.8449 58.1563 28.1736C57.6972 26.8358 57.0684 25.5548 56.4842 24.2692C56.4387 24.1729 56.3919 24.076 56.3462 23.9795C56.3587 24.0231 56.3729 24.0661 56.3852 24.1098C56.7093 25.2695 57.1659 26.4347 56.9829 27.6597Z" fill="#A9CDDD" />
    <path d="M35.3136 59.8924C35.3961 61.3686 35.1807 61.7819 36.6653 59.9571C37.7425 58.6329 38.7275 57.2373 39.6482 55.7968C39.2797 56.1972 38.9103 56.5962 38.5414 56.9906C38.0719 57.4924 37.5289 58.0958 36.8623 58.3282C36.1493 58.5769 36.0276 57.5242 35.9733 57.0702C35.851 56.0484 35.8991 54.9914 35.8988 53.9632C35.898 48.7812 36.0061 43.585 35.6707 38.4103C35.609 37.4569 35.5276 36.5017 35.3944 35.5547C35.2839 42.1764 35.1148 48.7981 35.1829 55.4209C35.2014 56.9106 35.2301 58.4037 35.3136 59.8924Z" fill="#A9CDDD" />
    <path d="M46.6079 51.5426C45.6435 52.7448 44.6324 53.8865 43.5323 54.9665C43.0616 55.4288 42.5758 55.8724 42.0848 56.3096C41.2956 57.1959 40.4813 58.0601 39.6354 58.8949C39.3682 59.1532 39.0966 59.4082 38.82 59.6583C37.5041 60.8474 37.4672 61.0173 38.8815 60.0182C39.6225 59.4946 40.3033 58.8718 40.9277 58.2848C43.2247 56.0775 45.3495 53.6933 47.4321 51.2848C52.4898 45.3213 56.6168 38.6621 60.3268 31.791C58.96 34.0384 57.4873 36.2284 56.0447 38.397C53.0604 42.883 49.9808 47.3378 46.6079 51.5426Z" fill="#A9CDDD" />
    <path d="M59.4811 20.7757C58.8006 20.4121 58.1031 20.0834 57.414 19.7368C56.543 19.2986 56.6331 18.6328 56.969 17.9707C56.5073 18.3389 56.003 18.6638 55.5166 18.9877C54.7672 19.4854 55.03 19.5538 55.9636 19.8789C56.1772 19.9534 56.3897 20.0306 56.6014 20.1106C58.8114 20.9449 60.9934 21.9656 63.0188 23.2099C63.0853 23.2507 63.148 23.289 63.208 23.3251C62.2256 22.2028 60.653 21.402 59.4811 20.7757V20.7757Z" fill="#A9CDDD" />
    <path d="M8.66172 26.4082C9.53005 27.0721 10.4537 27.6652 11.3924 28.2264C11.9563 28.5632 12.4115 28.6887 12.7718 27.7426C12.8957 27.4171 13.0298 27.0952 13.1628 26.7756C13.5349 25.8805 13.933 24.9961 14.3463 24.1192C14.8265 23.1008 15.3129 22.0658 15.8589 21.0687C16.0702 20.6827 16.1963 20.4043 16.1623 20.2588C16.122 20.3039 16.0839 20.3491 16.0496 20.3945C15.6397 20.9361 15.281 21.5173 14.93 22.0979C14.2376 23.2431 13.6126 24.4289 12.9936 25.6149C12.7636 26.0555 12.5699 26.5235 12.3058 26.9451C11.9777 27.4686 11.2384 27.1606 10.7666 27.0435C9.68969 26.7761 7.69281 25.6367 7.36497 24.3298C7.07694 24.6671 7.12339 25.0368 7.59755 25.5199C7.92492 25.8535 8.30915 26.1398 8.66172 26.4082V26.4082Z" fill="#A9CDDD" />
    <path d="M48.1391 13.2054C47.3142 12.8628 46.4845 12.5065 45.644 12.1878C43.7194 11.4577 43.2953 11.4734 41.1874 11.3937C38.1516 11.2782 30.2549 11.1436 29.122 11.2831C28.4358 11.3676 27.7514 11.4858 27.0685 11.6487C26.7613 11.722 25.0349 12.2619 24.0484 12.5887C20.2966 13.8342 20.6137 14.7932 23.6974 16.2847C23.9846 16.4237 24.2985 16.5703 24.6208 16.7121C26.4692 17.5248 27.0557 17.5331 29.7097 17.6015C32.8835 17.683 38.1316 17.7771 41.289 17.7475C43.2923 17.7285 43.9902 17.6706 45.6318 17.0469C46.5474 16.6989 47.5911 16.2516 48.4165 15.8277C50.5757 14.7188 50.0675 14.0061 48.1391 13.2054V13.2054Z" fill="#E9FFFB" />
    <path d="M13.4613 21.6021C12.285 22.1221 11.1483 22.7459 10.0518 23.408C9.73133 23.6016 8.36919 24.1993 8.73318 24.7784C9.05032 25.2829 9.56075 25.4923 10.0743 25.7503C10.5092 25.9689 10.9198 26.0847 11.3226 25.7689C11.952 25.2753 12.3451 24.2834 12.7765 23.6276C13.1328 23.0856 13.5093 22.5579 13.8915 22.0342C14.4496 21.2693 14.186 21.2817 13.4613 21.6021V21.6021Z" fill="#E9FFFB" />
    <path d="M21.9367 23.4941C21.3036 23.059 20.5717 22.7091 19.8824 22.3454C18.4455 21.5895 18.5262 22.0995 18.7121 23.145C18.8086 23.6893 18.9786 24.2189 19.1617 24.7448C19.3769 25.3629 19.5755 26.0572 20.0461 26.5327C20.7913 27.2854 21.7605 24.7312 21.9367 24.3975C22.0769 24.1323 22.2578 23.7148 21.9367 23.4941V23.4941Z" fill="#E9FFFB" />
    <path d="M32.3416 26.0102C31.1797 26.6654 30.0135 27.3192 28.8866 28.0294C28.2644 28.4213 27.6591 28.8548 27.081 29.3093C25.9814 30.1736 25.9727 30.7506 27.2037 30.8776C28.0102 30.9607 28.9934 30.9048 29.1525 30.8993C29.9774 30.871 30.834 30.7968 31.6224 30.5387C32.253 30.3324 32.4566 29.9265 32.6984 29.3297C33.0288 28.5151 33.3592 27.5814 33.5194 26.6904C33.6933 25.7194 33.3003 25.4705 32.3416 26.0102V26.0102Z" fill="#E9FFFB" />
    <path d="M47.4706 27.13C47.3191 26.6168 47.122 26.1001 46.9111 25.6255C46.7039 25.1591 46.3997 24.4403 45.8643 24.2593C45.0733 23.9919 44.1611 23.9335 43.3321 23.9072C42.8375 23.8914 42.3312 23.8878 41.8289 23.9209C40.9564 23.9787 40.7944 24.2881 41.5761 24.7041C42.9308 25.4247 45.0763 26.6839 46.6179 27.8475C47.1647 28.2605 47.8529 28.4226 47.4706 27.13V27.13Z" fill="#E9FFFB" />
    <path d="M55.4319 24.5547C55.0472 23.7442 54.9462 23.9781 54.787 24.6763C54.401 26.3668 53.8203 27.831 53.3059 29.2473C53.1259 29.7423 53.0875 30.185 53.8178 29.9127C54.1369 29.7937 55.0993 29.1027 55.7571 28.4886C56.4196 27.8696 56.5639 27.649 56.2756 26.6608C56.0838 26.0032 55.7391 25.2022 55.4319 24.5547V24.5547Z" fill="#E9FFFB" />
    <path d="M60.311 19.2316C59.8505 18.732 59.0246 17.7157 58.2424 17.7911C57.9885 17.8156 57.6796 18.1712 57.6678 18.4219C57.6432 18.9384 58.0478 19.1233 58.4485 19.3606C59.3613 19.9012 60.3155 20.3621 61.2347 20.8886C61.9205 21.2816 62.0463 21.189 61.5271 20.5824C61.1306 20.1191 60.7115 19.6663 60.311 19.2316V19.2316Z" fill="#E9FFFB" />
    <path d="M45.5844 34.8968C44.4183 34.85 43.2531 34.7856 42.0861 34.7739C40.3968 34.7568 39.8246 35.2592 39.769 37.1411C39.6948 39.6542 39.6488 44.3499 39.4048 48.9998C39.2044 52.7982 40.5999 52.3617 42.0542 49.2967C43.3991 46.4629 45.9683 40.4486 47.2136 37.1835C48.0681 34.9432 47.3944 34.9693 45.5844 34.8968V34.8968Z" fill="#E9FFFB" />
    <path d="M23.7854 47.0934C22.5221 44.5653 21.2185 42.0576 19.9426 39.536C19.1442 37.9582 18.4176 36.3381 17.5898 34.7763C17.2349 34.1065 16.7862 33.4863 16.1915 33.0592C15.7934 32.7738 14.6691 32.062 15.8213 34.27C16.8785 36.2965 19.1924 40.256 23.5699 47.3944C24.5371 48.972 24.6547 48.8331 23.7854 47.0934V47.0934Z" fill="#E9FFFB" />
  </svg>

);