import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { ICapitalMion, ICapitalMionLog } from '../../../../types/models/CapitalMion'
import { NET } from '../../../network'

// Define a service using a base URL and expected endpoints
export const capitalMionApi = createApi({
  reducerPath: 'capitalMionApi',
  baseQuery: fetchBaseQuery({ baseUrl: NET.BACK_URL }),
  endpoints: (builder) => ({
    getCapitalMion: builder.query<{
      capitals: ICapitalMion[],
    }, string>({
      query: () => `capital-mion`,
    }),
    getCapitalMionLogs: builder.query<{
      data: ICapitalMionLog[],
    }, string>({
      query: () => `capital-mion-logs`,
    }),
  }),
})

export const {
  useGetCapitalMionQuery,
  useGetCapitalMionLogsQuery,
} = capitalMionApi
