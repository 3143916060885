import React, { FC, ReactNode } from 'react';
import cx from 'classnames';
import './../ui.scss'

interface IButtonProps {
  children: ReactNode;
  type?: string,
  icon?: string | boolean,
  onClick: () => void;
  disabled?: boolean;
};

const Button: FC<IButtonProps> = ({
  type = 'primary',
  icon = false,
  children,
  onClick,
  disabled = false,
}) => {
  return (
    <button
      onClick={onClick}
      className={cx(
        "button",
        type === 'primary' && "button__primary"
      )}
      disabled={disabled}
    >
      {icon && <div>+</div>}
      <div>{children}</div>
    </button>
  )
};

export default Button;