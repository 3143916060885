import { ICurrency, IEventCurrency } from "../../../types/models/Currency";
import { NET } from "../../network"
// import { IParams } from "../slices/UserSlice";
import { IParams } from './../slices/CoreSlice';
import { ICurrencyPair } from './../../../types/models/Currency';

export const actionFetchCurrencies = async () => {
  const res = await fetch(`${NET.BACK_URL}/currencies`);

  const data = await res.json();

  return data.data as ICurrency[];
}

export const actionFetchCurrencyPairs = async () => {
  const res = await fetch(`${NET.BACK_URL}/currency-pairs`);

  const data = await res.json();

  return data.data as ICurrencyPair[];
}

export const actionFetchCurrency = async ({
  currency_pair_id,
}:IParams['fetch']) => {
  const res = await fetch(`${NET.BACK_URL}/currencies/${currency_pair_id}`);

  const data = await res.json();

  return data.data as IEventCurrency[];
}