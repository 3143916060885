import React, { FC } from 'react';
import { exitUser, selectToken, selectUser, viewModalLogin, viewModalRegister } from '../../app/redux/slices/UserSlice';
import { useAppDispatch, useAppSelector } from './../../app/hooks';
import { selectLang } from './../../app/redux/slices/CoreSlice';
import { Lang, useLang } from './../../app/lang/useLang';
import { clearOrders } from '../../app/redux/slices/OrderSlice';
import { useNavigate } from 'react-router-dom';

interface IProfileModalProps {
  setOpenModal: any;
};

const ProfileModal: FC<IProfileModalProps> = ({
  setOpenModal
}) => {
  const lang = useAppSelector(selectLang);
  const user = useAppSelector(selectUser);
  const token = useAppSelector(selectToken);
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const openRegisterModal = () => {
    setOpenModal(false);
    navigate('/auth/registration');
  };
  const openLoginModal = () => {
    setOpenModal(false);
    dispatch(viewModalLogin(true));
  };
  const exitFunc = () => {
    dispatch(exitUser());
    dispatch(clearOrders());
    localStorage.removeItem('user_id');
    localStorage.removeItem('token');
    setOpenModal(false);
  };

  const goToProfile = () => {
    navigate('/profile');
  };

  return (
    <div className="profile-modal">
      <div
        className="profile-modal__cross"
        onClick={() => setOpenModal(false)}
      >
        x
      </div>
      <div className="profile-modal__title">{useLang("profile", lang)}</div>
      {user && token && (
        <div>
          <div>
            <div className="text-center mb-2"><Lang
                keyWord="hello"
                lang={lang}
              />, {user.name}!</div>
            <button
              className="button-main"
              onClick={goToProfile}
            >
              <Lang
                keyWord="go-to-profile"
                lang={lang}
              />
            </button>
            <div
              className="profile-modal__register"
              onClick={exitFunc}
            >
              <Lang
                keyWord="exit"
                lang={lang}
              />
            </div>
          </div>
        </div>
      )}
      {!user && !token && (
        <div>
          <div>
            <button
              className="button-main"
              onClick={openLoginModal}
            >
              <Lang
                keyWord="sign-in"
                lang={lang}
              />
            </button>
          </div>
          <div
            className="profile-modal__register"
            onClick={openRegisterModal}
          >
            <Lang
              keyWord="sign-up"
              lang={lang}
            />
          </div>
        </div>
      )}
    </div>
  )
};

export default ProfileModal;