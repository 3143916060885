/* eslint-disable react-hooks/rules-of-hooks */
import React, { FC, useRef } from 'react';
import { useEffect } from 'react';
import { useGetCapitalMionQuery } from '../../app/redux/api/capitalMion';
import Loading from '../ui/Loading/Loading';
import { totalCapitalCalc } from './../../pages/capital/CapitalPage';
import { selectLang } from './../../app/redux/slices/CoreSlice';
import { useAppSelector } from '../../app/hooks';
import { useLang } from './../../app/lang/useLang';

interface IDayCapitalProps {

};

export const COLORS = ['#04D382', '#FE912C', '#FFE24A', '#63ECFF', '#0047FF'];

const DayCapital:FC<IDayCapitalProps> = ({}) => {
  const { data, isSuccess } = useGetCapitalMionQuery('capitals');
	const lang = useAppSelector(selectLang);
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    if (canvasRef.current) {
      const canvas = canvasRef.current;
      const widthWindow = window.innerWidth;

      let widthCanvas = 270;
      let heightCanvas = 270;
      let radius = 100;
      let centerXY = 135;
      let lineWidth = 50

      // if (widthWindow > 1100 && widthWindow < 1300) {
      //   widthCanvas = 600;
      // } else if (widthWindow > 576 && widthWindow <= 1100) {
      //   widthCanvas = 500;
      // } else if (widthWindow < 576) {
      //   widthCanvas = widthWindow - 49
      // }
      if (widthWindow < 576) {
        widthCanvas = 150;
        heightCanvas = 150;
        radius = 45;
        centerXY = 75;
        lineWidth = 30
      }
      canvas.width = widthCanvas;
      canvas.height = heightCanvas;
      const ctx = canvas.getContext('2d');
      if (ctx && data) {
        const total = totalCapitalCalc(data);
        const transformData = data.capitals.filter(item => item.value > 0).map((item, idx) => ({
          value: item.value * item.price,
          color: COLORS[idx] || COLORS[idx - COLORS.length],
        }));
        let oldPoint = 0;
        transformData.forEach((capital) => {
          ctx.beginPath();
          // ctx.lineCap = "round"
          ctx.strokeStyle = capital.color
          ctx.lineWidth = lineWidth;
          const percent = capital.value / total * 100;
          let radianValue = 2 * Math.PI * percent / 100;
          ctx.arc(centerXY, centerXY, radius, oldPoint, radianValue + oldPoint);
          oldPoint = radianValue + oldPoint;
          ctx.stroke();
        })
      }
    }
  }, [isSuccess]);

  if (!isSuccess) return <Loading />

  return (
    <div>
      <canvas ref={canvasRef} id="canvas">
				{useLang("browser-does-not", lang)}
      </canvas>
    </div>
  )
};

export default DayCapital;
