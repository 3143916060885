import React, { FC, useMemo } from 'react';
import { faHome, faList, faCar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NavLink, useNavigate } from "react-router-dom";
import styles from './LeftMenu.module.scss';

interface ILeftMenuProps {
};

const LeftMenu:FC<ILeftMenuProps> = ({}) => {
  let navigate = useNavigate();

  const StructureApp = {
    routes: [
      {
        title: 'Exchange',
        route: '../../',
        icon: 'home',
        view: true,
      },
      {
        title: 'Capital Logs',
        route: 'capital-logs',
        icon: 'list',
        view: true,
      },
      {
        title: 'Capital Mion',
        route: 'capital-mion',
        icon: 'list',
        view: true,
      },
      {
        title: 'Event Currencies',
        route: 'event-currencies',
        icon: 'list',
        view: true,
      },
      {
        title: 'Orders',
        route: 'orders',
        icon: 'list',
        view: true,
      },
      {
        title: 'Users',
        route: 'users',
        icon: 'list',
        view: true,
      },
    ]
  }

  const LeftmenuData = useMemo(() => StructureApp.routes.map((elem) => ({
    title: elem.title,
    route: `/${elem.route}`,
    icon: elem.icon,
    view: elem.view,
  })), []);

  const libIcons:any = {
    'home': faHome,
    'list': faList,
    'cars': faCar,
  }
  return (
    <div className={styles.navigateLeft}>
      {LeftmenuData.filter(item => item.view).map((item, idx) => (
        <NavLink reloadDocument key={`nav${idx}`} to={'..'+item.route}  className={ ({ isActive }) => isActive ? styles.navigateLeft__item_active + ' ' + styles.navigateLeft__item : styles.navigateLeft__item
      }>
            <div className={styles.navigateLeft__item__img}>
              <FontAwesomeIcon
                // icon={faHome}
                icon={libIcons[item.icon]}
              />
            </div>
            <div className={styles.navigateLeft__item__text}>{item.title}</div>
            {/* <div className={styles.navigateLeft__item__text} onClick={() => {navigate('../'+item.title)}}>{item.title}</div> */}
        </NavLink>
      ))}
    </div>
  );
};

export default LeftMenu;