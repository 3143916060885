import React, { FC } from 'react';
import classes from './Loading.module.scss';

interface ILoadingProps {
  
};

const Loading:FC<ILoadingProps> = ({}) => {
  return (
    <div className="loader"></div>
  );
};

export default Loading;