/* eslint-disable react-hooks/rules-of-hooks */
import React, { FC, useState } from 'react';
import cx from 'classnames';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { GLOBAL_VAR } from '../../../app/network';
import { updateOrder } from '../../../app/redux/slices/OrderSlice';
import UIButton from '../../../widgets/ui/user-interface/Button/UIButton';
import { copyTextFunc } from './../../../widgets/helpers';
import { selectToken, selectUser } from '../../../app/redux/slices/UserSlice';
import { selectLang } from './../../../app/redux/slices/CoreSlice';
import { useLang } from './../../../app/lang/useLang';
import { PAY_METHODS } from './../../../app/network';

interface IConfirmPayModalProps {
  type: 'buy' | 'sell';
  value: number;
  fixed_price: number;
  orderId: number;
  userId: number;
  currency_pair_id: number;
  modalConfirmPayShow: (status: boolean) => void;
  pair: {
    firstCurrency: {
      symbol: string;
    };
    secondCurrency: {
      symbol: string;
    };
  };
  setFastOrder?: any;
};

const ConfirmPayModal: FC<IConfirmPayModalProps> = ({
  type,
  orderId,
  value,
  fixed_price,
  userId,
  currency_pair_id,
  modalConfirmPayShow,
  pair,
  setFastOrder,
}) => {
  const lang = useAppSelector(selectLang);
  const user = useAppSelector(selectUser);
  const token = useAppSelector(selectToken);
  const [loading, setLoading] = useState(false);
  const [validate, setValidate] = useState(false);
  const [formData, setFormData] = useState({
    link: '',
  });

  console.log(currency_pair_id)
  const payData:any = PAY_METHODS.find((method) => method.id === currency_pair_id);

  const dispatch = useAppDispatch();

  const sendFunc = async () => {
    if (formData.link) {
      setLoading(true)
      await dispatch(updateOrder({
        token,
        link: formData.link,
        status: "in progress",
        orderId,
        user_id: userId
      }))
      setLoading(false)
      setValidate(false)
      modalConfirmPayShow(false)
      if (setFastOrder) {
        setFastOrder(false)
      }
    } else {
      setValidate(true)
    }
  };

  console.log(payData)

  return (
    <div className="register-modal confirm-modal">
      {type === 'buy' && (
        <div>
          <div className="mb-2 white text-center">{useLang("you-need-to-pay", lang)}</div>
          <ol>
            <li className="mb-2">{useLang("use-these-wallets", lang)} <span className="confirm-modal_needToPay">{value * fixed_price} {pair.secondCurrency.symbol}</span></li>
            <ul className="confirm-modal__lisp">
              {payData['buy'].map((payMethod: {
                title: string;
                value: string;
              }, idx: number) => (
                <li key={`buyMethod${idx}`} className="ml-3">
                  <div className="confirm-modal__lisp-title">{payMethod.title}</div>
                  <div
                    onClick={() => copyTextFunc(payMethod.value)}
                    className="confirm-modal__link"
                  >{useLang("click-to-copy", lang)} {payMethod.value}</div>
                </li>
              ))}
            </ul>
            <li className="mb-2">
              <ul>
                <div className="mb-2">{useLang("confirming-after-payment", lang)}</div>
                <li className="ml-3">
                  <div className="confirm-modal__lisp-title">Metamask</div>
                  <div>{useLang("you-need-to-pay", lang)}</div>
                </li>
                <li className="ml-3">
                  <div className="confirm-modal__lisp-title">Binance</div>
                  <div>{useLang("paste-your-nickname", lang)}</div>
                </li>
                <li className="ml-3">
                  <div className="confirm-modal__lisp-title">Monobank</div>
                  <div>Paste your name</div>
                </li>
              </ul>
            </li>
          </ol>
          <div className="mb-2">
            <input
              className={cx(
                "input-main",
                validate && "input-main_error",
              )}
              placeholder={useLang("paste-link", lang)}
              onChange={(e) => {
                setValidate(false)
                setFormData({
                  ...formData,
                  link: e.target.value,
                })
              }}
            />
          </div>
          <div className="mb-2">
            {useLang("click-confirm", lang)} <span className="yellow">{useLang("in-processing", lang)}</span>
          </div>
          <div className="mb-2">
            <UIButton
              onClick={sendFunc}
              loading={loading}
            >
              {useLang("confirm", lang)}
            </UIButton>
          </div>
          <div className="mb-2">
            {useLang("your-order-will-processing", lang)}
          </div>
          <div className="mb-2">
            {useLang("you-will-receive", lang)} <span className="confirm-modal_needToPay">{value} {pair.firstCurrency.symbol}</span> {useLang("on-your-wallet", lang)}
            <div>ID: {user?.metamask_id}</div>
          </div>
        </div>
      )}

      {type === 'sell' && (
        <div>
          <div className="mb-2 white text-center">{useLang("you-need-to-pay", lang)}</div>
          <ol>
            <li className="mb-2">{useLang("use-these-wallets", lang)} <span className="confirm-modal_needToPay">{value} {pair.firstCurrency.symbol}</span></li>
            <ul className="confirm-modal__lisp">
              <li className="ml-3">
                <div className="confirm-modal__lisp-title">Metamask</div>
                <div
                  onClick={() => copyTextFunc(GLOBAL_VAR.pay_metamask)}
                  className="confirm-modal__link"
                >{useLang("click-to-copy", lang)} {GLOBAL_VAR.pay_metamask}</div>
              </li>
            </ul>
            <li className="mb-2">
              <ul>
                <div className="mb-2">{useLang("confirming-after-payment", lang)}</div>
                <li className="ml-3">
                  <div className="confirm-modal__lisp-title">Metamask</div>
                  <div>{useLang("you-get-a-link", lang)}</div>
                </li>
              </ul>
            </li>
          </ol>
          <div className="mb-2">
            <input
              className={cx(
                "input-main",
                validate && "input-main_error",
              )}
              placeholder={useLang("paste-link", lang)}
              onChange={(e) => {
                setValidate(false)
                setFormData({
                  ...formData,
                  link: e.target.value,
                })
              }}
            />
          </div>
          <div className="mb-2">
            {useLang("click-confirm", lang)}<span className="yellow"> {useLang("in-processing", lang)}</span>
          </div>
          <div className="mb-2">
            <UIButton
              onClick={sendFunc}
              loading={loading}
            >
              {useLang("confirm", lang)}
            </UIButton>
          </div>
          <div className="mb-2">
            {useLang("your-order-will-processing", lang)}
          </div>
          <div className="mb-2">
            {useLang("you-will-receive", lang)} <span className="confirm-modal_needToPay">{value * fixed_price} {pair.secondCurrency.symbol}</span> {useLang("on-your-wallet", lang)}
            <div>ID: {user?.metamask_id}</div>
          </div>
        </div>
      )}
    </div>
  )
};

export default ConfirmPayModal;