import React, { FC } from "react";
import {
  faEdit,
  faEye,
  faQrcode,
  faTrash,
  faUndo,
  faFilter,
  faCubes,
  faArrowRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from 'react-router-dom';

type TCustomButton = {
  icon: "filter" | "update" | "trash" | "arrow-right";
  path: string;
  type: "link";
}

interface IButtons {
  idRow: number; 
  value: any;
  gridHandlers: any;
  styles: any; 
  elem: any;
  width: any;
  customButtons?: TCustomButton[];
}

const Buttons:FC<IButtons> = ({ 
  idRow, 
  value, 
  gridHandlers, 
  styles, 
  elem, 
  width, 
  customButtons = [],
}) => {
  const navigate = useNavigate();
  const buttons = Object.keys(gridHandlers || {});
  const renderButtons = [...buttons, ...customButtons];
  const libIcon:any = {
    "update": faEdit,
    "qr": faQrcode,
    "delete": faTrash,
    "return": faUndo,
    "show": faEye,
    "filter": faFilter,
    "cubes": faCubes,
    "arrow-right": faArrowRight,
  }
  return (
    <div
      className={styles.buttonWrapper}
      style={{
        width,
        minWidth: width,
      }}
    >
      {renderButtons.map((button: any) => (
        <div
          className={styles.buttonWrapperItem}
          onClick={
            button?.type === 'link' ? 
            () => navigate(`${button.path}/${idRow}`) :
            () => gridHandlers[button](idRow, elem)
          }
        >
          <FontAwesomeIcon
            icon={
              button?.type === 'link' ?
              libIcon[button.icon] :
              libIcon[button]
            }
          />
        </div>
      ))}
    </div>
  );
};
export default Buttons;

