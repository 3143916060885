/* eslint-disable react-hooks/rules-of-hooks */
import React, { FC, useState, ReactNode, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { useCheckAdminTokenMutation, useLoginAdminMutation } from '../app/redux/api/coreApi';
import { selectCheckKey, setCheckKey } from '../app/redux/slices/CoreSlice';
import LeftMenu from './components/LeftMenu/LeftMenu';
import { selectLang } from './../app/redux/slices/CoreSlice';
import { useLang } from './../app/lang/useLang';

interface IAdminLayoutProps {
  children: ReactNode;
};

const AdminLayout:FC<IAdminLayoutProps> = ({
  children,
}) => {
	const lang = useAppSelector(selectLang);
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState('');
  const dispatch = useAppDispatch();

  const [checkAdminToken] = useCheckAdminTokenMutation();
  const [loginAdmin] = useLoginAdminMutation();

  useEffect(() => {
    const token = localStorage.getItem('tokenAdmin');
    (async () => {
      const res:any = await checkAdminToken({
        token: token || 'noToken',
      });
      if (!res.error) {
        dispatch(setCheckKey(res.data.checkKey))
        setLoading(true);
      }
    })()
  }, []);
  const enterToAdmin = async () => {
    const res:any = await loginAdmin({
      password,
    });
    if (!res.error) {
      localStorage.setItem('tokenAdmin', res.data.token);
    }
  };

  return (
    <div className="d-flex bg-white">
      <div>
        <LeftMenu />
      </div>
      <div>
        {loading && children}
        {!loading && (
          <div className="d-flex">
            <input
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
            <button onClick={enterToAdmin} className="button-main">{useLang("enter", lang)}</button>
          </div>
        )}
      </div>
    </div>
  )
};

export default AdminLayout;
