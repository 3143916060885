import React, { FC, useEffect } from 'react';
import cx from 'classnames';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { fetchCurrencyPairs, selectCurrencyPairs, selectLang, setActivePair } from './../../../app/redux/slices/CoreSlice';
import { useLang } from './../../../app/lang/useLang';
import { useSearchParams, useNavigate } from 'react-router-dom';

interface ILispCurrencyProps {

};

const LispCurrency:FC<ILispCurrencyProps> = ({}) => {
	const lang = useAppSelector(selectLang);
  const currencyPairs = useAppSelector(selectCurrencyPairs);
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate()

  useEffect(() => {
    dispatch(fetchCurrencyPairs())
  }, []);

  if (currencyPairs?.length) {
    dispatch(setActivePair(Number(searchParams.get('pair'))));
  }
  return (
    <div className="currency-lisp">
      <div className="currency-lisp__title">{useLang("currency-pairs", lang)}</div>
      <div>
        {currencyPairs && currencyPairs.map((item) => (
          <div
            key={item.id}
            className={cx(
              "currency-lisp__item",
              item.id !== Number(searchParams.get('pair')) ? 'grey-light' : ''
            )}
            onClick={() => {
              navigate(`/?pair=${item.id}`);
            }}
          >
            <div>{item.firstCurrency.symbol + "/" + item.secondCurrency.symbol}</div>
            <div className="currency-lisp__item-price">{item.value}</div>
          </div>
        ))}
      </div>
    </div>
  )
};

export default LispCurrency;