import React, { useState, FC } from "react";
import classes from "./editor.module.scss";
import EditorSwitcher from "./EditorSwitcher";
import { useNavigate, useParams } from 'react-router-dom';

interface IEditor {
  route: any;
  fields: any;
  dataItem: any;
  setDataItem: any;
  afterSuccess: any;
  net: any;
  url: any;
  urlId: any;
  modalConfig: any;
}

const Editor:FC<IEditor> = ({
  route,
  fields,
  dataItem,
  setDataItem,
  afterSuccess,
  net,
  url,
  urlId,
  modalConfig,
}) => {
  // Data for new news
  const [errorData, setDataError] = useState({});
  const [systemInfo, setSystemInfo] = useState("");
  const params = useParams();
  const create = async () => {
    // setPreload(1)
    let fetchUrl = url + '/' + route;
    if (urlId){
      fetchUrl = url + '/' + route.substring(0, route.length - 4)
    }
    try {
      //Блок формирования отправки данных картинки и текста
      let formData = new FormData();
      //Перебор объекта отправки - добавление ключей и значений
      for (let key in dataItem) {
        if (dataItem[key] !== null && key !== 'id') {
          formData.append(key, dataItem[key]);
        }
      }
      if (urlId){
        formData.append('active_key', String(params.id))
      }
      setSystemInfo("Началась загрузка");
      const response = await fetch(fetchUrl, {
        method: "POST",
        headers: {
          //Заголовки не отправляем вообще никакие)
          "X-Requested-With":"XMLHttpRequest"
          // 'Accept': 'application/json',
          // 'Content-Type': 'multipart/form-data',
          // 'Content-Type': false,
          // "processData": false
        },
        body: formData,
      });
      if (response.status === 200) {
        const result = await response.json();
        afterSuccess(result.data);
      } else if (response.status === 401 || response.status === 422) {
        const errors = await response.json();
        setSystemInfo("Ошибка валидации");
        setDataError(errors);
      }
    } catch (e) {
      setSystemInfo(
        "Похоже произошла ошибка. Попробуйте еще раз или обратитесь к администратору сайта."
      );
    }
  };

  const update = async () => {
    try {
      //Блок формирования отправки данных картинки и текста
      let formData = new FormData();
      //Перебор объекта отправки - добавление ключей и значений
      for (let key in dataItem) {
        if (dataItem[key] !== null && key !== 'id') {
          formData.append(key, dataItem[key]);
        }
      }
      const updateUrl = `${url}/${route}/${dataItem.id}`;
      setSystemInfo("Началась загрузка");
      const response = await fetch(updateUrl, {
        method: "POST",
        headers: {
          "X-Requested-With":"XMLHttpRequest"

          //Заголовки не отправляем вообще никакие)
          // 'Accept': 'application/json',
          // 'Content-Type': 'multipart/form-data',
          // 'Content-Type': false,
          // "processData": false
        },
        body: formData,
      });
      if (response.status === 200) {
        const result = await response.json();
        afterSuccess(result.data);
      } else if (response.status === 401 || response.status === 422) {
        const errors = await response.json();
        setDataError(errors);
      }
    } catch (e) {
      setSystemInfo(
        "Похоже произошла ошибка. Попробуйте еще раз или обратитесь к администратору сайта."
      );
    }
    // }
  };

  return (
    <div>
      <div className={classes.editor}>
        {fields.map((itemEditor:any, idx:number) => (
          <EditorSwitcher
            key={`item${idx}`}
            itemEditor={itemEditor}
            classes={classes}
            dataItem={dataItem}
            setDataItem={setDataItem}
            errorData={errorData}
            net={net}
            url={url}
          />
        ))}

        {/* <div className={classes.editor__item}>
                  <div className={classes.editor__item__title}>
                      Краткое описание
                  </div>
                  <div className={classes.editor__item__input}>
                      <textarea rows="3" value={dataItem.subtitle} type="text" onChange={(e) => {
                          setDataItem({
                              ...dataItem,
                              subtitle: e.target.value
                          })
                      }} />
                  </div>
              </div> */}

      </div>
      <div className={classes.editor__wrapper__button}>
        {systemInfo && <div>{systemInfo}</div>}
        <div className={classes.editor__wr}>
          <button
            className={[classes.editor__button, classes.editor__button_big].join(
              " "
            )}
            onClick={modalConfig?.type === "create" ? create : update}
          >
            {modalConfig?.type === "create" ? modalConfig?.btnNameCreate : modalConfig?.btnNameUpdate}
          </button>
        </div>
      </div>
    </div>
  );
};
export default Editor;
