import { FC } from "react";
import { ISvg } from "../svgSprite";

export const CapitalSvg: FC<ISvg> = (config) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2 2V20C2 21.1046 2.89543 22 4 22H22" stroke-linejoin="round" />
    <rect x="15" y="3" width="5" height="16" stroke-linejoin="round" />
    <rect x="10" y="7" width="5" height="12" stroke-linejoin="round" />
    <rect x="5" y="12" width="5" height="7" stroke-linejoin="round" />
  </svg>
);
