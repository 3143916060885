import React, { FC } from 'react';
import AdminLayout from '../../../layouts/AdminLayout';
import TableCurrency from '../Main/components/TableCurrency/TableCurrency';

interface IAdminCurrencyPageProps {

};

const AdminCurrencyPage:FC<IAdminCurrencyPageProps> = ({}) => {
  return (
    <AdminLayout>
      <TableCurrency />
    </AdminLayout>
  )
};

export default AdminCurrencyPage;