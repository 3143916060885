import { FC } from "react";
import { ISvg } from "../svgSprite";

export const BinanceSvg: FC<ISvg> = (config) => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M5.51038 7.55194L7.52946 5.53564L7.96999 5.09573L8.99788 4.06925L12.4854 7.55194L14.5045 5.53564L8.99788 0L3.45459 5.53564L5.51038 7.55194Z"/>
		<path d="M8.97883 6.96733L6.9541 8.98926L8.97883 11.0112L11.0036 8.98926L8.97883 6.96733Z"/>
		<path d="M12.4854 10.4482L8.99788 13.9309L7.78644 12.7212L7.52946 12.5012L5.51038 10.4482L3.45459 12.5012L8.99788 18.0002L14.5045 12.5012L12.4854 10.4482Z"/>
		<path d="M2.02473 6.9893L0 9.01123L2.02472 11.0332L4.04945 9.01123L2.02473 6.9893Z"/>
		<path d="M15.9754 6.99125L13.9507 9.01318L15.9754 11.0351L18.0001 9.01318L15.9754 6.99125Z"/>
	</svg>
);
