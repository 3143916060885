export const ggUsers = () => {
  return {
    thead: [
      {
        title: 'ID',
        alias: 'id',
        type: 'text',
        width: 50,
      },
      {
        title: 'Email',
        alias: 'email',
        type: 'text',
        width: 200
      },
      {
        title: 'Name',
        alias: 'name',
        type: 'text',
        width: 150
      },
      {
        title: 'Wallet',
        alias: 'metamask_id',
        type: 'text',
        width: 250
      },
      {
        title: 'Date',
        alias: 'created_at',
        type: 'text',
        width: 180,
        transformerValue: (e:string):string => `${e.substring(0, 10)} ${e.substring(11, 19)}`
      },
    ],
  }
}