/* eslint-disable react-hooks/rules-of-hooks */
import React, { FC, useState, useEffect } from 'react';
import cx from 'classnames';
import { useAppSelector } from '../../../app/hooks';
import { fetchOrdersUser, selectOrdersUsers } from '../../../app/redux/slices/OrderSlice';
import OrderItem from './OrderItem';
import { useAppDispatch } from './../../../app/hooks';
import { selectToken, selectUser } from '../../../app/redux/slices/UserSlice';
import { useLang } from './../../../app/lang/useLang';
import { selectLang } from './../../../app/redux/slices/CoreSlice';

interface IOrdersProps {

};

type TFilter = 'open' | 'history';

const Orders:FC<IOrdersProps> = ({}) => {
	const lang = useAppSelector(selectLang);
  const user = useAppSelector(selectUser);
  const token = useAppSelector(selectToken);
  const orders = useAppSelector(selectOrdersUsers);
  const dispatch = useAppDispatch();
  const [filterOrder, setFilterOrder] = useState<TFilter>('open');

  useEffect(() => {
    if (user && token){
      dispatch(fetchOrdersUser({
        user_id: user.id,
        token,
      }))
    }
  }, [user, token])
  const filterFunc = (filter:TFilter) => {
    setFilterOrder(filter)
  }
  return (
    <div className="orders">
      <div className="orders__block">
        <div
          className={cx(
            "orders__item",
            filterOrder === 'open' && "orders__item_active"
          )}
          onClick={() =>filterFunc('open')}
        >{useLang("open-orders", lang)}
        <div className={cx(
            "orders__dot",
            filterOrder === 'open' && "orders__dot__active"
          )}></div>
        </div>
        <div
          className={cx(
            "orders__item",
            filterOrder === 'history' && "orders__item_active"
          )}
          onClick={() =>filterFunc('history')}
        >{useLang("history", lang)}
        <div className={cx(
            "orders__dot",
            filterOrder === 'history' && "orders__dot__active"
          )}></div>
        </div>
      </div>
      <div>
        <div className="orders-headers">
          <div className="orders-headers__item">ID</div>
          <div className="orders-headers__item">{useLang("date", lang)}</div>
          <div className="orders-headers__item">{useLang("pair", lang)}</div>
          <div className="orders-headers__item">{useLang("value", lang)}</div>
          <div className="orders-headers__item">{useLang("fixed-price", lang)}</div>
          <div className="orders-headers__item">{useLang("type", lang)}</div>
          <div className="orders-headers__item">{useLang("status", lang)}</div>
        </div>
        {orders ? (
          <div>
            {orders.filter(order => {
              if (filterOrder === 'open') {
                return order.status === 'open' || order.status === 'in progress'
              } else if (filterOrder === 'history') {
                return order.status === 'rejected' || order.status === 'confirmed' || order.status === 'cancel'
              }
            }).map((order) => (
              <OrderItem
                key={order.id}
                {...order}
              />
            ))}
          </div>
        ) : (
          <div>{useLang("no-orders", lang)}</div>
        )}
      </div>
    </div>
  )
};

export default Orders;