import React, { FC } from 'react';
import { NET } from '../../../app/network';
import AdminLayout from '../../../layouts/AdminLayout';
import GridView from '../../../widgets/GridView/GridView';
import { egEventCurrencies } from './../../../common/editorgenerator/egEventCurrencies';
import { ggOrders } from './../../../common/gridgenerator/ggOrders';
import { egOrders } from './../../../common/editorgenerator/egOrders';
import { useAppSelector } from '../../../app/hooks';
import { selectCheckKey } from '../../../app/redux/slices/CoreSlice';

interface IAdminOrdersPageProps {

};

const AdminOrdersPage: FC<IAdminOrdersPageProps> = ({ }) => {
  const token = useAppSelector(selectCheckKey);

  return (
    <AdminLayout>
      <GridView
        config={{
          title: "Orders",
          baseUrl: NET.BACK_URL,
          path: 'orders',
          width: 'flexible',
          pagination: true,
          filters: {
            on: true,
            name: 'status',
            defaultValue: 'open',
            staticData: true,
            options: [
              {
                id: "open",
                title: "open",
              },
              {
                id: "in progress",
                title: "in progress",
              },
              {
                id: "success",
                title: "success",
              },
              {
                id: "cancel",
                title: "cancel",
              },
            ]
          },
        }}
        generator={{
          grid: ggOrders,
          editor: egOrders,
        }}
        model={{
          id: 1,
          status: 'open',
        }}
        token={token}
      />
    </AdminLayout>
  )
};

export default AdminOrdersPage;
