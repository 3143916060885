/* eslint-disable react-hooks/rules-of-hooks */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons'

import React, { FC } from 'react';
import { selectLang } from './../../../app/redux/slices/CoreSlice';
import { useAppSelector } from '../../../app/hooks';
import { useLang } from './../../../app/lang/useLang';

interface IModalHeaderProps {
  title: string;
  closeModal: () => void
};

const ModalHeader: FC<IModalHeaderProps> = ({
  title,
  closeModal
	
}) => {
	const lang = useAppSelector(selectLang);
	let ID = title.split(' ');
  return (
    <div className="modal__header">
      <div className="modal__header__title">
				{title === "Login" ? useLang("login", lang) :
				title === "Registration" ? useLang("registration", lang) :
				`${useLang("payment-confirmation", lang) + ': ' + useLang("order", lang) + " " + ID[3]}`}
				</div>
      <div onClick={closeModal} className="modal__header__cross">
        <FontAwesomeIcon icon={faXmark} />
      </div>
    </div>
  )
};

export default ModalHeader;