import React, { FC, ReactNode } from 'react';
import cx from 'classnames'
import styles from './Button.module.scss';
import Svg from '../../../../assets/svg/svgSprite';

interface IUIButtonProps {
  children: ReactNode;
  loading: boolean;
  onClick: () => void;
  size?: 'l' | 'm' | 's';
  flexible?: boolean;
};

const UIButton: FC<IUIButtonProps> = ({
  children,
  loading,
  onClick,
  size = 'l',
  flexible,
}) => {
  return (
    <button
      disabled={loading}
      onClick={onClick}
      className={cx(
        "button-main",
        styles.button,
        size === 's' && styles.buttonSmall,
        size === 'm' && styles.buttonMedium,
        flexible && styles.buttonFlexible
      )}
    >
      {loading && <div className={styles.icon}>
        <Svg
          svgName="arrow-circle"
          width={15}
          height={15}
        />
      </div>}
      <div>{children}</div>
    </button>
  );
};

export default UIButton;
