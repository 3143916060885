import React, {
  useState, useEffect, FC
} from 'react'

interface IGridFilter {
  keyItem: string;
  filter: any;
  setFilter: any
  classes?: any
  compStyle?: any
  routeFilter?: string;
  url: string;
  staticData?: any;
  options?: any;
}

const GridFilter:FC <IGridFilter> = ({
  keyItem,
  filter,
  setFilter,
  classes,
  compStyle,
  routeFilter,
  url,
  staticData = false,
  options,
}) => {
  const [filterParams, setFilterParams] = useState<any>([])
  useEffect(() => {
    staticData ?
      setFilterParams(options) :
      (async () => {
        const res = await fetch(`${url}/${routeFilter}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
            // 'Content-Type': 'application/x-www-form-urlencoded',
          },
        })
        const data = await res.json();
        setFilterParams(data.data)
      })()
  }, [])
  return (
    <select className={[classes.gridFilter, compStyle?.gridFilter].join(' ')} value={filter[keyItem]} onChange={(e) => {
      setFilter({
        ...filter,
        [keyItem]: e.target.value
      })
    }}>
      {filterParams && filterParams.map((el:any, idx:any) => (
        <option value={el.id} key={`opti${idx}`}>{el.title}</option>
      ))}
    </select>
  )
}
export default GridFilter