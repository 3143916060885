/* eslint-disable react-hooks/rules-of-hooks */
import React, { FC } from 'react';
import { useGetCapitalMionQuery } from '../../app/redux/api/capitalMion';
import LineChart from '../../widgets/LineChart/LineChart';
import MainLayout from './../../layouts/MainLayout';
import DayCapital, { COLORS } from './../../widgets/CircleChart/DayCapital';
import { ICapitalMion } from '../../types/models/CapitalMion';
import { selectLang } from './../../app/redux/slices/CoreSlice';
import { useAppSelector } from '../../app/hooks';
import { useLang } from './../../app/lang/useLang';

interface ICapitalPageProps {

};

export const totalCapitalCalc = (data: {
  capitals: ICapitalMion[];
}) => {
  if (data) {
    const total =  data.capitals.reduce((acc, item) => {
      return acc + (item.price * item.value)
    }, 0);
    return total;
  }
  return 0;
}

const CapitalPage:FC<ICapitalPageProps> = ({}) => {
  const { data, isLoading } = useGetCapitalMionQuery('capitals');
	const lang = useAppSelector(selectLang);
  const total = data && totalCapitalCalc(data);
  return (
    <MainLayout
      activeMenuItem="capital"
    >
      <div className="container">
        {isLoading && (
          <div>{useLang("loading", lang)}</div>
        )}
        <div className="capital__head-block">
          <div className="capital__title">{useLang("total-capital", lang)} </div>
          <div className="capital__total">$ {total?.toFixed(2)}</div>
        </div>

        {!isLoading && (
          <div className="capital-wrapper">
            <div className="capital">
              <DayCapital />
              <div className="capital__legend">
                {data?.capitals.filter((item) => item.value > 0).map((capital, idx) => (
                  <div key={`circle${idx}`} className="capital__item">
                    <div
                      className="capital__item-circle"
                      style={{
                        backgroundColor: COLORS[idx] || COLORS[idx - COLORS.length]
                      }}
                    ></div>
                    <div className="capital__item-value">{(capital.value * capital.price / (total || 0) * 100).toFixed(2)} %</div>
                    <div className="capital__item-pair">{capital.pair_id}</div>
                  </div>
                ))}
              </div>
            </div>
            <div>
              <LineChart />
            </div>
          </div>
        )}
      </div>
    </MainLayout>
  )
};

export default CapitalPage;