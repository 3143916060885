import { Link } from 'react-router-dom';
import { useAppSelector } from '../../../app/hooks';
import { Lang } from '../../../app/lang/useLang';
import { selectLang } from './../../../app/redux/slices/CoreSlice';
import Svg from '../../../assets/svg/svgSprite';
import logoFooter from './../../../assets/images/logo-footer.png';
import proMotich from './../../../assets/images/pro-motich.png';
import bgFooter from './../../../assets/images/bg-footer.png';
import '../../Footer.scss';

const Footer = ({}) => {
	const lang = useAppSelector(selectLang);
  return (
    <div className='footer'>
			<div className="footer__top">
				<div className="footer__items">
					<div className="footer__item">
						<div className="footer__body-logo">
							<div className="footer__box">
								<div className="footer__logo">
									<Link to="/?pair=1"><img src={logoFooter} /></Link>
								</div>
								<div className="footer__socials">
									<a target="_blank" href="https://t.me/homyak_fm"><Svg svgName='telegram-footer' width={18} height={18} /></a>
									<a href="#"><Svg svgName='joystick' width={18} height={18} /></a>
								</div>
							</div>
							<div className="footer__help-btn">
									<a href="#">
									<Lang
										keyWord="help-the-project"
										lang={lang}
									/>
									</a>
							</div>
						</div>
					</div>
					<div className="footer__item">
							<div className="footer__title">
							<Lang
								keyWord="about-us"
								lang={lang}
							/>
							</div>
							<div className="footer__list">
								<a target="_blank" href="https://t.me/homyak_fm">
									<Lang
										keyWord="telegram-channel"
										lang={lang}
									/>
								</a>
								<a target="_blank" href="https://t.me/homyak_fm">
									<Lang
										keyWord="crypto-homyak"
										lang={lang}
									/>
								</a>
								<a target="_blank" href="https://trustwallet.com/">Trust Wallet</a>
								<a target="_blank" href="https://accounts.binance.com/ru/register?ref=436123371">Binance</a>
							</div>
					</div>
					<div className="footer__item">
							<div className="footer__title">
								<Lang
									keyWord="partners"
									lang={lang}
								/>
								</div>
								
							<div className="footer__svg">
								<Svg svgName='trust-binance' width={167} height={68} />
							</div>
					</div>
					<div className="footer__item">
						<img src={bgFooter} alt="" />
					</div>
				</div>
			</div>
			<div className="footer__bottom">
				<Lang
          keyWord="mion’s-exchange"
          lang={lang}
        />
				<div className="footer__team">
					<a target="_blank" href="https://t.me/matvasilenko">
						<img src={proMotich} alt="proMotich"/>
					</a>
				</div>
			</div>
		</div>
  );
};


export default Footer
