export const ggCapitalLogs = () => {
  return {
    thead: [
      {
        title: '',
        alias: 'id',
        type: 'text',
        width: 0,
        hide: true,
      },
      {
        title: 'Capital',
        alias: 'capital',
        type: 'text',
        width: 100
      },
      {
        title: 'Date',
        alias: 'date',
        type: 'text',
        width: 150
      },
      {
        title: 'Holders',
        alias: 'holders_count',
        type: 'text',
        width: 100
      },
      {
        title: 'Actions',
        alias: 'buttons',
        type: 'buttons',
        width: 150,
        // customButtons: [
        //   {
        //     icon: 'qr',
        //     path: '../filters-values',
        //     type: 'link',
        //   }
        // ]
      },
    ],
  }
}