import { FC } from "react";
import { ISvg } from "../svgSprite";

export const ArrowCircleSvg: FC<ISvg> = (config) => (
  <svg {...config} viewBox="0 0 193 171" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M126.8 157.2C120.6 159.3 114.2 160.7 107.8 161.2C101.4 161.7 95 161.4 88.7 160.4C82.5 159.3 76.4 157.5 70.8 155C65.2 152.4 59.8 149.2 55.1 145.4C50.3 141.6 46.1 137.2 42.5 132.4C40.7 130 39.1 127.5 37.6 124.9C36.9 123.6 36.1 122.3 35.5 121L34.6 119.2L51.7 109.9C45.4 108.5 38.9 105.8 32.8 101.8C26.7 97.7 21 92.3 16.1 85.6C11.2 92.3 7.09999 100.1 4.29999 109C1.49999 117.9 0 127.7 0 138L17.1 128.7L18.5 130.9C19.5 132.5 20.7 134 21.8 135.5C24.1 138.5 26.5 141.4 29.1 144C34.3 149.4 40.2 154 46.5 157.9C52.8 161.8 59.6 164.7 66.5 166.9C73.5 169 80.7 170.2 87.8 170.5C95 170.8 102.1 170.2 109 168.7C115.9 167.2 122.6 164.9 128.8 161.8C141.2 155.6 151.9 146.3 159.6 135.2C150.6 145.3 139.1 152.9 126.8 157.2Z" fill="white"/>
    <path d="M174.4 40.1C173.4 38.5 172.2 37 171.1 35.5C168.8 32.5 166.4 29.6 163.8 27C158.6 21.6 152.7 17 146.4 13.1C140.1 9.20003 133.3 6.30003 126.4 4.10003C119.4 2.00003 112.2 0.80003 105.1 0.50003C98 0.20003 90.9 0.80003 84 2.20003C77.1 3.70003 70.4 6.00003 64.2 9.10003C51.8 15.3 41.1 24.6 33.4 35.7C42.4 25.6 53.8 18.1 66.1 13.8C72.3 11.7 78.7 10.3 85.1 9.80003C91.5 9.30003 97.9 9.60003 104.2 10.6C110.4 11.7 116.5 13.5 122.1 16C127.7 18.6 133.1 21.8 137.8 25.6C142.6 29.4 146.8 33.8 150.4 38.6C152.2 41 153.8 43.5 155.3 46.1C156 47.4 156.8 48.7 157.4 50L158.3 51.8L141.2 61.1C147.5 62.5 154 65.2 160.1 69.2C166.2 73.3 171.9 78.7 176.8 85.4C181.7 78.7 185.8 70.9 188.6 62C191.4 53.1 192.9 43.3 192.9 33L175.8 42.3L174.4 40.1Z" fill="white"/>
  </svg>

);
