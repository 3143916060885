import React, { FC } from 'react';
import AdminLayout from '../../../layouts/AdminLayout';
import cx from 'classnames';
import { useGetSettingQuery, useUpdateSettingMutation } from '../../../app/redux/api/coreApi';
import { useAppSelector } from '../../../app/hooks';
import { selectCheckKey, selectLang } from './../../../app/redux/slices/CoreSlice';
import { useLang } from './../../../app/lang/useLang';

interface IAdminMainPageProps {

};

const AdminMainPage:FC<IAdminMainPageProps> = ({}) => {
	const lang = useAppSelector(selectLang);
  const { data: setting } = useGetSettingQuery('setting');
  const checkKey = useAppSelector(selectCheckKey);
  const [updateSetting] = useUpdateSettingMutation();

  const changeStatusExchange = () => {
    updateSetting({
      checkKey,
      last_update_info: setting?.last_update_info || '2023-01-01',
      working: setting?.working === 'closed' ? 'open' : 'closed',
    })
  };

  return (
    <AdminLayout>
      <div className="container">
        <div>
          <div>{useLang("status-exchange", lang)}</div>
          <button
            className={cx(
              'button-main',
              'button-main_success',
              setting?.working === 'closed' && 'button-main_danger'
            )}
            onClick={changeStatusExchange}
          >{setting?.working}</button>
        </div>
      </div>
    </AdminLayout>
  )
};

export default AdminMainPage;