import { FC } from "react";
import { ISvg } from "../svgSprite";

export const GameButtonCircleSvg: FC<ISvg> = (config) => (
  <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="28" cy="28" r="28" fill="white"/>
    <circle cx="28" cy="28" r="25" fill="white" stroke="#68182B"/>
  </svg>
);

