import React, { FC, useMemo } from 'react'
import GridBody from './components/GridBody';
import GridHead from './components/GridHead';
import styles from './grid-styles.module.scss'
import GridControls from './controls/GridControls';
import { getGridHandlersInner } from './utils';
import GridPaggination from './components/GridPaggination';
import './grid.scss'
import { IGridData } from './types';

interface GridComponentProps {
  gridData: IGridData;
  setGridData: any;
  transformData?: (head: any[], data: any) => IGridData;
  customStyles?: any;
  settings: {
    counter?: boolean;
    filter?: boolean;
    filters: {
      on: boolean;
      routeFilter?: string;
      value?: any;
      staticData?: boolean;
      options?: {
        id: string | number;
        title: string;
      }[];
    };
    url: string;
    width?: 'flexible',
    // handlers?: string[];
  };
  gridHandlers?: object;
  filter?: any;
  setFilter?: any;
  token?: string;
  thead: any;
  settingsPage: any;
  setSettingsPage: any;
}
const GridComponent: FC<GridComponentProps> = ({
  gridData, // thead, tbody - данные с сервера
  setGridData,
  thead,
  customStyles, // кастомные стили в scss
  settings,
  gridHandlers,
  filter, // filter - true
  setFilter, // filter - true
  token = '',
  transformData
}) => {
  const { counter, url, width, filters } = settings
  const { routeFilter } = filters
  return (
    <div className={[
      styles.gridWrapper,
      customStyles?.gridWrapper,
      width === 'flexible' ? styles.gridWrapper_flexible : '',
    ].join(' ')}>
      {filter && <GridControls
        filter={filter}
        setFilter={setFilter}
        styles={styles}
        routeFilter={routeFilter}
        url={url}
        gridData={gridData.boder}
        options={settings.filters?.options}
        staticData={settings.filters?.staticData}
      />}
      <GridHead
        styles={styles}
        thead={gridData.header}
        customStyles={customStyles}
        config={settings}
      />
      <GridBody
        styles={styles}
        tbody={gridData.boder}
        elems={thead}
        customStyles={customStyles}
        config={settings}
        gridHandlers={gridHandlers}
      />
      {transformData && <GridPaggination
        data={gridData.data}
        thead={thead}
        setGridData={setGridData}
        transformData={transformData}
      />}
    </div>
  )
}
export default GridComponent

//Document styles - CustomStyles
// gridWrapper - main wrapper
// gridHead - main wrapper head
// gridHead
// gridHeadItem
// gridBody
// gridRow
// gridRowItem