import React, { FC } from 'react';

interface IModalBackgroundProps {
    setShowModal: any;
};

const ModalBackground:FC<IModalBackgroundProps> = ({
    setShowModal
}) => {
    return (
        <div
            onClick={() => {
                setShowModal(false)
            }} 
            className="wrapper__background"
        ></div>
    )
};

export default ModalBackground;