import { useState, useEffect, FC } from 'react';
import cx from 'classnames';
import { IField } from '../types';

interface IImageProps extends IField {
  urlPreview: string;
}

const Image:FC<IImageProps> = ({
  dataItem,
  setDataItem,
  title,
  attribute,
  classes,
  urlPreview,
  styles,
}) => {
  const [preview, setPreview] = useState<string | false>(false)

  useEffect(() => {
    if (dataItem[attribute]) {
      setPreview(`${urlPreview}/${dataItem[attribute]}`)
    }
  }, [])
  const changeHandler = (e:any) => {
    const url = URL.createObjectURL(e.target.files[0])
    setDataItem({
      ...dataItem,
      [attribute]: e.target.files[0]
    })
    setPreview(url)
  }
  return (
    <div className={cx(
      classes.editor__item,
      styles?.wrapper
    )}>
      <div className={classes.editor__item__title}>{title}</div>
      <div className={classes.editor__item__image}>
        <div>
          <input type="file" onChange={changeHandler} />
        </div>
        <div className={classes.editor__item__image__img}>
          {preview && <div>
            <img src={preview}></img>
          </div>}
        </div>
      </div>
    </div>
  )
}
export default Image;
