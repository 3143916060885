/* eslint-disable react-hooks/rules-of-hooks */
import React, { FC, useState } from 'react';
import cx from 'classnames';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { createOrder, updateOrder } from '../../../app/redux/slices/OrderSlice';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { selectCurrencyPairs, selectLang } from './../../../app/redux/slices/CoreSlice';
import { selectToken, selectUser } from '../../../app/redux/slices/UserSlice';
import UIButton from '../../../widgets/ui/user-interface/Button/UIButton';
import { IOrder } from '../../../types/models/Order';
import ConfirmPayModal from './ConfirmPayModal';
import Modal from '../../../widgets/modal/Modal';
import { useLang } from './../../../app/lang/useLang';

interface IChangeBlockProps {

};

const ChangeBlock:FC<IChangeBlockProps> = ({}) => {
  const lang = useAppSelector(selectLang);
  const token = useAppSelector(selectToken);
  const navigate = useNavigate();

  const [fastOrder, setFastOrder] = useState<IOrder | false>(false);
  const [modalConfirmPay, setModaConfirmPay] = useState(false);

  const [loading, setLoading] = useState(false);
  const [validate, setValidate] = useState(false);
  const currencyPairs = useAppSelector(selectCurrencyPairs);
  const user = useAppSelector(selectUser);
  const dispatch = useAppDispatch();

  const [params]:any = useSearchParams();
  const activePair = currencyPairs?.find(item => item.id === Number(params.get('pair')));

  const [activeBlock, setActiveBlock] = useState<'buy' | 'sell'>('buy');
  const [value, setValue] = useState(0);
  const changeFunc = (e:any) => {
    setValue(e.target.value);
  };
  const course = currencyPairs?.find((item) => item.id === Number(params.get('pair')))?.value ?? 0;

  const createOrderFunc = async () => {
    if (user){
      if (Boolean(Number(value))) {
        setLoading(true)
        setValidate(false)
        const res:any = await dispatch(createOrder({
          token,
          currency_pair_id: Number(params.get('pair')),
          fixed_price: course,
          type: activeBlock,
          value,
          status: 'open',
          user_id: user?.id,
        }));
        setFastOrder(res.payload[0])
        setLoading(false)
        setValue(0)
      } else {
        setValidate(true)
      }
    } else {
      navigate('/profile');
    }
  };

  const cancelOrder = () => {
    if (user && fastOrder){
      dispatch(updateOrder({
        token,
        status: 'cancel',
        orderId: fastOrder.id ,
        user_id: user.id,
      }));
      setFastOrder(false);
    }
  };

  const modalConfirmPayShow = (status: boolean) => {
    setModaConfirmPay(status)
  };

  return (
    <div className="change-block">
      <div className="change-buttons">
        <div
          className={cx(
            "change-button change-button_buy",
            activeBlock === 'buy' && "change-button_active"
          )}
          onClick={() => setActiveBlock('buy')}
        >{useLang("buy", lang)}</div>
        <div
          className={cx(
            "change-button change-button_sell",
            activeBlock === 'sell' && "change-button_active"
          )}
          onClick={() => setActiveBlock('sell')}
        >{useLang("sell", lang)}</div>
      </div>
      <div className="change-inputs">
        <div className="change-inputs__block">
          <div className="change-inputs__title">{useLang("value", lang)}</div>
          <input
            type="number"
            value={value}
            onClick={(e:any) => e.target.select()}
            onChange={(e:any) => {
              setValidate(false)
              changeFunc(e)
            }}
            className={cx(
              "change-input",
              validate && "change-input_validate"
            )}
          />
          <div className="change-inputs__currency">{activePair?.firstCurrency.symbol}</div>
        </div>
        <div className="change-inputs__block">
          <div className="change-inputs__title">{useLang("total", lang)}</div>
          <input
            type="number"
            value={(value * course).toFixed(5)}
            onChange={(e:any) => changeFunc(e)}
            className="change-input"
            disabled
          />
          <div className="change-inputs__currency">{activePair?.secondCurrency.symbol}</div>
        </div>
      </div>
      <div className="text-center">
        <div className="px-3">
          <UIButton
            loading={loading}
            onClick={createOrderFunc}
          >
            {useLang("create-order", lang)}
          </UIButton>
        </div>
      </div>
      {fastOrder && (
        <div className="fast-order">
          <div className="mb-2">
            <div>
            {useLang("nice", lang)}{fastOrder.id} {useLang("has", lang)} <span className="blue mx-2">{useLang("opened", lang)}</span>
            </div>
            <div>{useLang("you-can", lang)} <span className="green mx-2">{useLang("pay", lang)}</span> {useLang("or", lang)} <span className="red mx-2">{useLang("cancel", lang)}</span> {useLang("this-order-now", lang)}</div>
          </div>
          <div className="d-flex gap-3">
            <button
              onClick={() => modalConfirmPayShow(true)}
              className="button-main button-main_success"
            >{useLang("pay-uppercase", lang)}</button>
            <button onClick={cancelOrder} className="button-main button-main_danger">{useLang("cancel-uppercase", lang)}</button>
          </div>
        </div>
      )}
      {modalConfirmPay && fastOrder && (
        <Modal
          showModal={modalConfirmPay}
          setShowModal={modalConfirmPayShow}
          title={`Confirm Pay: Order #${fastOrder.id}`}
        >
          <ConfirmPayModal
            type={fastOrder.type}
            value={fastOrder.value}
            fixed_price={fastOrder.fixed_price}
            currency_pair_id={fastOrder.currency_pair_id}
            orderId={fastOrder.id}
            userId={user?.id || 1}
            modalConfirmPayShow={modalConfirmPayShow}
            pair={fastOrder.pair}
            setFastOrder={setFastOrder}
          />
        </Modal>
      )}
    </div>
  )
};

export default ChangeBlock;
