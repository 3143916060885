import { FC } from "react";
import { ISvg } from "../svgSprite";

export const MionSvg: FC<ISvg> = (config) => (
  <svg width="68" height="58" viewBox="0 0 68 58" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M22.1321 38.2242C22.5099 38.8909 22.0284 39.7173 21.2621 39.7173H2.62259C1.85784 39.7173 1.37612 38.8938 1.75092 38.2272L11.0335 21.7179C11.4151 21.0394 12.3914 21.0377 12.7752 21.715L22.1321 38.2242Z" fill="white" stroke="white" stroke-width="0.7" />
    <path d="M44.7759 1.48401C45.1625 0.821853 46.1198 0.823263 46.5045 1.48655L50.9363 9.12818L56.0988 18.02C56.2796 18.3314 56.281 18.7125 56.0993 19.0234C52.4524 25.2645 45.532 37.1453 41.568 43.9205C41.1819 44.5803 40.2304 44.5767 39.8444 43.9169L30.2082 27.4433C30.0259 27.1316 30.0257 26.7459 30.2078 26.4341L44.7759 1.48401Z" fill="white" stroke="white" stroke-width="0.7" />
    <path d="M66.8259 38.2008C67.2274 38.8672 66.7474 39.7168 65.9693 39.7168H46.806C46.0295 39.7168 45.5493 38.8703 45.9476 38.2038L55.4911 22.2344C55.8784 21.5864 56.8165 21.5847 57.2061 22.2314L66.8259 38.2008Z" fill="white" stroke="white" stroke-width="0.7" />
    <path d="M44.7585 38.2266C44.935 38.5345 44.9351 38.9128 44.7589 39.2208L34.8774 56.4861C34.4936 57.1565 33.527 57.1572 33.1423 56.4873L12.1161 19.8705C11.94 19.5638 11.9389 19.1869 12.1134 18.8793L18.0164 8.46924L21.9663 1.52238C22.3489 0.849506 23.318 0.847718 23.7031 1.51917C27.5448 8.21764 31.3925 14.9223 35.2332 21.6197C38.4135 27.1606 41.5861 32.6938 44.7585 38.2266Z" fill="white" stroke="white" stroke-width="0.7" />
    <path d="M21.4645 38.5905H2.8042L12.0972 22.4951L21.4645 38.5905Z" fill="#F4FFFF" stroke="#68182B" stroke-width="0.7" />
    <path d="M45.4998 3.78906L49.8936 11.4019L54.4211 19.2377C51.2466 24.6945 44.5854 36.1881 41.4035 41.6449L32.4451 26.2557L45.4998 3.78906Z" fill="#91DCFF" stroke="#68182B" stroke-width="0.7" />
    <path d="M65.5948 38.5905H46.9346L56.2275 22.4951L65.5948 38.5905Z" fill="#F4FFFF" stroke="#68182B" stroke-width="0.7" />
    <path d="M43.1581 38.59L34.0584 54.217L13.822 19.4985L18.4983 11.395L22.892 3.78223C26.6612 10.2576 30.4379 16.7403 34.2071 23.2157C37.1957 28.3454 40.1769 33.4677 43.1581 38.59Z" fill="#BDF5FE" stroke="#68182B" stroke-width="0.7" />
  </svg>

);

