import React, { FC } from 'react';
import MainLayout from '../../layouts/MainLayout';
import Chart from '../../widgets/Chart/Chart';
import CurrencyPanel from './components/CurrencyPanel';
import ChangeBlock from './components/ChangeBlock';
import LispCurrency from './components/LispCurrency';
import Orders from './components/Orders';
import { useSearchParams, useLocation, Navigate } from 'react-router-dom';
import { selectLang } from './../../app/redux/slices/CoreSlice';
import { useAppSelector } from '../../app/hooks';
import Footer from '../../layouts/components/Footer/Footer';

interface IHomePageProps {

};

const HomePage:FC<IHomePageProps> = ({}) => {
  const lang = useAppSelector(selectLang);

  const [params]:any = useSearchParams();

  const route = useLocation();

  if (!params.get('pair')){
    return <Navigate replace to={`${route.pathname}?pair=1&lang=${localStorage.getItem('lang') || lang}`} />
  }
  return (
    <MainLayout activeMenuItem="home">
      <div className="container home">
        <div className="home-wrapper">
          <LispCurrency />
          <div>
            <CurrencyPanel />
            <Chart />
          </div>
          <ChangeBlock />
        </div>
        <Orders />
      </div>
			<Footer/>
    </MainLayout>
  )
};

export default HomePage;