import React, { FC } from 'react';
import { NET } from '../../../app/network';
import AdminLayout from '../../../layouts/AdminLayout';
import GridView from '../../../widgets/GridView/GridView';
import { ggEventCurrencies } from './../../../common/gridgenerator/ggEventCurrencies';
import { egEventCurrencies } from './../../../common/editorgenerator/egEventCurrencies';

interface IAdminEventCurrencyPageProps {

};

const AdminEventCurrencyPage: FC<IAdminEventCurrencyPageProps> = ({ }) => {
  return (
    <AdminLayout>
      <GridView
        config={{
          title: "Event Currencies",
          baseUrl: NET.BACK_URL,
          path: 'event-currencies',
          width: 'flexible',
          pagination: true,
          filters: {
            on: true,
            name: 'currency_pair_id',
            routeFilter: 'currency-pairs',
          },
        }}
        generator={{
          grid: ggEventCurrencies,
          editor: egEventCurrencies,
        }}
        model={{
          id: 1,
          currency_id: 1,
          currency_second_id: 2,
          date: '2023-01-01',
          value: 0,
        }}
      />
    </AdminLayout>
  )
};

export default AdminEventCurrencyPage;