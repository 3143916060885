import React, { FC } from 'react';
import { TSvg } from './lisp';
import { HomyakSvg } from './library/HomyakSvg';
import { PanelSvg } from './library/PanelSvg';
import { GameButtonSvg } from './library/GameButton';
import { AbacusSvg } from './library/AbacusSvg';
import { DonutSvg } from './library/DonutSvg';
import { PlayVideoSvg } from './library/PlayVideoSvg';
import { GameButtonCircleSvg } from './library/GameButtonCircleSvg';
import { ProfileSvg } from './library/ProfileSvg';
import { SettingsSvg } from './library/SettingsSvg';
import { EnergySvg } from './library/EnergySvg';
import { MionSvg } from './library/MionSvg';
// import { CubocSvg } from './library/CubocSvg';
import { ProfileNewSvg } from './library/ProfileNewSvg';
import { ArrowCircleSvg } from './library/ArrowCircleSvg';
import { HomeSvg } from './library/HomeSvg';
import { HomeActiveSvg } from './library/HomeActiveSvg';
import { CapitalSvg } from './library/CapitalSvg';
import { CapitalActiveSvg } from './library/CapitalActiveSvg';
import { BlogSvg } from './library/BlogSvg';
import { ProfileMenuSvg } from './library/ProfileMenuSvg';
import { ProfileMenuActiveSvg } from './library/ProfileMenuActiveSvg';
import { MionStreamSvg } from './library/MionStreamSvg';
import { ArrowCrossSvg } from './library/ArrowCrossSvg';
import { TelegramSvg } from './library/TelegramSvg';
import { BinanceSvg } from './library/BinanceSvg';
import { BellSvg } from './library/BellSvg';
import { LangSvg } from './library/LangSvg';
import { LogoMionSvg } from './library/LogoMionSvg';
import { JoystickSvg } from './library/JoystickSvg';
import { TrustBinanceSvg } from './library/TrustBinanceSvg';

export interface ISvg {
  width?: number;
  height?: number;
  className?: string;
  stroke?: string;
  fill?: string;
  // onClick?: () => void;
}

interface ISvgProps extends ISvg {
  svgName: TSvg;
  onClick?: () => void;
};

const Svg: FC<ISvgProps> = ({
  svgName,
  width,
  height,
  className,
  stroke,
  fill,
  // onClick,
}) => {
  const config = {
    width,
    height,
    className,
    stroke,
    fill,
    // onClick,
  };
  switch (svgName) {
    case "homyak":
      return <HomyakSvg {...config} />
    case "panel":
      return <PanelSvg {...config} />
    case "game-button":
      return <GameButtonSvg {...config} />
    case "game-button-circle":
      return <GameButtonCircleSvg {...config} />
    case "abacus":
      return <AbacusSvg {...config} />
    case "donut":
      return <DonutSvg {...config} />
    case "play-video":
      return <PlayVideoSvg {...config} />
    case "profile":
      return <ProfileSvg {...config} />
    case "profile-new":
      return <ProfileNewSvg {...config} />
    case "settings":
      return <SettingsSvg {...config} />
    case "energy":
      return <EnergySvg {...config} />
    case "mion":
      return <MionSvg {...config} />
    case "arrow-circle":
      return <ArrowCircleSvg {...config} />
    //menu
    case "home":
      return <HomeSvg {...config} />
    case "home-active":
      return <HomeActiveSvg {...config} />
    case "capital":
      return <CapitalSvg {...config} />
    case "capital-active":
      return <CapitalActiveSvg {...config} />
    case "telegram":
      return <BlogSvg {...config} />
    case "profile-menu":
      return <ProfileMenuSvg {...config} />
    case "profile-menu-active":
      return <ProfileMenuActiveSvg {...config} />
    case "mion-stream":
      return <MionStreamSvg {...config} />
    case "arrow-cross":
      return <ArrowCrossSvg {...config} />
    case "telegram-footer":
      return <TelegramSvg {...config} />
    case "binance":
      return <BinanceSvg {...config} />
    case "bell":
      return <BellSvg {...config} />
    case "lang":
      return <LangSvg {...config} />
    case "logo-mion":
      return <LogoMionSvg {...config} />
    case "joystick":
      return <JoystickSvg {...config} />
    case "trust-binance":
      return <TrustBinanceSvg {...config} />
    default:
      return <div>Icon</div>
  }
};

export default Svg;
