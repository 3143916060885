import { FC } from "react";
import { ISvg } from "../svgSprite";

export const ProfileMenuSvg: FC<ISvg> = (config) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="12" cy="12" r="9" stroke="white" />
    <circle cx="12" cy="10" r="3" stroke="white" />
    <path d="M18.0636 19C16.8532 16.9077 14.591 15.5 12 15.5C9.40906 15.5 7.14686 16.9077 5.93652 19" stroke="white" />
  </svg>
);
