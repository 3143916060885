import React, {
  FC
} from 'react'
import cx from 'classnames';
import { IField } from '../types';

const Input:FC<IField> = ({
  title,
  placeholder,
  classes,
  dataItem,
  setDataItem,
  attribute,
  errors,
  value = false,
  changeFunc = false,
  styles,
}) => {
  // const [val, setVal] = useState()
  // useEffect(() => {
  //     if (dataItem[attribute]) {
  //         setVal(dataItem[attribute])
  //     }
  // }, [])
  return (
    <div className={cx(
      classes.editor__item,
      styles?.wrapper
    )}>
      <div className={classes.editor__item__title}>
        {title}
      </div>
      <div className={cx(
        classes.editor__item__input,
        errors.length && classes.editor__input__error,
        styles?.input
      )} >
        <input placeholder={placeholder} value={value ? value : (dataItem && dataItem[attribute]) ? dataItem[attribute] : ''} type="text" onChange={(e) => {
          if (changeFunc) {
            changeFunc(e)
          } else {
            setDataItem({
              ...dataItem,
              [attribute]: e.target.value
            })
          }
        }} />
      </div>
      <div className={classes.editor__error}>
        {errors && errors.length > 0 && errors.map((err: any, idx: number) => (
          <div key={`err${attribute}${idx}`}>{err}</div>
        ))}
      </div>
    </div>
  )
}
export default Input
