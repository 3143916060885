import { IFieldType } from "../../widgets/editor/types"

export const egEventCurrencies = () => {
  const fields = [
    {
      attribute: 'value',
      type: IFieldType.FIELD_INPUT,
      title: 'Value',
      placeholder: 'Enter value',
      styles: {
        wrapper: 'w-100',
        input: 'w-100'
      }
    },
    {
      attribute: 'currency_id',
      type: IFieldType.FIELD_SELECT,
      title: 'First Currency',
      placeholder: 'Enter value',
      options: [
        {
          alias: "MION",
          value: 1,
        },
        {
          alias: "USDT",
          value: 2,
        },
        {
          alias: "UAH",
          value: 3,
        },
      ],
      styles: {
        wrapper: 'w-50 pr-3',
        input: 'w-100'
      }
    },
    {
      attribute: 'currency_second_id',
      type: IFieldType.FIELD_SELECT,
      title: 'Second Currency',
      placeholder: 'Enter value',
      options: [
        {
          alias: "MION",
          value: 1,
        },
        {
          alias: "USDT",
          value: 2,
        },
        {
          alias: "UAH",
          value: 3,
        },
      ],
      styles: {
        wrapper: 'w-50 pr-3',
        input: 'w-100'
      }
    },
    {
      attribute: 'date',
      type: 'date',
      title: 'Date',
      placeholder: 'Enter Date',
      format: 'YYYY-MM-DD',
      styles: {
        wrapper: 'w-50',
        input: 'w-100'
      }
    },
  ]
  return fields
}