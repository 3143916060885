import { FC } from "react";
import { ISvg } from "../svgSprite";

export const LangSvg: FC<ISvg> = (config) => (
	<svg {...config} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
		<circle cx="11" cy="11" r="10"  stroke-linejoin="round"/>
		<ellipse cx="11" cy="11" rx="4" ry="10"  stroke-linejoin="round"/>
		<path d="M21 11H1"/>
	</svg>
);
