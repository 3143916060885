import { FC } from "react";
import { ISvg } from "../svgSprite";

export const GameButtonSvg: FC<ISvg> = (config) => (
  <svg width="87" height="79" viewBox="0 0 87 79" fill="none" xmlns="http://www.w3.org/2000/svg">
    <ellipse cx="43.5" cy="39.5" rx="43.5" ry="39.5" fill="white"/>
    <ellipse cx="43.5" cy="39.5" rx="40.5" ry="36.5" fill="white" stroke="#68182B" stroke-width="2"/>
  </svg>

);

