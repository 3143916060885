/* eslint-disable react-hooks/rules-of-hooks */
import { FC } from 'react';
import { NET } from '../../../app/network';
import GridView from '../../../widgets/GridView/GridView';
import AdminLayout from '../../../layouts/AdminLayout';
import { ggCapital } from '../../../common/gridgenerator/ggCapital';
import { useGetCapitalMionQuery } from '../../../app/redux/api/capitalMion';
import { egCapitalMion } from '../../../common/editorgenerator/egCapitalMion';
import { selectLang } from './../../../app/redux/slices/CoreSlice';
import { useAppSelector } from '../../../app/hooks';
import { useLang } from './../../../app/lang/useLang';

interface IAdminCapitalPageProps {

};

const AdminCapitalPage:FC<IAdminCapitalPageProps> = ({}) => {
	const lang = useAppSelector(selectLang);
  const { data, isLoading } = useGetCapitalMionQuery('capitals');
  return (
    <AdminLayout>
      {!isLoading && (
        <div className="d-flex">
          <div className="mr-1">{useLang("total", lang)}</div>
          <div>{data?.capitals.reduce((acc, item) => {
            return acc + (item.price * (item.value || 0))
          }, 0)}</div>
        </div>
      )}
      <GridView
        config={{
          title: "Capital Mion",
          baseUrl: NET.BACK_URL,
          path: 'admin-capital-mion',
          width: 'flexible',
          pagination: true,
          filters: {
            on: false,
          }
        }}
        generator={{
          grid: ggCapital,
          editor: egCapitalMion,
        }}
        model={{
          id: 1,
          value: '',
          pair_id: 1,
          price: '',
          price_buy: '',
        }}
      />
    </AdminLayout>
  )
};

export default AdminCapitalPage;