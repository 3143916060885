import React, { FC } from 'react'
import Text from './cells/Text';
import Check from './cells/Check';
import Buttons from './cells/Buttons';
import Image from './cells/Image';
import ShortText from './cells/ShortText';
import BooleanText from './cells/BooleanText';
import Status from './cells/Status';
import Link from './cells/Link';

interface IGridSwitcherProps {
  type: any;
  idRow: any;
  value: any;
  width: any;
  name: any;
  net: any;
  bg: number;
  styles: any;
  customStyles: any;
  gridHandlers: any;
  elem: any;
  cell: any;
}

const GridSwitcher: FC<IGridSwitcherProps> = ({
  type,
  idRow,
  value,
  width,
  name,
  net,
  styles,
  bg,
  customStyles,
  gridHandlers,
  elem,
  cell,
}) => {
  const config = {
    ...cell,
    bg,
    idRow,
    value,
    name,
    styles,
    width,
    net,
    customStyles: customStyles || {},
    gridHandlers: gridHandlers || {},
    elem
  }

  switch (type) {
    case 'text':
      return (
        <Text {...config} />
      );
    case 'boolean-text':
      return (
        <BooleanText {...config} />
      );
    case 'short-text':
      return (
        <ShortText {...config} />
      );
    case 'buttons':
      return (
        <Buttons {...config} />
      )
    case 'check':
      return (
        <Check {...config} />
      );
    case 'image':
      return (
        <Image {...config} />
      );
    case 'status':
      return (
        <Status {...config} />
      );
    case 'link':
      return (
        <Link {...config} />
      );
    default: return <div></div>
  }
}
export default GridSwitcher