import { FC } from "react";
import { ISvg } from "../svgSprite";

export const LogoMionSvg: FC<ISvg> = (config) => (
  <svg {...config} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="10" cy="10" r="9.5" stroke="url(#paint0_linear_576_4768)" />
    <circle cx="10" cy="10" r="9.5" stroke="url(#paint1_linear_576_4768)" stroke-opacity="0.2" />
    <path d="M5.76904 10.7042V14.6155L8.94212 11.33L5.76904 8.51384V5.38477L9.99981 9.92192L14.2306 5.38477V8.51384L10.9064 11.33L14.2306 14.6155C14.2306 14.6155 14.2306 12.2317 14.2306 10.7042" stroke="url(#paint2_linear_576_4768)" />
    <path d="M5.76904 10.7042V14.6155L8.94212 11.33L5.76904 8.51384V5.38477L9.99981 9.92192L14.2306 5.38477V8.51384L10.9064 11.33L14.2306 14.6155C14.2306 14.6155 14.2306 12.2317 14.2306 10.7042" stroke="url(#paint3_linear_576_4768)" stroke-opacity="0.2" />
    <defs>
      <linearGradient id="paint0_linear_576_4768" x1="5.02793" y1="-2.53732" x2="23.338" y2="3.66106" gradientUnits="userSpaceOnUse">
        <stop stop-color="#4EAFCD" />
        <stop offset="1" stop-color="#7C8AE5" />
      </linearGradient>
      <linearGradient id="paint1_linear_576_4768" x1="10" y1="0" x2="10" y2="20" gradientUnits="userSpaceOnUse">
        <stop stop-color="white" stop-opacity="0" />
        <stop offset="0.989602" />
      </linearGradient>
      <linearGradient id="paint2_linear_576_4768" x1="7.89625" y1="4.2137" x2="15.7721" y2="6.65769" gradientUnits="userSpaceOnUse">
        <stop stop-color="#4EAFCD" />
        <stop offset="1" stop-color="#7C8AE5" />
      </linearGradient>
      <linearGradient id="paint3_linear_576_4768" x1="9.99981" y1="5.38477" x2="9.99981" y2="14.6155" gradientUnits="userSpaceOnUse">
        <stop stop-color="white" stop-opacity="0" />
        <stop offset="0.989602" />
      </linearGradient>
    </defs>
  </svg>

);
