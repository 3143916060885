import { IOrder } from "../../../types/models/Order";
import { NET } from "../../network";
import { IParams } from "../slices/OrderSlice";

export const actionFetchOrders = async ({
  user_id,
  token,
}:IParams['ordersUser']) => {
  const res = await fetch(`${NET.BACK_URL}/orders-user/${user_id}`, {
    headers: {
      'Content-type': 'application/json',
      'Authorization': 'Bearer ' + token,
    },
  });

  const data = await res.json();

  return data.data as IOrder[];
}

export const actionCreateOrder = async (params:IParams['create']) => {
  const res = await fetch(`${NET.BACK_URL}/orders-user`, {
    method: "POST",
    headers: {
      'Content-type': 'application/json',
      'Authorization': 'Bearer ' + params.token,
    },
    body: JSON.stringify({
      ...params,
      token: '',
    })
  });

  const data = await res.json();
  return data.data as IOrder[];
}

export const actionUpdateOrder = async (params:IParams['update']) => {
  const res = await fetch(`${NET.BACK_URL}/orders-user/${params?.orderId}`, {
    method: "POST",
    headers: {
      'Content-type': 'application/json',
      'Authorization': 'Bearer ' + params.token,
    },
    body: JSON.stringify({
      ...params,
      token: '',
    }),
  });

  const data = await res.json();
  return data.data as IOrder[];
}
