import { IUser } from "../../../types/models/User";
import { NET } from "../../network";
import { IParams } from "../slices/UserSlice";

export const actionStartRegisterUser = async ({
  phone,
  email,
  name,
  code,
  user_id,
  refferal,
}:IParams['register']) => {
  const res = await fetch(`${NET.BACK_URL}/register`, {
    method: "POST",
    headers: {
      'Content-type': 'application/json'
    },
    body: JSON.stringify(code ? {
      phone,
      email,
      name,
      code,
      user_id,
    } : {
      phone,
      name,
      email,
      refferal
    })
  });

  const data = await res.json();

  return data as IUser;
}

export const actionUpdateUser = async (params:IParams['update']) => {
  const res = await fetch(`${NET.BACK_URL}/user/${params.id}`, {
    method: "POST",
    headers: {
      'Content-type': 'application/json'
    },
    body: JSON.stringify(params)
  });

  const data = await res.json();

  return data as IUser;
}

export const actionCheckToken = async (params:IParams['token']) => {
  const res = await fetch(`${NET.BACK_URL}/token`, {
    method: "POST",
    headers: {
      'Content-type': 'application/json'
    },
    body: JSON.stringify(params)
  });

  if (res.status === 403) {
    localStorage.setItem('user_id', '');
    localStorage.setItem('token', '');
    return 403;
  }
  const data = await res.json();
  return data as IUser;
}

export const actionCheckUser = async (params:IParams['check']) => {
  const res = await fetch(`${NET.BACK_URL}/login`, {
    method: "POST",
    headers: {
      'Content-type': 'application/json'
    },
    body: JSON.stringify(params)
  });

  if (res.status === 422) {
    localStorage.setItem('user_id', '');
    localStorage.setItem('token', '');
    return 422;
  }
  const data = await res.json();
  return data as IUser;
}

export const actionLoginUser = async (params:IParams['login']) => {
  const res = await fetch(`${NET.BACK_URL}/login`, {
    method: "POST",
    headers: {
      'Content-type': 'application/json'
    },
    body: JSON.stringify(params)
  });

  if (res.status === 422) {
    localStorage.setItem('token', '');
    return 422;
  }
  const data = await res.json();
  return data as {
    user: IUser,
    token: string,
  };
}
