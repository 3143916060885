import { FC } from "react";
import { ISvg } from "../svgSprite";

export const ArrowCrossSvg: FC<ISvg> = (config) => (
  <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M17 5H3L7 1.5" stroke="white" stroke-width="2" stroke-linecap="square" />
    <path d="M1 9H15L11 12" stroke="white" stroke-width="2" stroke-linecap="square" />
  </svg>
);


