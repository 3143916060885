import { FC } from "react";
import { ISvg } from "../svgSprite";

export const BellSvg: FC<ISvg> = (config) => (
  <svg {...config} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path fill-rule="evenodd" clip-rule="evenodd" d="M10.3041 4.5C7.90673 4.5 5.84677 6.20157 5.39404 8.55577L3.518 18.3112C3.46167 18.6041 3.53905 18.9069 3.72902 19.1368C3.91899 19.3668 4.20171 19.5 4.50001 19.5H19.5C19.7983 19.5 20.081 19.3668 20.271 19.1368C20.461 18.9069 20.5383 18.6041 20.482 18.3112L18.606 8.55576C18.1532 6.20156 16.0933 4.5 13.6959 4.5H10.3041Z" />
		<path fill-rule="evenodd" clip-rule="evenodd" d="M22 19.5H2V17.5H22V19.5Z" />
		<path fill-rule="evenodd" clip-rule="evenodd" d="M8 18.5C8 17.9477 8.44772 17.5 9 17.5H15C15.5523 17.5 16 17.9477 16 18.5V19.5C16 21.1569 14.6569 22.5 13 22.5H11C9.34315 22.5 8 21.1569 8 19.5V18.5ZM10 19.5C10 20.0523 10.4477 20.5 11 20.5H13C13.5523 20.5 14 20.0523 14 19.5H10Z" />
		<path fill-rule="evenodd" clip-rule="evenodd" d="M10 6.5C9.44772 6.5 9 6.05228 9 5.5V4.5C9 2.84315 10.3431 1.5 12 1.5C13.6569 1.5 15 2.84315 15 4.5V5.5C15 6.05228 14.5523 6.5 14 6.5H10Z" />
	</svg>
);
