import React, { FC, useState } from 'react';
import { useAppSelector } from '../../../app/hooks';
import { lastValueCurrency, selectActivePair, selectCurrencyPairs } from './../../../app/redux/slices/CoreSlice';
import cx from 'classnames';
import Svg from '../../../assets/svg/svgSprite';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Overlayer from '../../../widgets/ui/Overlayer/Overlayer';

interface ICurrencyPanelProps {

};

const CurrencyPanel: FC<ICurrencyPanelProps> = ({ }) => {
  const currency = useAppSelector(lastValueCurrency);
  const currencyPairs = useAppSelector(selectCurrencyPairs);

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const activePair = useAppSelector(selectActivePair);
  const [showCurrencyList, setShowCurrencyList] = useState(false);
  return (
    <div className="currency-panel">
      <div onClick={() => setShowCurrencyList(!showCurrencyList)} className="currency-panel__title">
        <div className="currency-panel__icon">
          <Svg svgName='arrow-cross' />
        </div>
        <div>{activePair}</div>
        {showCurrencyList && (
          <div className="currency-panel__mobile-lisp">
            {currencyPairs && currencyPairs.map((item) => (
              <div
                key={item.id}
                className={cx(
                  "currency-lisp__item",
                  item.id !== Number(searchParams.get('pair')) ? 'grey-light' : ''
                )}
                onClick={() => {
                  navigate(`/?pair=${item.id}`);
                }}
              >
                <div>{item.firstCurrency.symbol + "/" + item.secondCurrency.symbol}</div>
                <div className="currency-lisp__item-price">{item.value}</div>
              </div>
            ))}
          </div>
        )}
        <div className="currency-lisp__mobile-overlayer">
          {showCurrencyList && <Overlayer onClick={() => setShowCurrencyList(false)} />}
        </div>
      </div>
      <div className="flex items-center gap-8 ml-2">
        <div className="flex gap-2">
          <div className="currency-panel__price-block">
            <div className="currency-panel__price font-semibold">
              <div>{currency?.value || 0}</div>
              <Svg svgName="logo-mion" width={20} height={20}/>
            </div>
            <div className="currency-panel__dollar pr-5">${currency?.value || 0}</div>
          </div>
        </div>
        <div className="flex items-end flex-col d-none text-right">
          <div className="grey-light text-xs">Изменения за 24ч</div>
          <div className={cx(
            "currency-panel__dollar text-xs",
            currency.percent.plus ? 'green' : 'red'
          )}>
            {(Number(currency.percent.value) * currency?.value).toFixed(4)} {currency.percent.plus ? '+' : '-'}{currency.percent.value}%
          </div>
        </div>
        <div className="flex items-end flex-col d-none">
          <div className="grey-light text-xs">Объем за 24ч</div>
          <div className={cx(
            "currency-panel__dollar text-xs white text-right",
          )}>
            0
          </div>
        </div>
        {/* <div className="grey-light d-none">{activePair}</div> */}
      </div>
    </div>
  )
};

export default CurrencyPanel;
