import { IFieldType } from "../../widgets/editor/types"
import { NET } from "../network"


export const egCapitalMion = () => {
  const fields = [
    {
      attribute: 'pair_id',
      type: IFieldType.FIELD_INPUT,
      title: 'Pair',
      placeholder: 'Enter pair',
      styles: {
        wrapper: 'w-50 pr-3',
        input: 'w-100'
      }
    },
    {
      attribute: 'value',
      type: IFieldType.FIELD_INPUT,
      title: 'Value',
      placeholder: 'Enter value',
      styles: {
        wrapper: 'w-50 pl-3',
        input: 'w-100'
      }
    },
    {
      attribute: 'price',
      type: IFieldType.FIELD_INPUT,
      title: 'Price',
      placeholder: 'Enter price',
      styles: {
        wrapper: 'w-50 pr-3',
        input: 'w-100'
      }
    },
    {
      attribute: 'price_buy',
      type: IFieldType.FIELD_INPUT,
      title: 'Price Buy',
      placeholder: 'Enter price buy',
      styles: {
        wrapper: 'w-50 pl-3',
        input: 'w-100'
      }
    },
  ]
  return fields
}