import { TLang } from "../../types/core";
import { DICT } from "./dictionary/dictionary";
import { TDict } from "./dictionary/types";

export const useLang = (key:TDict, lang:TLang) => {
  return DICT[key][lang];
}

export const LANGS:TLang[] = [
  'en',
  'ua',
  'ru',
];

export const Lang = ({
  keyWord,
  lang,
} : {
  keyWord:TDict;
  lang:TLang;
}) => {
  return (
    <span>{DICT[keyWord][lang]}</span>
  );
};
