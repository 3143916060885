import { Wenter } from "./words/enter";
import { WpersonalProfile } from "./words/personal-profile";
import { WHome } from './words/home';
import { WCapital } from './words/capital';
import { QChangeYour } from './words/change-your-quote';
import { WCreateOrder } from './words/create-order';
import { WCurrencyPairs } from './words/currency-pairs';
import { WBuy } from './words/buy';
import { WSell } from './words/sell';
import { WValue } from './words/value';
import { WTotal } from './words/total';
import { QNice } from './words/nice';
import { WHas } from "./words/has";
import { WOpened } from './words/opened';
import { WYouCan } from './words/you-can';
import { WPay } from './words/pay';
import { WOr } from "./words/or";
import { WCancel } from "./words/cancel";
import { QThisOrder } from './words/this-order-now';
import { WPayUppercase } from "./words/pay-uppercase";
import { WCancelUppercase } from "./words/cancel-uppercase";
import { QYouNeedToPay } from './words/you-need-to-pay';
import { QUseTheseWallets } from './words/use-these-wallets';
import { WClickToCopy } from './words/click-to-copy';
import { QConfirmingAfter } from "./words/confirming-after-payment";
import { QYouGetALink } from "./words/you-get-a-link";
import { QPasteYourNickname } from './words/paste-your-nickname';
import { QClickConfirm } from './words/click-confirm';
import { WInProgress } from './words/in-progress';
import { WConfirm } from "./words/confirm";
import { QYourOrderWillProcessing } from "./words/your-order-will-processing";
import { QYouWillReceive } from "./words/you-will-receive";
import { QOnYourWallet } from './words/on-your-wallet';
import { QYouNeedTo } from "./words/you-need-to";
import { WOrders } from "./words/orders";
import { WNoOrders } from './words/no-orders';
import { WStatus } from './words/status';
import { WType } from "./words/type";
import { WFixedPrice } from './words/fixed-price';
import { WPair } from "./words/pair";
import { WDate } from './words/date';
import { WHistory } from './words/history';
import { WOpenOrders } from "./words/open-orders";
import { QBrowserDoesNot } from './words/browser-does-not';
import { WLoading } from './words/loading';
import { QMonFri } from './words/mon-fri';
import { QLastPriceUpdate } from './words/last-price-update';
import { WHello } from './words/hello';
import { WCreate } from "./words/create";
import { WStatusExchange } from "./words/status-exchange";
import { WProfile } from './words/profile';
import { WSignIn } from './words/sign-in';
import { WSignUp } from "./words/sign-up";
import { WOpen } from "./words/open";
import { WClosed } from './words/closed';
import { QThankYou } from "./words/thank-you";
import { QForBuyOrSell } from './words/for-buy-or-sell';
import { QIfUHaventGot } from './words/if-u-havent-got';
import { QIfUHaveAccount } from "./words/if-u-have-account";
import { QAlsoUCan } from './words/also-u-can';
import { WSkip } from './words/skip';
import { WNext } from './words/next';
import { WLogin } from "./words/login";
import { WRegistration } from './words/registration';
import { WYourName } from "./words/your-name";
import { WYourPhone } from './words/your-phone';
import { WCodeFromSms } from './words/code-from-sms';
import { QPasteLink } from "./words/paste-link";
import { WOrder } from "./words/order";
import { WPayOrder } from "./words/pay-order";
import { WSendCode } from "./words/send-code";
import { QWriteMetamaskId } from './words/write-metamask-id';
import { WConfirmed } from "./words/confirmed";
import { WRejected } from "./words/rejected";
import { WCanceled } from "./words/canceled";
import { WBuyLowercase } from "./words/buy-lowercase";
import { WSellLowercase } from "./words/sell-lowercase";
import { WUncorrectLogin } from './words/uncorrect-login';
import { WUncorrectCode } from "./words/uncorrect-code";
import { WTotalCapital } from "./words/total-capital";
import { WCapitalProgress } from "./words/capital-progress";
import { WPaymentConfirmation } from "./words/payment-confirmation";
import { WInProcessing } from "./words/in-processing";
import { QGoToProfile } from "./words/go-to-profile";
import { WBlog } from './words/blog';
import { WExit } from './words/exit';
import { WEmail } from './words/email';
import { WName } from './words/name';
import { WNeedAuth } from './words/need-auth';
import { WSave } from './words/save';
import { WEdit } from './words/edit';
import { QAddWalletID } from './words/add-wallet';
import { WWalletId } from './words/wallet-id';
import { WEnterWalletId } from "./words/enter-wallet-id";
import { WHelpTheProject } from "./words/help-the-project";
import { WAboutUs } from './words/about-us';
import { WTelegramChannel } from './words/telegram-channel';
import { WSupport } from './words/support';
import { WMionsExchange } from './words/mion’s-exchange';
import { QGetASignUpBonus } from './words/get-a-sign-up-bonus';
import { WCryptoHomyak } from "./words/crypto-homyak";
import { WPartners } from './words/partners';

export const DICT = {
  "blog": WBlog,
  "buy": WBuy,
  "cancel": WCancel,
  "capital": WCapital,
  "currency-pairs": WCurrencyPairs,
  "create-order": WCreateOrder,
  "enter": Wenter,
  "exit": WExit,
  "email": WEmail,
  "home": WHome,
  "name": WName,
  "need-auth": WNeedAuth,
  "nice": QNice,
  "opened": WOpened,
  "or": WOr,
  "personal-profile": WpersonalProfile,
  "quote-change-your": QChangeYour,
  "sell": WSell,
  "value": WValue,
  "total": WTotal,
  "has": WHas,
  "you-can": WYouCan,
  "pay": WPay,
  "this-order-now": QThisOrder,
  "pay-uppercase": WPayUppercase,
  "cancel-uppercase": WCancelUppercase,
  "you-need-to-pay": QYouNeedToPay,
  "use-these-wallets": QUseTheseWallets,
  "click-to-copy": WClickToCopy,
  "confirming-after-payment": QConfirmingAfter,
  "you-get-a-link": QYouGetALink,
  "paste-your-nickname": QPasteYourNickname,
  "click-confirm": QClickConfirm,
  "in-progress": WInProgress,
  "confirm": WConfirm,
  "your-order-will-processing": QYourOrderWillProcessing,
  "you-will-receive": QYouWillReceive,
  "on-your-wallet": QOnYourWallet,
  "you-need-to": QYouNeedTo,
  "orders": WOrders,
  "no-orders": WNoOrders,
  "status": WStatus,
  "type": WType,
  "fixed-price": WFixedPrice,
  "pair": WPair,
  "date": WDate,
  "history": WHistory,
  "open-orders": WOpenOrders,
  "browser-does-not": QBrowserDoesNot,
  "loading": WLoading,
  "mon-fri": QMonFri,
  "last-price-update": QLastPriceUpdate,
  "hello": WHello,
  "create": WCreate,
  "status-exchange": WStatusExchange,
  "profile": WProfile,
  "sign-in": WSignIn,
  "sign-up": WSignUp,
  "open": WOpen,
  "closed": WClosed,
  "thank-you": QThankYou,
  "for-buy-or-sell": QForBuyOrSell,
  "if-u-havent-got": QIfUHaventGot,
  "if-u-have-account": QIfUHaveAccount,
  "also-u-can": QAlsoUCan,
  "skip": WSkip,
  "next": WNext,
  "login": WLogin,
  "registration": WRegistration,
  "your-name": WYourName,
  "your-phone": WYourPhone,
  "code-from-sms": WCodeFromSms,
  "paste-link": QPasteLink,
  "order": WOrder,
  "pay-order": WPayOrder,
  "send-code": WSendCode,
  "write-metamask-id": QWriteMetamaskId,
  "confirmed": WConfirmed,
  "rejected": WRejected,
  "canceled": WCanceled,
  "buy-lowercase": WBuyLowercase,
  "sell-lowercase": WSellLowercase,
  "uncorrect-login": WUncorrectLogin,
  "uncorrect-code": WUncorrectCode,
  "total-capital": WTotalCapital,
  "capital-progress": WCapitalProgress,
  "payment-confirmation": WPaymentConfirmation,
  "in-processing": WInProcessing,
  "go-to-profile": QGoToProfile,
  "save": WSave,
  "edit": WEdit,
  "add-wallet": QAddWalletID,
  "wallet-id": WWalletId,
  "enter-wallet-id": WEnterWalletId,
  "help-the-project": WHelpTheProject,
  "about-us": WAboutUs,
  "telegram-channel": WTelegramChannel,
  "support": WSupport,
  "mion’s-exchange": WMionsExchange,
  "get-a-sign-up-bonus": QGetASignUpBonus,
  "partners": WPartners,
  "crypto-homyak": WCryptoHomyak,
}
