/* eslint-disable react-hooks/rules-of-hooks */
import React, { FC, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { selectToken, selectUser, showModalRegister, viewModalLogin, viewModalRegister } from '../../app/redux/slices/UserSlice';
import Svg from '../../assets/svg/svgSprite';
import Modal from '../../widgets/modal/Modal';
import Overlayer from '../../widgets/ui/Overlayer/Overlayer';
import logo from './../../assets/images/logo-footer.png';
import ProfileModal from './ProfileModal';
import RegisterModal from './RegisterModal';
import { showModalLogin } from './../../app/redux/slices/UserSlice';
import LoginModal from './LoginModal';
import cx from 'classnames';
import { Link } from 'react-router-dom';
import { useGetSettingQuery } from '../../app/redux/api/coreApi';
import { selectLang } from '../../app/redux/slices/CoreSlice';
import { Lang, LANGS, useLang } from './../../app/lang/useLang';
import { TLang } from '../../types/core';
import { useSearchParams } from 'react-router-dom';
import { TMenuItem } from '../MainLayout';

interface IMenuProps {
  activeMenuItem: TMenuItem;
};

const Menu: FC<IMenuProps> = ({
  activeMenuItem,
}) => {
  const lang = useAppSelector(selectLang);
  const user = useAppSelector(selectUser);
  const token = useAppSelector(selectToken);
  const { data: setting } = useGetSettingQuery('settings');
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openLangModal, setOpenLangModal] = useState<boolean>(false);
  const modalRegister = useAppSelector(showModalRegister);
  const modalLogin = useAppSelector(showModalLogin);
  const dispatch = useAppDispatch();
  const status = setting?.working || 'open'
  const statusExchange = status[0].toUpperCase() + status.slice(1)

  const [searchParams, setSearchParams]: any = useSearchParams();

  const modalRegisterShow = (status: boolean) => {
    dispatch(viewModalRegister(status));
  };

  const modalLoginShow = (status: boolean) => {
    dispatch(viewModalLogin(status));
  };

  const changeLang = (item: TLang) => {
    const currentParams = Object.fromEntries([...searchParams]);
    setSearchParams({
      ...currentParams,
      lang: item,
    })
  };

  return (
      <div className="menu">
				<div className="menu__top">
					<div className="menu__quote">{useLang("quote-change-your", lang)}</div>
					<div className="menu__work">
            <div className="d-flex align-items-center">
              <div className={cx(
                  "menu__indicator-title",
                  status === 'closed' && 'menu__indicator-title_closed'
                )}>
                {statusExchange === "Open" ? `${useLang("open", lang)}` : `${useLang("closed", lang)}`}
              </div>
							<div className={cx(
                "menu__indicator",
                status === 'closed' && 'menu__indicator_closed'
              )}></div>
              {/* TODO: Create separate component lang Dropdown */}
              <div className="menu__lang menu__lang-mobile" onClick={() => setOpenLangModal(!openLangModal)}>
                <div>{lang.toUpperCase()}</div>
                {openLangModal && (
                  <div className="menu__lang-modal">
                    {LANGS.sort((item, nextItem) => {
                      if (item === lang) {
                        return -1;
                      }
                      if (nextItem !== lang) {
                        return 1
                      }
                      return 0
                    }).map((item) => (
                      <div
                        key={item}
                        className="menu__lang-modal-item"
                        onClick={() => changeLang(item)}
                      >
                        {item}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
				</div>
				<div className="menu__body">
					<div className="menu__left d-flex align-items-center">
						<div className="menu__logo">
							<Link to="/?pair=1"><img src={logo} /></Link>
						</div>
						<div className="menu__bell">
							<Svg svgName='bell' width={20} height={20} />
						</div>
						<div className="menu__promo">
							<Lang
							  keyWord="get-a-sign-up-bonus"
								lang={lang}
							/>
						</div>
					</div>
					<div className="menu__profile d-flex align-items-center">
						<div className="menu__items">
							<Link className={
								cx(
									"menu__item",
									activeMenuItem === 'home' && "menu__item_active"
								)
							} to="/?pair=1">
								<Svg svgName='home' width={30} height={30} />
								{useLang('home', lang)}
								</Link>
							<Link className={
								cx(
									"menu__item",
									activeMenuItem === 'capital' && "menu__item_active"
								)
							} to="/capital">
								<Svg svgName='capital' width={30} height={30} />
								{useLang('capital', lang)}
								</Link>
						</div>

						<div className="menu__enter__btn">
							{user && token ? (
								<div className="menu__name">{useLang("hello", lang)}<br/> {user.name}!</div>
								) : <Link to="/auth/registration">{useLang("registration", lang)}</Link> }
								{/* <button onClick={() => setOpenModal(!openModal)}>{useLang("registration", lang)}</button> */}
						</div>

						<div className="menu__icons">
							<div onClick={() => setOpenModal(!openModal)} className="menu__icon-profile menu__profile__btn">
								<Svg svgName='profile-new' width={20} height={20} />
								{openModal && (
								<ProfileModal setOpenModal={setOpenModal} />
								)}
							</div>
							<div className="menu__lang" onClick={() => setOpenLangModal(!openLangModal)}>
								<Svg svgName='lang' width={20} height={20} />
								 {/* {lang.toUpperCase()} */}
								{openLangModal && (
									<div className="menu__lang-modal">
										{LANGS.sort((item, nextItem) => {
											if (item === lang) {
												return -1;
											}
											if (nextItem !== lang) {
												return 1
											}
											return 0
										}).map((item) => (
											<div
												key={item}
												className="menu__lang-modal-item"
												onClick={() => changeLang(item)}
											>
												{item}
											</div>
										))}
									</div>
								)}
							</div>
						</div>

					</div>
					{openModal && (
						<Overlayer
							onClick={() => setOpenModal(false)}
						/>
					)}
					{modalRegister && (
						<Modal
							showModal={modalRegister}
							setShowModal={modalRegisterShow}
							title="Registration"
						>
							<RegisterModal />
						</Modal>
					)}
					{modalLogin && (
						<Modal
							showModal={modalLogin}
							setShowModal={modalLoginShow}
							title="Login"
						>
							<LoginModal />
						</Modal>
					)}
				</div>
      </div>
  )
};

export default Menu;