import React, { FC } from 'react';
import { NET } from '../../../app/network';
import AdminLayout from '../../../layouts/AdminLayout';
import GridView from '../../../widgets/GridView/GridView';
import { ggOrders } from './../../../common/gridgenerator/ggOrders';
import { egOrders } from './../../../common/editorgenerator/egOrders';
import { ggUsers } from './../../../common/gridgenerator/ggUsers';
import { useAppSelector } from '../../../app/hooks';
import { selectCheckKey } from '../../../app/redux/slices/CoreSlice';

interface IAdminOrdersPageProps {

};

const AdminUsersPage: FC<IAdminOrdersPageProps> = ({ }) => {
  const token = useAppSelector(selectCheckKey);
  return (
    <AdminLayout>
      <GridView
        token={token}
        config={{
          title: "Users",
          baseUrl: NET.BACK_URL,
          path: 'users',
          width: 'flexible',
          pagination: true,
          filters: {
            on: false,
          },
        }}
        generator={{
          grid: ggUsers,
          editor: egOrders,
        }}
        model={{
          id: 1,
          status: 'open',
        }}
      />
    </AdminLayout>
  )
};

export default AdminUsersPage;
