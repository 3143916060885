/* eslint-disable react-hooks/rules-of-hooks */
import { FC, useState, useMemo, useEffect, ReactNode } from 'react';
import { IEditorConfig } from './types';
import GridComponent from './../mgrid/GridComponent';
import { fetchGridData, getDataGE, getGridHandlers, transformGE } from './utils';
import GridModal from './components/GridModal';
import { useLocation, useParams } from 'react-router-dom';
import { TTHead } from '../mgrid/types';
import Button from '../ui/Button/Button';
import './gridview.scss';
import { selectLang } from './../../app/redux/slices/CoreSlice';
import { useLang } from './../../app/lang/useLang';
import { useAppSelector } from '../../app/hooks';

export interface IConfigGridView {
  title: string;
  baseUrl: string;
  urlId?: boolean;
  path: string;
  width?: 'flexible';
  pagination?: boolean;
  actions?: {
    create?: boolean;
    update?: boolean;
    deleted?: boolean;
  };
  filters: {
    on: boolean;
    name?: any;
    defaultValue?: any;
    routeFilter?: string;
    staticData?: boolean;
    options?: {
      id: string | number;
      title: string;
    }[];
  };
  headerBtn?: ReactNode;

}

interface IGridViewProps {
  config: IConfigGridView;
  model: object;
  generator: {
    grid: () => {
      thead: TTHead[];
    };
    editor: () => any[];
  };
  token?: any;
};

const GridView:FC<IGridViewProps> = ({
  config: {
    baseUrl,
    urlId,
    path,
    title,
    width,
    pagination,
    filters,
    headerBtn,
  },
  model,
  generator,
  token,
}) => {
  const location = useLocation()
  //Initial state managment
  const url = `${baseUrl}/${path}`
  const activeRoute = location.pathname;
  const [page, setPage] = useState(1)
  const [showModal, setShowModal] = useState(false)
  const [modalConfig, setModalConfig] = useState<IEditorConfig>({
    btnNameCreate: 'Create',
    btnNameUpdate: 'Update',
    type: 'create',
  })

  const [filter, setFilter] = useState<any>({
    page: page,
    ...(filters.on ? {
      [filters.name]: filters?.defaultValue || 1
    } : {})
  })
  const [gridData, setGridData] = useState<any>(null)
  const [settingsPage, setSettingsPage] = useState({
    maxPage: 1,
    count: ''
  })

  const [dataItem, setDataItem] = useState(model)

  const gridConfig = useMemo(() => generator.grid(), [activeRoute])
  const { thead } = gridConfig;
  const params = useParams();

  const afterSuccess = async () => {
    await fetchGridData(
      filter,
      url,
      setGridData,
      thead,
      settingsPage,
      setSettingsPage,
      pagination,
      urlId ? params.id : '',
      token,
    );
    setShowModal(false);
    setDataItem(model);
  }

  useEffect(() => {
    fetchGridData(
      filter,
      url,
      setGridData,
      thead,
      settingsPage,
      setSettingsPage,
      pagination,
      urlId ? params.id : '',
      token,
    );
  }, [setFilter, filter, setPage, page, activeRoute])

  const deleteHandler = async (id: number) => {
    const result = await getDataGE(`${url}/${id}`, 'DELETE')
    let data = result.data;
    if (pagination){
      data = result.data.data
    }
    setGridData(transformGE(thead, data))
  }

  const openUpdateHandler = async (id:number) => {
    setModalConfig({
      ...modalConfig,
      type: 'update',
    })
    setShowModal(true)
    const result = await getDataGE(`${url}/${id}`, 'GET', token);
    const editData:any = {};
    Object.keys(model).forEach((key) => {
      editData[key] = result.data[key]
    })
    setDataItem(editData);
  }

  const openModal = () => {
    setShowModal(true)
    setModalConfig({
      ...modalConfig,
      type: 'create',
    })
  }
	const lang = useAppSelector(selectLang);
  const gridHandlers = useMemo(() => getGridHandlers(
    openUpdateHandler,
    deleteHandler,
  ), [activeRoute])

  return (
    <div className='gridview w-100'>
      <div className='gridview__title'>{title}</div>

      {headerBtn ? <div>{headerBtn}</div> : (
        <div>
          <Button onClick={openModal}>{useLang("create", lang)}</Button>
        </div>
      )}
      {gridData ?
        <GridComponent
          gridData={gridData}
          thead={thead}
          filter={filter}
          // filterOptions={filterOptions}
          setFilter={setFilter}
          settings={{
            //Включает порядковые номера у строк
            counter: true,
            url: baseUrl,
            //Включает блок фильтров и поиска
            filter: filters.on,
            filters: filters,
            width: width,
          }}
          gridHandlers={gridHandlers}
          setGridData={setGridData}
          settingsPage={settingsPage}
          setSettingsPage={setSettingsPage} /> :
        <div style={{ display: 'flex', justifyContent: 'center' }}>{useLang("loading", lang)}</div>
      }
      {showModal && <GridModal
        fields={generator.editor()}
        showModal={showModal}
        setShowModal={setShowModal}
        dataItem={dataItem}
        setDataItem={setDataItem}
        afterSuccess={afterSuccess}
        modalConfig={modalConfig}
        title={title}
        baseUrl={baseUrl}
        path={path}
        urlId={urlId}
        filters={filters}
      />}
    </div>
  )
};

export default GridView;